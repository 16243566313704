@import '../../assets/styles/typography';

.listItem {
  &:hover {
    background-color: $neutral-200;
  }
  &.active {
    color: $primary;
    &:after {
      position: absolute;
      top: 0px;
      left: 0px;
      display: block;
      content: '';
      width: 2px;
      height: 100%;
      background-color: $primary;
    }
  }
}
