.left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.both {
  border-radius: 0 0 0 0 !important;
}
