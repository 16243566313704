@import '../../assets/styles/typography';

.pre {
  position: relative;
  background-color: $neutral-620;
  border-radius: 8px;
  color: $code-color;
  overflow-wrap: break-word;
  line-height: 1.57rem;
}

.copy {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
}

.textarea {
  color: $code-color;
  resize: none;
}
