// Do not edit directly
// Generated on Wed, 24 May 2023 08:42:53 GMT

$es-1-border-width: 1;
$es-2-border-width: 2;
$es-4-border-width: 4;
$es-8-border-width: 8;
$es-10-font-size: 10;
$es-11-font-size: 11;
$es-12-font-size: 12;
$es-13-font-size: 13;
$es-14-font-size: 14;
$es-15-font-size: 15;
$es-16-font-size: 16;
$es-17-font-size: 17;
$es-18-font-size: 18;
$es-20-font-size: 20;
$es-22-font-size: 22;
$es-23-font-size: 23;
$es-24-font-size: 24;
$es-26-font-size: 26;
$es-28-font-size: 28;
$es-32-font-size: 32;
$es-42-font-size: 42;
$es-60-font-size: 60;
$es-80-font-size: 80;
$es-100-line-height: 100%;
$es-110-line-height: 110%;
$es-130-line-height: 130%;
$es-135-line-height: 135%;
$es-140-line-height: 140%;
$es-145-line-height: 145%;
$es-150-line-height: 150%;
$es-160-line-height: 160%;
$es-170-line-height: 170%;
$es-180-line-height: 180%;
$es-300-font-weight: Light;
$es-400-font-weight: Regular;
$es-500-font-weight: Medium;
$es-600-font-weight: SemiBold;
$es-700-font-weight: Bold;
$es-800-font-weight: ExtraBold;
$es-default-letter-spacing: 0;
$es-uppercase-text-case: uppercase;
$es-none-text-case: none;
$es-button-normal-line-height: 132%;
$es-button-admin-small-line-height: 145%;
$es-8-font-size: 8;
$es-8-opacity: 0.08;
$es-10-opacity: 0.1;
$es-20-opacity: 0.2;
$es-30-opacity: 0.3;
$es-40-opacity: 0.4;
$es-50-opacity: 0.5;
$es-60-opacity: 0.6;
$es-70-opacity: 0.7;
$es-80-opacity: 0.8;
$es-90-opacity: 0.9;
$es-x0-border-radius: 0;
$es-x05-border-radius: 2;
$es-x1-border-radius: 4;
$es-3x05-border-radius: 6;
$es-x2-border-radius: 4 * 2;
$es-x3-border-radius: 4 * 3;
$es-x4-border-radius: 4 * 4;
$es-x5-border-radius: 4 * 5;
$es-x6-border-radius: 4 * 6;
$es-x7-border-radius: 4 * 7;
$es-x8-border-radius: 4 * 8;
$es-x9-border-radius: 4 * 9;
$es-x10-border-radius: 4 * 10;
$es-x14-border-radius: 4 * 14;
$es-x30-border-radius: 4 * 30;
$es-x025-spacing: 4/4;
$es-x05-spacing: 4/2;
$es-x1-spacing: 4;
$es-x2-spacing: 4 * 2;
$es-x3-spacing: 4 * 3;
$es-x4-spacing: 4 * 4;
$es-x5-spacing: 4 * 5;
$es-x6-spacing: 4 * 6;
$es-x7-spacing: 4 * 7;
$es-x8-spacing: 4 * 8;
$es-x9-spacing: 4 * 9;
$es-x10-spacing: 4 * 10;
$es-x11-spacing: 4 * 11;
$es-x12-spacing: 4 * 12;
$es-x13-spacing: 4 * 13;
$es-x14-spacing: 4 * 14;
$es-x15-spacing: 4 * 15;
$es-x16-spacing: 4 * 16;
$es-x17-spacing: 4 * 17;
$es-x18-spacing: 4 * 18;
$es-x19-spacing: 4 * 19;
$es-x20-spacing: 4 * 20;
$es-x21-spacing: 4 * 21;
$es-x22-spacing: 4 * 22;
$es-x23-spacing: 4 * 23;
$es-x24-spacing: 4 * 24;
$es-x25-spacing: 4 * 25;
$es-x30-spacing: 4 * 30;
$es-x35-spacing: 4 * 35;
$es-x40-spacing: 4 * 40;
$es-x45-spacing: 4 * 45;
$es-x50-spacing: 4 * 50;
$es-x55-spacing: 4 * 55;
$es-x60-spacing: 4 * 60;
$es-x65-spacing: 4 * 65;
$es-x70-spacing: 4 * 70;
$es-x75-spacing: 4 * 75;
$es-x80-spacing: 4 * 80;
$es-x90-spacing: 4 * 90;
$es-x95-spacing: 4 * 95;
$es-x121-spacing: 4 * 121;
$es-x146-spacing: 4 * 146;
$es-x160-spacing: 4 * 160;
$es-x172-spacing: 4 * 172;
$es-x198-spacing: 4 * 198;
$es-x223-spacing: 4 * 223;
$es-x249-spacing: 4 * 249;
$es-x274-spacing: 4 * 274;
$es-x300-spacing: 4 * 300;
$es-x320-spacing: 4 * 320;
$es-x025-sizing: 1;
$es-x05-sizing: 2;
$es-x1-sizing: 4;
$es-x2-sizing: 4 * 2;
$es-x3-sizing: 4 * 3;
$es-x4-sizing: 4 * 4;
$es-x5-sizing: 4 * 5;
$es-x6-sizing: 4 * 6;
$es-x7-sizing: 4 * 7;
$es-x8-sizing: 4 * 8;
$es-x9-sizing: 4 * 9;
$es-x10-sizing: 4 * 10;
$es-x11-sizing: 4 * 11;
$es-x12-sizing: 4 * 12;
$es-x13-sizing: 4 * 13;
$es-x14-sizing: 4 * 14;
$es-x15-sizing: 4 * 15;
$es-x16-sizing: 4 * 16;
$es-x17-sizing: 4 * 17;
$es-x18-sizing: 4 * 18;
$es-x19-sizing: 4 * 19;
$es-x20-sizing: 4 * 20;
$es-x21-sizing: 4 * 21;
$es-x22-sizing: 4 * 22;
$es-x23-sizing: 4 * 23;
$es-x24-sizing: 4 * 24;
$es-x25-sizing: 4 * 25;
$es-x26-sizing: 4 * 26;
$es-x27-sizing: 4 * 27;
$es-x28-sizing: 4 * 28;
$es-x29-sizing: 4 * 29;
$es-x30-sizing: 4 * 30;
$es-x31-sizing: 4 * 31;
$es-x32-sizing: 4 * 32;
$es-x33-sizing: 4 * 33;
$es-x34-sizing: 4 * 34;
$es-x35-sizing: 4 * 35;
$es-x36-sizing: 4 * 36;
$es-x37-sizing: 4 * 37;
$es-x38-sizing: 4 * 38;
$es-x39-sizing: 4 * 39;
$es-x40-sizing: 4 * 40;
$es-x41-sizing: 4 * 41;
$es-x42-sizing: 4 * 42;
$es-x43-sizing: 4 * 43;
$es-x44-sizing: 4 * 44;
$es-x45-sizing: 4 * 45;
$es-x46-sizing: 4 * 46;
$es-x47-sizing: 4 * 47;
$es-x48-sizing: 4 * 48;
$es-x49-sizing: 4 * 49;
$es-x50-sizing: 4 * 50;
$es-x54-sizing: 4 * 54;
$es-x60-sizing: 4 * 60;
$es-x70-sizing: 4 * 70;
$es-x72-sizing: 4 * 72;
$es-x95-sizing: 4 * 95;
$es-x96-sizing: 4 * 96;
$es-x121-sizing: 4 * 121;
$es-x146-sizing: 4 * 146;
$es-x147-sizing: 4 * 147;
$es-x160-sizing: 4 * 160;
$es-x172-sizing: 4 * 172;
$es-x198-sizing: 4 * 198;
$es-x223-sizing: 4 * 223;
$es-x249-sizing: 4 * 249;
$es-x274-sizing: 4 * 274;
$es-x300-sizing: 4 * 300;
$es-x320-sizing: 4 * 320;
$es-global-neutral-f-color: #fff;
$es-global-neutral-100-color: #f7f7f7;
$es-global-neutral-150-color: #eff0f0;
$es-global-neutral-200-color: #ebebeb;
$es-global-neutral-250-color: #e5e5e6;
$es-global-neutral-300-color: #dddedf;
$es-global-neutral-350-color: #d5d6d8;
$es-global-neutral-400-color: #c1c2c3;
$es-global-neutral-500-color: #bdbdbd;
$es-global-neutral-520-color: #9a9a9a;
$es-global-neutral-550-color: #828282;
$es-global-neutral-600-color: #666666;
$es-global-neutral-620-color: #464749;
$es-global-neutral-650-color: #3c3d3e;
$es-global-neutral-660-color: #373839;
$es-global-neutral-666-color: #313335;
$es-global-neutral-670-color: #2f3032;
$es-global-neutral-700-color: #2c2e30;
$es-global-neutral-750-color: #292b2d;
$es-global-neutral-780-color: #242628;
$es-global-neutral-800-color: #1f2123;
$es-global-neutral-850-color: #1a1c1e;
$es-global-neutral-890-color: #121416;
$es-global-neutral-900-color: #000;
$es-global-neutral-0-20-color: #00000033;
$es-global-neutral-0-60-color: #00000099;
$es-global-neutral-f-60-color: #ffffff99;
$es-global-neutral-f-20-color: #ffffff33;
$es-global-green-100-color: #2e7d321a;
$es-global-green-150-color: #2e7d3233;
$es-global-green-200-color: #2e7d324d;
$es-global-green-250-color: #2e7d3266;
$es-global-green-300-color: #2e7d3299;
$es-global-green-350-color: #2e7d32cc;
$es-global-green-400-color: #83f105;
$es-global-green-450-color: #388e3c;
$es-global-green-500-color: #2e7d32;
$es-global-green-550-color: #29702d;
$es-global-green-600-color: #1b5e20;
$es-global-green-650-color: #18531c;
$es-global-green-700-color: #144718;
$es-global-green-750-color: #123f16;
$es-global-green-800-color: #0f3412;
$es-global-green-850-color: #0b280d;
$es-global-green-900-color: #09200b;
$es-global-green-950-color: #061407;
$es-global-red-dg-100-color: #b7291314;
$es-global-red-dg-150-color: #b7291333;
$es-global-red-dg-200-color: #b729134d;
$es-global-red-dg-250-color: #b7291366;
$es-global-red-dg-300-color: #b7291399;
$es-global-red-dg-350-color: #b72913cc;
$es-global-red-dg-400-color: #d5341c;
$es-global-red-dg-450-color: #bf5040;
$es-global-red-dg-500-color: #b72913;
$es-global-red-dg-550-color: #a72511;
$es-global-red-dg-600-color: #821d0d;
$es-global-yellow-400-color: #ffcc33;
$es-global-yellow-500-color: #ffbf00;
$es-global-blue-100-color: #0f46921a;
$es-global-blue-150-color: #0f469233;
$es-global-blue-200-color: #0f46924d;
$es-global-blue-250-color: #0f469266;
$es-global-blue-300-color: #0f469299;
$es-global-blue-350-color: #0f4692cc;
$es-global-blue-400-color: #3962dd;
$es-global-blue-450-color: #4982cf;
$es-global-blue-500-color: #0f4692;
$es-global-blue-600-color: #5a78a5;
$es-global-blue-700-color: #163761;
$es-global-blue-800-color: #112a4b;
$es-global-orange-110-color: #fffef1;
$es-global-orange-180-color: #ffd54f;
$es-global-orange-200-color: #ffa726;
$es-global-orange-220-color: #ff9137;
$es-global-orange-250-color: #fb8c00;
$es-global-orange-270-color: #ef6c00;
$es-global-orange-300-color: #e86612;
$es-global-orange-350-color: #e65100;
$es-global-orange-800-color: #b74b00;
$es-global-orange-main-30-color: #ef6c004d;
$es-global-attention-400-color: #f28933;
$es-global-attention-500-color: #ef6c00;
$es-global-attention-50-color: #ef6c0080;
$es-global-attention-600-color: #d96200;
$es-global-attention-30-color: #ff963f4d;
$es-global-shades-100-color: #bedaff;
$es-global-shades-200-color: #6fa1d9;
$es-global-shades-300-color: #658dbb;
$es-global-shades-400-color: #557aa5;
$es-global-shades-500-color: #4b6f98;
$es-global-shades-50-color: #4b6f9880;
$es-global-shades-700-color: #1c467d;
$es-global-shades-20-color: #4b6f9833;
$es-global-success-300-color: #50c276;
$es-global-success-400-color: #58975b;
$es-global-success-500-color: #2e7d32;
$es-global-success-50-color: #2e7d3280;
$es-global-success-600-color: #2a722e;
$es-global-success-20-color: #58975b33;
$es-global-error-300-color: #ff4d4d;
$es-global-error-400-color: #c55442;
$es-global-error-500-color: #ff2706;
$es-global-error-50-color: #ff270680;
$es-global-error-600-color: #ff3e3e;
$es-global-gradient-background-neutral-color: linear-gradient(
  0deg,
  #ffffff 0%,
  #f9f9f9 100%
);
$es-global-gradient-background-error-color: linear-gradient(
  180deg,
  #ffffff 0%,
  #ff9090 100%
);
$es-global-neutral-blue-0-color: #fff;
$es-global-neutral-blue-150-color: #f3f5f6;
$es-global-neutral-blue-160-color: #eceff1;
$es-global-neutral-blue-210-color: #cfd8dc;
$es-global-neutral-blue-400-color: #b9c4cb;
$es-global-neutral-blue-650-color: #b0bec5;
$es-global-neutral-blue-660-color: #90a4ae;
$es-global-neutral-blue-test-color: #90a4ae;
$es-global-neutral-blue-666-color: #78909c;
$es-global-neutral-blue-670-color: #607d8b;
$es-global-neutral-blue-700-color: #546e7a;
$es-global-neutral-blue-750-color: #455a64;
$es-global-neutral-blue-780-color: #37474f;
$es-global-neutral-blue-800-color: #263238;
$es-global-neutral-blue-850-color: #14161a;
$es-global-neutral-blue-900-color: #111317;
$es-global-neutral-blue-0-60-color: #ffffff99;
$es-global-red-250-color: #da442c;
$es-global-red-350-color: #d5341c;
$es-global-red-400-color: #bf5040;
$es-global-red-550-color: #a72511;
$es-global-red-600-color: #821d0d;
$es-global-red-500-color: #b72913;
$es-mobile-dark-dg-up-main-box-shadow-color: #ff818114;
$es-mobile-dark-dg-up-main-box-shadow-type: dropShadow;
$es-mobile-dark-dg-up-main-box-shadow-x: 0;
$es-mobile-dark-dg-up-main-box-shadow-y: 10;
$es-mobile-dark-dg-up-main-box-shadow-blur: 40;
$es-mobile-dark-dg-up-main-box-shadow-spread: 0;
$es-mobile-for-scroll-up-box-shadow-color: #0000000f;
$es-mobile-for-scroll-up-box-shadow-type: dropShadow;
$es-mobile-for-scroll-up-box-shadow-x: 0;
$es-mobile-for-scroll-up-box-shadow-y: -4;
$es-mobile-for-scroll-up-box-shadow-blur: 8;
$es-mobile-for-scroll-up-box-shadow-spread: 0;
$es-mobile-for-scroll-down-box-shadow-color: #00000014;
$es-mobile-for-scroll-down-box-shadow-type: dropShadow;
$es-mobile-for-scroll-down-box-shadow-x: 0;
$es-mobile-for-scroll-down-box-shadow-y: 4;
$es-mobile-for-scroll-down-box-shadow-blur: 8;
$es-mobile-for-scroll-down-box-shadow-spread: 0;
$es-open-sans: Open Sans;
$es-plain-text-80px-300-typography-font-family: Open Sans;
$es-plain-text-80px-300-typography-font-weight: Light;
$es-plain-text-80px-300-typography-line-height: 150%;
$es-plain-text-80px-300-typography-font-size: 80;
$es-plain-text-80px-300-typography-letter-spacing: 0;
$es-plain-text-80px-300-typography-paragraph-spacing: none;
$es-plain-text-80px-300-typography-text-case: none;
$es-plain-text-80px-300-typography-text-decoration: none;
$es-plain-text-80px-400-typography-font-family: Open Sans;
$es-plain-text-80px-400-typography-font-weight: Regular;
$es-plain-text-80px-400-typography-line-height: 150%;
$es-plain-text-80px-400-typography-font-size: 80;
$es-plain-text-80px-400-typography-letter-spacing: 0;
$es-plain-text-80px-400-typography-paragraph-spacing: none;
$es-plain-text-80px-400-typography-text-case: none;
$es-plain-text-80px-400-typography-text-decoration: none;
$es-plain-text-80px-500-typography-font-family: Open Sans;
$es-plain-text-80px-500-typography-font-weight: Medium;
$es-plain-text-80px-500-typography-line-height: 150%;
$es-plain-text-80px-500-typography-font-size: 80;
$es-plain-text-80px-500-typography-letter-spacing: 0;
$es-plain-text-80px-500-typography-paragraph-spacing: none;
$es-plain-text-80px-500-typography-text-case: none;
$es-plain-text-80px-500-typography-text-decoration: none;
$es-plain-text-80px-600-typography-font-family: Open Sans;
$es-plain-text-80px-600-typography-font-weight: SemiBold;
$es-plain-text-80px-600-typography-line-height: 150%;
$es-plain-text-80px-600-typography-font-size: 80;
$es-plain-text-80px-600-typography-letter-spacing: 0;
$es-plain-text-80px-600-typography-paragraph-spacing: none;
$es-plain-text-80px-600-typography-text-case: none;
$es-plain-text-80px-600-typography-text-decoration: none;
$es-plain-text-80px-700-typography-font-family: Open Sans;
$es-plain-text-80px-700-typography-font-weight: Bold;
$es-plain-text-80px-700-typography-line-height: 150%;
$es-plain-text-80px-700-typography-font-size: 80;
$es-plain-text-80px-700-typography-letter-spacing: 0;
$es-plain-text-80px-700-typography-paragraph-spacing: none;
$es-plain-text-80px-700-typography-text-case: none;
$es-plain-text-80px-700-typography-text-decoration: none;
$es-plain-text-80px-800-typography-font-family: Open Sans;
$es-plain-text-80px-800-typography-font-weight: ExtraBold;
$es-plain-text-80px-800-typography-line-height: 150%;
$es-plain-text-80px-800-typography-font-size: 80;
$es-plain-text-80px-800-typography-letter-spacing: 0;
$es-plain-text-80px-800-typography-paragraph-spacing: none;
$es-plain-text-80px-800-typography-text-case: none;
$es-plain-text-80px-800-typography-text-decoration: none;
$es-plain-text-60px-300-typography-font-family: Open Sans;
$es-plain-text-60px-300-typography-font-weight: Light;
$es-plain-text-60px-300-typography-line-height: 140%;
$es-plain-text-60px-300-typography-font-size: 60;
$es-plain-text-60px-300-typography-letter-spacing: 0;
$es-plain-text-60px-300-typography-paragraph-spacing: none;
$es-plain-text-60px-300-typography-text-case: none;
$es-plain-text-60px-300-typography-text-decoration: none;
$es-plain-text-60px-400-typography-font-family: Open Sans;
$es-plain-text-60px-400-typography-font-weight: Regular;
$es-plain-text-60px-400-typography-line-height: 140%;
$es-plain-text-60px-400-typography-font-size: 60;
$es-plain-text-60px-400-typography-letter-spacing: 0;
$es-plain-text-60px-400-typography-paragraph-spacing: none;
$es-plain-text-60px-400-typography-text-case: none;
$es-plain-text-60px-400-typography-text-decoration: none;
$es-plain-text-60px-500-typography-font-family: Open Sans;
$es-plain-text-60px-500-typography-font-weight: Medium;
$es-plain-text-60px-500-typography-line-height: 140%;
$es-plain-text-60px-500-typography-font-size: 60;
$es-plain-text-60px-500-typography-letter-spacing: 0;
$es-plain-text-60px-500-typography-paragraph-spacing: none;
$es-plain-text-60px-500-typography-text-case: none;
$es-plain-text-60px-500-typography-text-decoration: none;
$es-plain-text-60px-600-typography-font-family: Open Sans;
$es-plain-text-60px-600-typography-font-weight: SemiBold;
$es-plain-text-60px-600-typography-line-height: 140%;
$es-plain-text-60px-600-typography-font-size: 60;
$es-plain-text-60px-600-typography-letter-spacing: 0;
$es-plain-text-60px-600-typography-paragraph-spacing: none;
$es-plain-text-60px-600-typography-text-case: none;
$es-plain-text-60px-600-typography-text-decoration: none;
$es-plain-text-60px-700-typography-font-family: Open Sans;
$es-plain-text-60px-700-typography-font-weight: Bold;
$es-plain-text-60px-700-typography-line-height: 140%;
$es-plain-text-60px-700-typography-font-size: 60;
$es-plain-text-60px-700-typography-letter-spacing: 0;
$es-plain-text-60px-700-typography-paragraph-spacing: none;
$es-plain-text-60px-700-typography-text-case: none;
$es-plain-text-60px-700-typography-text-decoration: none;
$es-plain-text-60px-800-typography-font-family: Open Sans;
$es-plain-text-60px-800-typography-font-weight: ExtraBold;
$es-plain-text-60px-800-typography-line-height: 150%;
$es-plain-text-60px-800-typography-font-size: 60;
$es-plain-text-60px-800-typography-letter-spacing: 0;
$es-plain-text-60px-800-typography-paragraph-spacing: none;
$es-plain-text-60px-800-typography-text-case: none;
$es-plain-text-60px-800-typography-text-decoration: none;
$es-plain-text-42px-300-typography-font-family: Open Sans;
$es-plain-text-42px-300-typography-font-weight: Light;
$es-plain-text-42px-300-typography-line-height: 150%;
$es-plain-text-42px-300-typography-font-size: 42;
$es-plain-text-42px-300-typography-letter-spacing: 0;
$es-plain-text-42px-300-typography-paragraph-spacing: none;
$es-plain-text-42px-300-typography-text-case: none;
$es-plain-text-42px-300-typography-text-decoration: none;
$es-plain-text-42px-400-typography-font-family: Open Sans;
$es-plain-text-42px-400-typography-font-weight: Regular;
$es-plain-text-42px-400-typography-line-height: 150%;
$es-plain-text-42px-400-typography-font-size: 42;
$es-plain-text-42px-400-typography-letter-spacing: 0;
$es-plain-text-42px-400-typography-paragraph-spacing: none;
$es-plain-text-42px-400-typography-text-case: none;
$es-plain-text-42px-400-typography-text-decoration: none;
$es-plain-text-42px-500-typography-font-family: Open Sans;
$es-plain-text-42px-500-typography-font-weight: Medium;
$es-plain-text-42px-500-typography-line-height: 150%;
$es-plain-text-42px-500-typography-font-size: 42;
$es-plain-text-42px-500-typography-letter-spacing: 0;
$es-plain-text-42px-500-typography-paragraph-spacing: none;
$es-plain-text-42px-500-typography-text-case: none;
$es-plain-text-42px-500-typography-text-decoration: none;
$es-plain-text-42px-600-typography-font-family: Open Sans;
$es-plain-text-42px-600-typography-font-weight: SemiBold;
$es-plain-text-42px-600-typography-line-height: 150%;
$es-plain-text-42px-600-typography-font-size: 42;
$es-plain-text-42px-600-typography-letter-spacing: 0;
$es-plain-text-42px-600-typography-paragraph-spacing: none;
$es-plain-text-42px-600-typography-text-case: none;
$es-plain-text-42px-600-typography-text-decoration: none;
$es-plain-text-42px-700-typography-font-family: Open Sans;
$es-plain-text-42px-700-typography-font-weight: Bold;
$es-plain-text-42px-700-typography-line-height: 150%;
$es-plain-text-42px-700-typography-font-size: 42;
$es-plain-text-42px-700-typography-letter-spacing: 0;
$es-plain-text-42px-700-typography-paragraph-spacing: none;
$es-plain-text-42px-700-typography-text-case: none;
$es-plain-text-42px-700-typography-text-decoration: none;
$es-plain-text-42px-800-typography-font-family: Open Sans;
$es-plain-text-42px-800-typography-font-weight: ExtraBold;
$es-plain-text-42px-800-typography-line-height: 150%;
$es-plain-text-42px-800-typography-font-size: 42;
$es-plain-text-42px-800-typography-letter-spacing: 0;
$es-plain-text-42px-800-typography-paragraph-spacing: none;
$es-plain-text-42px-800-typography-text-case: none;
$es-plain-text-42px-800-typography-text-decoration: none;
$es-plain-text-24px-300-typography-font-family: Open Sans;
$es-plain-text-24px-300-typography-font-weight: Light;
$es-plain-text-24px-300-typography-line-height: 170%;
$es-plain-text-24px-300-typography-font-size: 24;
$es-plain-text-24px-300-typography-letter-spacing: 0;
$es-plain-text-24px-300-typography-paragraph-spacing: none;
$es-plain-text-24px-300-typography-text-case: none;
$es-plain-text-24px-300-typography-text-decoration: none;
$es-plain-text-24px-400-typography-font-family: Open Sans;
$es-plain-text-24px-400-typography-font-weight: Regular;
$es-plain-text-24px-400-typography-line-height: 170%;
$es-plain-text-24px-400-typography-font-size: 24;
$es-plain-text-24px-400-typography-letter-spacing: 0;
$es-plain-text-24px-400-typography-paragraph-spacing: none;
$es-plain-text-24px-400-typography-text-case: none;
$es-plain-text-24px-400-typography-text-decoration: none;
$es-plain-text-24px-500-typography-font-family: Open Sans;
$es-plain-text-24px-500-typography-font-weight: Medium;
$es-plain-text-24px-500-typography-line-height: 170%;
$es-plain-text-24px-500-typography-font-size: 24;
$es-plain-text-24px-500-typography-letter-spacing: 0;
$es-plain-text-24px-500-typography-paragraph-spacing: none;
$es-plain-text-24px-500-typography-text-case: none;
$es-plain-text-24px-500-typography-text-decoration: none;
$es-plain-text-24px-600-typography-font-family: Open Sans;
$es-plain-text-24px-600-typography-font-weight: SemiBold;
$es-plain-text-24px-600-typography-line-height: 170%;
$es-plain-text-24px-600-typography-font-size: 24;
$es-plain-text-24px-600-typography-letter-spacing: 0;
$es-plain-text-24px-600-typography-paragraph-spacing: none;
$es-plain-text-24px-600-typography-text-case: none;
$es-plain-text-24px-600-typography-text-decoration: none;
$es-plain-text-24px-700-typography-font-family: Open Sans;
$es-plain-text-24px-700-typography-font-weight: Bold;
$es-plain-text-24px-700-typography-line-height: 170%;
$es-plain-text-24px-700-typography-font-size: 24;
$es-plain-text-24px-700-typography-letter-spacing: 0;
$es-plain-text-24px-700-typography-paragraph-spacing: none;
$es-plain-text-24px-700-typography-text-case: none;
$es-plain-text-24px-700-typography-text-decoration: none;
$es-plain-text-24px-800-typography-font-family: Open Sans;
$es-plain-text-24px-800-typography-font-weight: ExtraBold;
$es-plain-text-24px-800-typography-line-height: 170%;
$es-plain-text-24px-800-typography-font-size: 24;
$es-plain-text-24px-800-typography-letter-spacing: 0;
$es-plain-text-24px-800-typography-paragraph-spacing: none;
$es-plain-text-24px-800-typography-text-case: none;
$es-plain-text-24px-800-typography-text-decoration: none;
$es-plain-text-32px-300-typography-font-family: Open Sans;
$es-plain-text-32px-300-typography-font-weight: Light;
$es-plain-text-32px-300-typography-line-height: 170%;
$es-plain-text-32px-300-typography-font-size: 32;
$es-plain-text-32px-300-typography-letter-spacing: 0;
$es-plain-text-32px-300-typography-paragraph-spacing: none;
$es-plain-text-32px-300-typography-text-case: none;
$es-plain-text-32px-300-typography-text-decoration: none;
$es-plain-text-32px-400-typography-font-family: Open Sans;
$es-plain-text-32px-400-typography-font-weight: Regular;
$es-plain-text-32px-400-typography-line-height: 170%;
$es-plain-text-32px-400-typography-font-size: 32;
$es-plain-text-32px-400-typography-letter-spacing: 0;
$es-plain-text-32px-400-typography-paragraph-spacing: none;
$es-plain-text-32px-400-typography-text-case: none;
$es-plain-text-32px-400-typography-text-decoration: none;
$es-plain-text-32px-500-typography-font-family: Open Sans;
$es-plain-text-32px-500-typography-font-weight: Medium;
$es-plain-text-32px-500-typography-line-height: 170%;
$es-plain-text-32px-500-typography-font-size: 32;
$es-plain-text-32px-500-typography-letter-spacing: 0;
$es-plain-text-32px-500-typography-paragraph-spacing: none;
$es-plain-text-32px-500-typography-text-case: none;
$es-plain-text-32px-500-typography-text-decoration: none;
$es-plain-text-32px-600-typography-font-family: Open Sans;
$es-plain-text-32px-600-typography-font-weight: SemiBold;
$es-plain-text-32px-600-typography-line-height: 170%;
$es-plain-text-32px-600-typography-font-size: 32;
$es-plain-text-32px-600-typography-letter-spacing: 0;
$es-plain-text-32px-600-typography-paragraph-spacing: none;
$es-plain-text-32px-600-typography-text-case: none;
$es-plain-text-32px-600-typography-text-decoration: none;
$es-plain-text-32px-700-typography-font-family: Open Sans;
$es-plain-text-32px-700-typography-font-weight: Bold;
$es-plain-text-32px-700-typography-line-height: 170%;
$es-plain-text-32px-700-typography-font-size: 32;
$es-plain-text-32px-700-typography-letter-spacing: 0;
$es-plain-text-32px-700-typography-paragraph-spacing: none;
$es-plain-text-32px-700-typography-text-case: none;
$es-plain-text-32px-700-typography-text-decoration: none;
$es-plain-text-32px-800-typography-font-family: Open Sans;
$es-plain-text-32px-800-typography-font-weight: ExtraBold;
$es-plain-text-32px-800-typography-line-height: 170%;
$es-plain-text-32px-800-typography-font-size: 32;
$es-plain-text-32px-800-typography-letter-spacing: 0;
$es-plain-text-32px-800-typography-paragraph-spacing: none;
$es-plain-text-32px-800-typography-text-case: none;
$es-plain-text-32px-800-typography-text-decoration: none;
$es-plain-text-20px-300-typography-font-family: Open Sans;
$es-plain-text-20px-300-typography-font-weight: Light;
$es-plain-text-20px-300-typography-line-height: 170%;
$es-plain-text-20px-300-typography-font-size: 20;
$es-plain-text-20px-300-typography-letter-spacing: 0;
$es-plain-text-20px-300-typography-paragraph-spacing: none;
$es-plain-text-20px-300-typography-text-case: none;
$es-plain-text-20px-300-typography-text-decoration: none;
$es-plain-text-20px-400-typography-font-family: Open Sans;
$es-plain-text-20px-400-typography-font-weight: Regular;
$es-plain-text-20px-400-typography-line-height: 170%;
$es-plain-text-20px-400-typography-font-size: 20;
$es-plain-text-20px-400-typography-letter-spacing: 0;
$es-plain-text-20px-400-typography-paragraph-spacing: none;
$es-plain-text-20px-400-typography-text-case: none;
$es-plain-text-20px-400-typography-text-decoration: none;
$es-plain-text-20px-500-typography-font-family: Open Sans;
$es-plain-text-20px-500-typography-font-weight: Medium;
$es-plain-text-20px-500-typography-line-height: 170%;
$es-plain-text-20px-500-typography-font-size: 20;
$es-plain-text-20px-500-typography-letter-spacing: 0;
$es-plain-text-20px-500-typography-paragraph-spacing: none;
$es-plain-text-20px-500-typography-text-case: none;
$es-plain-text-20px-500-typography-text-decoration: none;
$es-plain-text-20px-600-typography-font-family: Open Sans;
$es-plain-text-20px-600-typography-font-weight: SemiBold;
$es-plain-text-20px-600-typography-line-height: 170%;
$es-plain-text-20px-600-typography-font-size: 20;
$es-plain-text-20px-600-typography-letter-spacing: 0;
$es-plain-text-20px-600-typography-paragraph-spacing: none;
$es-plain-text-20px-600-typography-text-case: none;
$es-plain-text-20px-600-typography-text-decoration: none;
$es-plain-text-20px-700-typography-font-family: Open Sans;
$es-plain-text-20px-700-typography-font-weight: Bold;
$es-plain-text-20px-700-typography-line-height: 170%;
$es-plain-text-20px-700-typography-font-size: 20;
$es-plain-text-20px-700-typography-letter-spacing: 0;
$es-plain-text-20px-700-typography-paragraph-spacing: none;
$es-plain-text-20px-700-typography-text-case: none;
$es-plain-text-20px-700-typography-text-decoration: none;
$es-plain-text-20px-800-typography-font-family: Open Sans;
$es-plain-text-20px-800-typography-font-weight: ExtraBold;
$es-plain-text-20px-800-typography-line-height: 170%;
$es-plain-text-20px-800-typography-font-size: 20;
$es-plain-text-20px-800-typography-letter-spacing: 0;
$es-plain-text-20px-800-typography-paragraph-spacing: none;
$es-plain-text-20px-800-typography-text-case: none;
$es-plain-text-20px-800-typography-text-decoration: none;
$es-plain-text-18px-300-typography-font-family: Open Sans;
$es-plain-text-18px-300-typography-font-weight: Light;
$es-plain-text-18px-300-typography-line-height: 170%;
$es-plain-text-18px-300-typography-font-size: 18;
$es-plain-text-18px-300-typography-letter-spacing: 0;
$es-plain-text-18px-300-typography-paragraph-spacing: none;
$es-plain-text-18px-300-typography-text-case: none;
$es-plain-text-18px-300-typography-text-decoration: none;
$es-plain-text-18px-400-typography-font-family: Open Sans;
$es-plain-text-18px-400-typography-font-weight: Regular;
$es-plain-text-18px-400-typography-line-height: 160%;
$es-plain-text-18px-400-typography-font-size: 18;
$es-plain-text-18px-400-typography-letter-spacing: 0;
$es-plain-text-18px-400-typography-paragraph-spacing: none;
$es-plain-text-18px-400-typography-text-case: none;
$es-plain-text-18px-400-typography-text-decoration: none;
$es-plain-text-18px-500-typography-font-family: Open Sans;
$es-plain-text-18px-500-typography-font-weight: Medium;
$es-plain-text-18px-500-typography-line-height: 160%;
$es-plain-text-18px-500-typography-font-size: 18;
$es-plain-text-18px-500-typography-letter-spacing: 0;
$es-plain-text-18px-500-typography-paragraph-spacing: none;
$es-plain-text-18px-500-typography-text-case: none;
$es-plain-text-18px-500-typography-text-decoration: none;
$es-plain-text-18px-600-typography-font-family: Open Sans;
$es-plain-text-18px-600-typography-font-weight: SemiBold;
$es-plain-text-18px-600-typography-line-height: 170%;
$es-plain-text-18px-600-typography-font-size: 18;
$es-plain-text-18px-600-typography-letter-spacing: 0;
$es-plain-text-18px-600-typography-paragraph-spacing: none;
$es-plain-text-18px-600-typography-text-case: none;
$es-plain-text-18px-600-typography-text-decoration: none;
$es-plain-text-18px-700-typography-font-family: Open Sans;
$es-plain-text-18px-700-typography-font-weight: Bold;
$es-plain-text-18px-700-typography-line-height: 170%;
$es-plain-text-18px-700-typography-font-size: 18;
$es-plain-text-18px-700-typography-letter-spacing: 0;
$es-plain-text-18px-700-typography-paragraph-spacing: none;
$es-plain-text-18px-700-typography-text-case: none;
$es-plain-text-18px-700-typography-text-decoration: none;
$es-plain-text-18px-800-typography-font-family: Open Sans;
$es-plain-text-18px-800-typography-font-weight: ExtraBold;
$es-plain-text-18px-800-typography-line-height: 170%;
$es-plain-text-18px-800-typography-font-size: 18;
$es-plain-text-18px-800-typography-letter-spacing: 0;
$es-plain-text-18px-800-typography-paragraph-spacing: none;
$es-plain-text-18px-800-typography-text-case: none;
$es-plain-text-18px-800-typography-text-decoration: none;
$es-plain-text-22px-300-typography-font-family: Open Sans;
$es-plain-text-22px-300-typography-font-weight: Light;
$es-plain-text-22px-300-typography-line-height: 170%;
$es-plain-text-22px-300-typography-font-size: 22;
$es-plain-text-22px-300-typography-letter-spacing: 0;
$es-plain-text-22px-300-typography-paragraph-spacing: none;
$es-plain-text-22px-300-typography-text-case: none;
$es-plain-text-22px-300-typography-text-decoration: none;
$es-plain-text-22px-400-typography-font-family: Open Sans;
$es-plain-text-22px-400-typography-font-weight: Regular;
$es-plain-text-22px-400-typography-line-height: 170%;
$es-plain-text-22px-400-typography-font-size: 22;
$es-plain-text-22px-400-typography-letter-spacing: 0;
$es-plain-text-22px-400-typography-paragraph-spacing: none;
$es-plain-text-22px-400-typography-text-case: none;
$es-plain-text-22px-400-typography-text-decoration: none;
$es-plain-text-22px-500-typography-font-family: Open Sans;
$es-plain-text-22px-500-typography-font-weight: Medium;
$es-plain-text-22px-500-typography-line-height: 170%;
$es-plain-text-22px-500-typography-font-size: 22;
$es-plain-text-22px-500-typography-letter-spacing: 0;
$es-plain-text-22px-500-typography-paragraph-spacing: none;
$es-plain-text-22px-500-typography-text-case: none;
$es-plain-text-22px-500-typography-text-decoration: none;
$es-plain-text-22px-600-typography-font-family: Open Sans;
$es-plain-text-22px-600-typography-font-weight: SemiBold;
$es-plain-text-22px-600-typography-line-height: 170%;
$es-plain-text-22px-600-typography-font-size: 22;
$es-plain-text-22px-600-typography-letter-spacing: 0;
$es-plain-text-22px-600-typography-paragraph-spacing: none;
$es-plain-text-22px-600-typography-text-case: none;
$es-plain-text-22px-600-typography-text-decoration: none;
$es-plain-text-22px-700-typography-font-family: Open Sans;
$es-plain-text-22px-700-typography-font-weight: Bold;
$es-plain-text-22px-700-typography-line-height: 170%;
$es-plain-text-22px-700-typography-font-size: 22;
$es-plain-text-22px-700-typography-letter-spacing: 0;
$es-plain-text-22px-700-typography-paragraph-spacing: none;
$es-plain-text-22px-700-typography-text-case: none;
$es-plain-text-22px-700-typography-text-decoration: none;
$es-plain-text-22px-800-typography-font-family: Open Sans;
$es-plain-text-22px-800-typography-font-weight: ExtraBold;
$es-plain-text-22px-800-typography-line-height: 170%;
$es-plain-text-22px-800-typography-font-size: 22;
$es-plain-text-22px-800-typography-letter-spacing: 0;
$es-plain-text-22px-800-typography-paragraph-spacing: none;
$es-plain-text-22px-800-typography-text-case: none;
$es-plain-text-22px-800-typography-text-decoration: none;
$es-plain-text-15px-300-typography-font-family: Open Sans;
$es-plain-text-15px-300-typography-font-weight: Light;
$es-plain-text-15px-300-typography-line-height: 170%;
$es-plain-text-15px-300-typography-font-size: 15;
$es-plain-text-15px-300-typography-letter-spacing: 0;
$es-plain-text-15px-300-typography-paragraph-spacing: none;
$es-plain-text-15px-300-typography-text-case: none;
$es-plain-text-15px-300-typography-text-decoration: none;
$es-plain-text-15px-400-typography-font-family: Open Sans;
$es-plain-text-15px-400-typography-font-weight: Regular;
$es-plain-text-15px-400-typography-line-height: 170%;
$es-plain-text-15px-400-typography-font-size: 15;
$es-plain-text-15px-400-typography-letter-spacing: 0;
$es-plain-text-15px-400-typography-paragraph-spacing: none;
$es-plain-text-15px-400-typography-text-case: none;
$es-plain-text-15px-400-typography-text-decoration: none;
$es-plain-text-15px-500-typography-font-family: Open Sans;
$es-plain-text-15px-500-typography-font-weight: Medium;
$es-plain-text-15px-500-typography-line-height: 170%;
$es-plain-text-15px-500-typography-font-size: 15;
$es-plain-text-15px-500-typography-letter-spacing: 0;
$es-plain-text-15px-500-typography-paragraph-spacing: none;
$es-plain-text-15px-500-typography-text-case: none;
$es-plain-text-15px-500-typography-text-decoration: none;
$es-plain-text-15px-600-typography-font-family: Open Sans;
$es-plain-text-15px-600-typography-font-weight: SemiBold;
$es-plain-text-15px-600-typography-line-height: 170%;
$es-plain-text-15px-600-typography-font-size: 15;
$es-plain-text-15px-600-typography-letter-spacing: 0;
$es-plain-text-15px-600-typography-paragraph-spacing: none;
$es-plain-text-15px-600-typography-text-case: none;
$es-plain-text-15px-600-typography-text-decoration: none;
$es-plain-text-15px-700-typography-font-family: Open Sans;
$es-plain-text-15px-700-typography-font-weight: Bold;
$es-plain-text-15px-700-typography-line-height: 170%;
$es-plain-text-15px-700-typography-font-size: 15;
$es-plain-text-15px-700-typography-letter-spacing: 0;
$es-plain-text-15px-700-typography-paragraph-spacing: none;
$es-plain-text-15px-700-typography-text-case: none;
$es-plain-text-15px-700-typography-text-decoration: none;
$es-plain-text-15px-800-typography-font-family: Open Sans;
$es-plain-text-15px-800-typography-font-weight: ExtraBold;
$es-plain-text-15px-800-typography-line-height: 170%;
$es-plain-text-15px-800-typography-font-size: 15;
$es-plain-text-15px-800-typography-letter-spacing: 0;
$es-plain-text-15px-800-typography-paragraph-spacing: none;
$es-plain-text-15px-800-typography-text-case: none;
$es-plain-text-15px-800-typography-text-decoration: none;
$es-plain-text-14px-300-typography-font-family: Open Sans;
$es-plain-text-14px-300-typography-font-weight: Light;
$es-plain-text-14px-300-typography-line-height: 170%;
$es-plain-text-14px-300-typography-font-size: 14;
$es-plain-text-14px-300-typography-letter-spacing: 0;
$es-plain-text-14px-300-typography-paragraph-spacing: none;
$es-plain-text-14px-300-typography-text-case: none;
$es-plain-text-14px-300-typography-text-decoration: none;
$es-plain-text-14px-400-typography-font-family: Open Sans;
$es-plain-text-14px-400-typography-font-weight: Regular;
$es-plain-text-14px-400-typography-line-height: 170%;
$es-plain-text-14px-400-typography-font-size: 14;
$es-plain-text-14px-400-typography-letter-spacing: 0;
$es-plain-text-14px-400-typography-paragraph-spacing: none;
$es-plain-text-14px-400-typography-text-case: none;
$es-plain-text-14px-400-typography-text-decoration: none;
$es-plain-text-14px-500-typography-font-family: Open Sans;
$es-plain-text-14px-500-typography-font-weight: Medium;
$es-plain-text-14px-500-typography-line-height: 170%;
$es-plain-text-14px-500-typography-font-size: 14;
$es-plain-text-14px-500-typography-letter-spacing: 0;
$es-plain-text-14px-500-typography-paragraph-spacing: none;
$es-plain-text-14px-500-typography-text-case: none;
$es-plain-text-14px-500-typography-text-decoration: none;
$es-plain-text-14px-600-typography-font-family: Open Sans;
$es-plain-text-14px-600-typography-font-weight: SemiBold;
$es-plain-text-14px-600-typography-line-height: 170%;
$es-plain-text-14px-600-typography-font-size: 14;
$es-plain-text-14px-600-typography-letter-spacing: 0;
$es-plain-text-14px-600-typography-paragraph-spacing: none;
$es-plain-text-14px-600-typography-text-case: none;
$es-plain-text-14px-600-typography-text-decoration: none;
$es-plain-text-14px-700-typography-font-family: Open Sans;
$es-plain-text-14px-700-typography-font-weight: Bold;
$es-plain-text-14px-700-typography-line-height: 170%;
$es-plain-text-14px-700-typography-font-size: 14;
$es-plain-text-14px-700-typography-letter-spacing: 0;
$es-plain-text-14px-700-typography-paragraph-spacing: none;
$es-plain-text-14px-700-typography-text-case: none;
$es-plain-text-14px-700-typography-text-decoration: none;
$es-plain-text-14px-800-typography-font-family: Open Sans;
$es-plain-text-14px-800-typography-font-weight: ExtraBold;
$es-plain-text-14px-800-typography-line-height: 170%;
$es-plain-text-14px-800-typography-font-size: 14;
$es-plain-text-14px-800-typography-letter-spacing: 0;
$es-plain-text-14px-800-typography-paragraph-spacing: none;
$es-plain-text-14px-800-typography-text-case: none;
$es-plain-text-14px-800-typography-text-decoration: none;
$es-plain-text-12px-300-typography-font-family: Open Sans;
$es-plain-text-12px-300-typography-font-weight: Light;
$es-plain-text-12px-300-typography-line-height: 170%;
$es-plain-text-12px-300-typography-font-size: 12;
$es-plain-text-12px-300-typography-letter-spacing: 0;
$es-plain-text-12px-300-typography-paragraph-spacing: none;
$es-plain-text-12px-300-typography-text-case: none;
$es-plain-text-12px-300-typography-text-decoration: none;
$es-plain-text-12px-400-typography-font-family: Open Sans;
$es-plain-text-12px-400-typography-font-weight: Regular;
$es-plain-text-12px-400-typography-line-height: 170%;
$es-plain-text-12px-400-typography-font-size: 12;
$es-plain-text-12px-400-typography-letter-spacing: 0;
$es-plain-text-12px-400-typography-paragraph-spacing: none;
$es-plain-text-12px-400-typography-text-case: none;
$es-plain-text-12px-400-typography-text-decoration: none;
$es-plain-text-12px-500-typography-font-family: Open Sans;
$es-plain-text-12px-500-typography-font-weight: Medium;
$es-plain-text-12px-500-typography-line-height: 170%;
$es-plain-text-12px-500-typography-font-size: 12;
$es-plain-text-12px-500-typography-letter-spacing: 0;
$es-plain-text-12px-500-typography-paragraph-spacing: none;
$es-plain-text-12px-500-typography-text-case: none;
$es-plain-text-12px-500-typography-text-decoration: none;
$es-plain-text-12px-600-typography-font-family: Open Sans;
$es-plain-text-12px-600-typography-font-weight: SemiBold;
$es-plain-text-12px-600-typography-line-height: 170%;
$es-plain-text-12px-600-typography-font-size: 12;
$es-plain-text-12px-600-typography-letter-spacing: 0;
$es-plain-text-12px-600-typography-paragraph-spacing: none;
$es-plain-text-12px-600-typography-text-case: none;
$es-plain-text-12px-600-typography-text-decoration: none;
$es-plain-text-12px-700-typography-font-family: Open Sans;
$es-plain-text-12px-700-typography-font-weight: Bold;
$es-plain-text-12px-700-typography-line-height: 170%;
$es-plain-text-12px-700-typography-font-size: 12;
$es-plain-text-12px-700-typography-letter-spacing: 0;
$es-plain-text-12px-700-typography-paragraph-spacing: none;
$es-plain-text-12px-700-typography-text-case: none;
$es-plain-text-12px-700-typography-text-decoration: none;
$es-plain-text-12px-800-typography-font-family: Open Sans;
$es-plain-text-12px-800-typography-font-weight: ExtraBold;
$es-plain-text-12px-800-typography-line-height: 170%;
$es-plain-text-12px-800-typography-font-size: 12;
$es-plain-text-12px-800-typography-letter-spacing: 0;
$es-plain-text-12px-800-typography-paragraph-spacing: none;
$es-plain-text-12px-800-typography-text-case: none;
$es-plain-text-12px-800-typography-text-decoration: none;
$es-plain-text-10px-300-typography-font-family: Open Sans;
$es-plain-text-10px-300-typography-font-weight: Light;
$es-plain-text-10px-300-typography-line-height: 170%;
$es-plain-text-10px-300-typography-font-size: 10;
$es-plain-text-10px-300-typography-letter-spacing: 0;
$es-plain-text-10px-300-typography-paragraph-spacing: none;
$es-plain-text-10px-300-typography-text-case: none;
$es-plain-text-10px-300-typography-text-decoration: none;
$es-plain-text-10px-400-typography-font-family: Open Sans;
$es-plain-text-10px-400-typography-font-weight: Regular;
$es-plain-text-10px-400-typography-line-height: 170%;
$es-plain-text-10px-400-typography-font-size: 10;
$es-plain-text-10px-400-typography-letter-spacing: 0;
$es-plain-text-10px-400-typography-paragraph-spacing: none;
$es-plain-text-10px-400-typography-text-case: none;
$es-plain-text-10px-400-typography-text-decoration: none;
$es-plain-text-10px-500-typography-font-family: Open Sans;
$es-plain-text-10px-500-typography-font-weight: Medium;
$es-plain-text-10px-500-typography-line-height: 170%;
$es-plain-text-10px-500-typography-font-size: 10;
$es-plain-text-10px-500-typography-letter-spacing: 0;
$es-plain-text-10px-500-typography-paragraph-spacing: none;
$es-plain-text-10px-500-typography-text-case: none;
$es-plain-text-10px-500-typography-text-decoration: none;
$es-plain-text-10px-600-typography-font-family: Open Sans;
$es-plain-text-10px-600-typography-font-weight: SemiBold;
$es-plain-text-10px-600-typography-line-height: 170%;
$es-plain-text-10px-600-typography-font-size: 10;
$es-plain-text-10px-600-typography-letter-spacing: 0;
$es-plain-text-10px-600-typography-paragraph-spacing: none;
$es-plain-text-10px-600-typography-text-case: none;
$es-plain-text-10px-600-typography-text-decoration: none;
$es-plain-text-10px-700-typography-font-family: Open Sans;
$es-plain-text-10px-700-typography-font-weight: Bold;
$es-plain-text-10px-700-typography-line-height: 170%;
$es-plain-text-10px-700-typography-font-size: 10;
$es-plain-text-10px-700-typography-letter-spacing: 0;
$es-plain-text-10px-700-typography-paragraph-spacing: none;
$es-plain-text-10px-700-typography-text-case: none;
$es-plain-text-10px-700-typography-text-decoration: none;
$es-plain-text-10px-800-typography-font-family: Open Sans;
$es-plain-text-10px-800-typography-font-weight: ExtraBold;
$es-plain-text-10px-800-typography-line-height: 170%;
$es-plain-text-10px-800-typography-font-size: 10;
$es-plain-text-10px-800-typography-letter-spacing: 0;
$es-plain-text-10px-800-typography-paragraph-spacing: none;
$es-plain-text-10px-800-typography-text-case: none;
$es-plain-text-10px-800-typography-text-decoration: none;
$es-plain-text-16px-300-typography-font-family: Open Sans;
$es-plain-text-16px-300-typography-font-weight: Light;
$es-plain-text-16px-300-typography-line-height: 170%;
$es-plain-text-16px-300-typography-font-size: 16;
$es-plain-text-16px-300-typography-letter-spacing: 0;
$es-plain-text-16px-300-typography-paragraph-spacing: none;
$es-plain-text-16px-300-typography-text-case: none;
$es-plain-text-16px-300-typography-text-decoration: none;
$es-plain-text-16px-400-typography-font-family: Open Sans;
$es-plain-text-16px-400-typography-font-weight: Regular;
$es-plain-text-16px-400-typography-line-height: 170%;
$es-plain-text-16px-400-typography-font-size: 16;
$es-plain-text-16px-400-typography-letter-spacing: 0;
$es-plain-text-16px-400-typography-paragraph-spacing: none;
$es-plain-text-16px-400-typography-text-case: none;
$es-plain-text-16px-400-typography-text-decoration: none;
$es-plain-text-16px-500-typography-font-family: Open Sans;
$es-plain-text-16px-500-typography-font-weight: Medium;
$es-plain-text-16px-500-typography-line-height: 170%;
$es-plain-text-16px-500-typography-font-size: 16;
$es-plain-text-16px-500-typography-letter-spacing: 0;
$es-plain-text-16px-500-typography-paragraph-spacing: none;
$es-plain-text-16px-500-typography-text-case: none;
$es-plain-text-16px-500-typography-text-decoration: none;
$es-plain-text-16px-600-typography-font-family: Open Sans;
$es-plain-text-16px-600-typography-font-weight: SemiBold;
$es-plain-text-16px-600-typography-line-height: 170%;
$es-plain-text-16px-600-typography-font-size: 16;
$es-plain-text-16px-600-typography-letter-spacing: 0;
$es-plain-text-16px-600-typography-paragraph-spacing: none;
$es-plain-text-16px-600-typography-text-case: none;
$es-plain-text-16px-600-typography-text-decoration: none;
$es-plain-text-16px-700-typography-font-family: Open Sans;
$es-plain-text-16px-700-typography-font-weight: Bold;
$es-plain-text-16px-700-typography-line-height: 170%;
$es-plain-text-16px-700-typography-font-size: 16;
$es-plain-text-16px-700-typography-letter-spacing: 0;
$es-plain-text-16px-700-typography-paragraph-spacing: none;
$es-plain-text-16px-700-typography-text-case: none;
$es-plain-text-16px-700-typography-text-decoration: none;
$es-plain-text-16px-800-typography-font-family: Open Sans;
$es-plain-text-16px-800-typography-font-weight: ExtraBold;
$es-plain-text-16px-800-typography-line-height: 170%;
$es-plain-text-16px-800-typography-font-size: 16;
$es-plain-text-16px-800-typography-letter-spacing: 0;
$es-plain-text-16px-800-typography-paragraph-spacing: none;
$es-plain-text-16px-800-typography-text-case: none;
$es-plain-text-16px-800-typography-text-decoration: none;
$es-control-text-10px-300-typography-font-family: Open Sans;
$es-control-text-10px-300-typography-font-weight: Light;
$es-control-text-10px-300-typography-line-height: 100%;
$es-control-text-10px-300-typography-font-size: 10;
$es-control-text-10px-300-typography-letter-spacing: 0;
$es-control-text-10px-300-typography-text-case: none;
$es-control-text-10px-400-typography-font-family: Open Sans;
$es-control-text-10px-400-typography-font-weight: Regular;
$es-control-text-10px-400-typography-line-height: 100%;
$es-control-text-10px-400-typography-font-size: 10;
$es-control-text-10px-400-typography-letter-spacing: 0;
$es-control-text-10px-500-typography-font-family: Open Sans;
$es-control-text-10px-500-typography-font-weight: Medium;
$es-control-text-10px-500-typography-line-height: 100%;
$es-control-text-10px-500-typography-font-size: 10;
$es-control-text-10px-500-typography-letter-spacing: 0;
$es-control-text-10px-600-typography-font-family: Open Sans;
$es-control-text-10px-600-typography-font-weight: SemiBold;
$es-control-text-10px-600-typography-line-height: 100%;
$es-control-text-10px-600-typography-font-size: 10;
$es-control-text-10px-600-typography-letter-spacing: 0;
$es-control-text-10px-700-typography-font-family: Open Sans;
$es-control-text-10px-700-typography-font-weight: Bold;
$es-control-text-10px-700-typography-line-height: 100%;
$es-control-text-10px-700-typography-font-size: 10;
$es-control-text-10px-700-typography-letter-spacing: 0;
$es-control-text-10px-800-typography-font-family: Open Sans;
$es-control-text-10px-800-typography-font-weight: ExtraBold;
$es-control-text-10px-800-typography-line-height: 100%;
$es-control-text-10px-800-typography-font-size: 10;
$es-control-text-10px-800-typography-letter-spacing: 0;
$es-control-text-11px-300-typography-font-family: Open Sans;
$es-control-text-11px-300-typography-font-weight: Light;
$es-control-text-11px-300-typography-line-height: 100%;
$es-control-text-11px-300-typography-font-size: 11;
$es-control-text-11px-300-typography-letter-spacing: 0;
$es-control-text-11px-300-typography-text-case: none;
$es-control-text-11px-400-typography-font-family: Open Sans;
$es-control-text-11px-400-typography-font-weight: Regular;
$es-control-text-11px-400-typography-line-height: 100%;
$es-control-text-11px-400-typography-font-size: 11;
$es-control-text-11px-400-typography-letter-spacing: 0;
$es-control-text-11px-500-typography-font-family: Open Sans;
$es-control-text-11px-500-typography-font-weight: Medium;
$es-control-text-11px-500-typography-line-height: 100%;
$es-control-text-11px-500-typography-font-size: 11;
$es-control-text-11px-500-typography-letter-spacing: 0;
$es-control-text-11px-600-typography-font-family: Open Sans;
$es-control-text-11px-600-typography-font-weight: SemiBold;
$es-control-text-11px-600-typography-line-height: 100%;
$es-control-text-11px-600-typography-font-size: 11;
$es-control-text-11px-600-typography-letter-spacing: 0;
$es-control-text-11px-700-typography-font-family: Open Sans;
$es-control-text-11px-700-typography-font-weight: Bold;
$es-control-text-11px-700-typography-line-height: 100%;
$es-control-text-11px-700-typography-font-size: 11;
$es-control-text-11px-700-typography-letter-spacing: 0;
$es-control-text-11px-800-typography-font-family: Open Sans;
$es-control-text-11px-800-typography-font-weight: ExtraBold;
$es-control-text-11px-800-typography-line-height: 100%;
$es-control-text-11px-800-typography-font-size: 11;
$es-control-text-11px-800-typography-letter-spacing: 0;
$es-control-text-12px-300-typography-font-family: Open Sans;
$es-control-text-12px-300-typography-font-weight: Light;
$es-control-text-12px-300-typography-line-height: 100%;
$es-control-text-12px-300-typography-font-size: 12;
$es-control-text-12px-300-typography-letter-spacing: 0;
$es-control-text-12px-300-typography-text-case: none;
$es-control-text-12px-400-typography-font-family: Open Sans;
$es-control-text-12px-400-typography-font-weight: Regular;
$es-control-text-12px-400-typography-line-height: 100%;
$es-control-text-12px-400-typography-font-size: 12;
$es-control-text-12px-400-typography-letter-spacing: 0;
$es-control-text-12px-500-typography-font-family: Open Sans;
$es-control-text-12px-500-typography-font-weight: Medium;
$es-control-text-12px-500-typography-line-height: 100%;
$es-control-text-12px-500-typography-font-size: 12;
$es-control-text-12px-500-typography-letter-spacing: 0;
$es-control-text-12px-600-typography-font-family: Open Sans;
$es-control-text-12px-600-typography-font-weight: SemiBold;
$es-control-text-12px-600-typography-line-height: 100%;
$es-control-text-12px-600-typography-font-size: 12;
$es-control-text-12px-600-typography-letter-spacing: 0;
$es-control-text-12px-700-typography-font-family: Open Sans;
$es-control-text-12px-700-typography-font-weight: Bold;
$es-control-text-12px-700-typography-line-height: 100%;
$es-control-text-12px-700-typography-font-size: 12;
$es-control-text-12px-700-typography-letter-spacing: 0;
$es-control-text-12px-800-typography-font-family: Open Sans;
$es-control-text-12px-800-typography-font-weight: ExtraBold;
$es-control-text-12px-800-typography-line-height: 100%;
$es-control-text-12px-800-typography-font-size: 12;
$es-control-text-12px-800-typography-letter-spacing: 0;
$es-control-text-13px-300-typography-font-family: Open Sans;
$es-control-text-13px-300-typography-font-weight: Light;
$es-control-text-13px-300-typography-line-height: 100%;
$es-control-text-13px-300-typography-font-size: 13;
$es-control-text-13px-300-typography-letter-spacing: 0;
$es-control-text-13px-300-typography-text-case: none;
$es-control-text-13px-400-typography-font-family: Open Sans;
$es-control-text-13px-400-typography-font-weight: Regular;
$es-control-text-13px-400-typography-line-height: 100%;
$es-control-text-13px-400-typography-font-size: 13;
$es-control-text-13px-400-typography-letter-spacing: 0;
$es-control-text-13px-500-typography-font-family: Open Sans;
$es-control-text-13px-500-typography-font-weight: Medium;
$es-control-text-13px-500-typography-line-height: 100%;
$es-control-text-13px-500-typography-font-size: 13;
$es-control-text-13px-500-typography-letter-spacing: 0;
$es-control-text-13px-600-typography-font-family: Open Sans;
$es-control-text-13px-600-typography-font-weight: SemiBold;
$es-control-text-13px-600-typography-line-height: 100%;
$es-control-text-13px-600-typography-font-size: 13;
$es-control-text-13px-600-typography-letter-spacing: 0;
$es-control-text-13px-700-typography-font-family: Open Sans;
$es-control-text-13px-700-typography-font-weight: Bold;
$es-control-text-13px-700-typography-line-height: 100%;
$es-control-text-13px-700-typography-font-size: 13;
$es-control-text-13px-700-typography-letter-spacing: 0;
$es-control-text-13px-800-typography-font-family: Open Sans;
$es-control-text-13px-800-typography-font-weight: ExtraBold;
$es-control-text-13px-800-typography-line-height: 100%;
$es-control-text-13px-800-typography-font-size: 13;
$es-control-text-13px-800-typography-letter-spacing: 0;
$es-control-text-14px-300-typography-font-family: Open Sans;
$es-control-text-14px-300-typography-font-weight: Light;
$es-control-text-14px-300-typography-line-height: 100%;
$es-control-text-14px-300-typography-font-size: 14;
$es-control-text-14px-300-typography-letter-spacing: 0;
$es-control-text-14px-300-typography-text-case: none;
$es-control-text-14px-400-typography-font-family: Open Sans;
$es-control-text-14px-400-typography-font-weight: Regular;
$es-control-text-14px-400-typography-line-height: 100%;
$es-control-text-14px-400-typography-font-size: 14;
$es-control-text-14px-400-typography-letter-spacing: 0;
$es-control-text-14px-500-typography-font-family: Open Sans;
$es-control-text-14px-500-typography-font-weight: Medium;
$es-control-text-14px-500-typography-line-height: 100%;
$es-control-text-14px-500-typography-font-size: 14;
$es-control-text-14px-500-typography-letter-spacing: 0;
$es-control-text-14px-600-typography-font-family: Open Sans;
$es-control-text-14px-600-typography-font-weight: SemiBold;
$es-control-text-14px-600-typography-line-height: 100%;
$es-control-text-14px-600-typography-font-size: 14;
$es-control-text-14px-600-typography-letter-spacing: 0;
$es-control-text-14px-700-typography-font-family: Open Sans;
$es-control-text-14px-700-typography-font-weight: Bold;
$es-control-text-14px-700-typography-line-height: 100%;
$es-control-text-14px-700-typography-font-size: 14;
$es-control-text-14px-700-typography-letter-spacing: 0;
$es-control-text-14px-800-typography-font-family: Open Sans;
$es-control-text-14px-800-typography-font-weight: ExtraBold;
$es-control-text-14px-800-typography-line-height: 100%;
$es-control-text-14px-800-typography-font-size: 14;
$es-control-text-14px-800-typography-letter-spacing: 0;
$es-control-text-15px-300-typography-font-family: Open Sans;
$es-control-text-15px-300-typography-font-weight: Light;
$es-control-text-15px-300-typography-line-height: 100%;
$es-control-text-15px-300-typography-font-size: 15;
$es-control-text-15px-300-typography-letter-spacing: 0;
$es-control-text-15px-300-typography-text-case: none;
$es-control-text-15px-400-typography-font-family: Open Sans;
$es-control-text-15px-400-typography-font-weight: Regular;
$es-control-text-15px-400-typography-line-height: 100%;
$es-control-text-15px-400-typography-font-size: 15;
$es-control-text-15px-400-typography-letter-spacing: 0;
$es-control-text-15px-500-typography-font-family: Open Sans;
$es-control-text-15px-500-typography-font-weight: Medium;
$es-control-text-15px-500-typography-line-height: 100%;
$es-control-text-15px-500-typography-font-size: 15;
$es-control-text-15px-500-typography-letter-spacing: 0;
$es-control-text-15px-600-typography-font-family: Open Sans;
$es-control-text-15px-600-typography-font-weight: SemiBold;
$es-control-text-15px-600-typography-line-height: 100%;
$es-control-text-15px-600-typography-font-size: 15;
$es-control-text-15px-600-typography-letter-spacing: 0;
$es-control-text-15px-700-typography-font-family: Open Sans;
$es-control-text-15px-700-typography-font-weight: Bold;
$es-control-text-15px-700-typography-line-height: 100%;
$es-control-text-15px-700-typography-font-size: 15;
$es-control-text-15px-700-typography-letter-spacing: 0;
$es-control-text-15px-800-typography-font-family: Open Sans;
$es-control-text-15px-800-typography-font-weight: ExtraBold;
$es-control-text-15px-800-typography-line-height: 100%;
$es-control-text-15px-800-typography-font-size: 15;
$es-control-text-15px-800-typography-letter-spacing: 0;
$es-control-text-16px-300-typography-font-family: Open Sans;
$es-control-text-16px-300-typography-font-weight: Light;
$es-control-text-16px-300-typography-line-height: 100%;
$es-control-text-16px-300-typography-font-size: 16;
$es-control-text-16px-300-typography-letter-spacing: 0;
$es-control-text-16px-300-typography-text-case: none;
$es-control-text-16px-400-typography-font-family: Open Sans;
$es-control-text-16px-400-typography-font-weight: Regular;
$es-control-text-16px-400-typography-line-height: 100%;
$es-control-text-16px-400-typography-font-size: 16;
$es-control-text-16px-400-typography-letter-spacing: 0;
$es-control-text-16px-500-typography-font-family: Open Sans;
$es-control-text-16px-500-typography-font-weight: Medium;
$es-control-text-16px-500-typography-line-height: 100%;
$es-control-text-16px-500-typography-font-size: 16;
$es-control-text-16px-500-typography-letter-spacing: 0;
$es-control-text-16px-600-typography-font-family: Open Sans;
$es-control-text-16px-600-typography-font-weight: SemiBold;
$es-control-text-16px-600-typography-line-height: 100%;
$es-control-text-16px-600-typography-font-size: 16;
$es-control-text-16px-600-typography-letter-spacing: 0;
$es-control-text-16px-700-typography-font-family: Open Sans;
$es-control-text-16px-700-typography-font-weight: Bold;
$es-control-text-16px-700-typography-line-height: 100%;
$es-control-text-16px-700-typography-font-size: 16;
$es-control-text-16px-700-typography-letter-spacing: 0;
$es-control-text-16px-800-typography-font-family: Open Sans;
$es-control-text-16px-800-typography-font-weight: ExtraBold;
$es-control-text-16px-800-typography-line-height: 100%;
$es-control-text-16px-800-typography-font-size: 16;
$es-control-text-16px-800-typography-letter-spacing: 0;
$es-control-text-18px-300-typography-font-family: Open Sans;
$es-control-text-18px-300-typography-font-weight: Light;
$es-control-text-18px-300-typography-line-height: 100%;
$es-control-text-18px-300-typography-font-size: 18;
$es-control-text-18px-300-typography-letter-spacing: 0;
$es-control-text-18px-300-typography-text-case: none;
$es-control-text-18px-400-typography-font-family: Open Sans;
$es-control-text-18px-400-typography-font-weight: Regular;
$es-control-text-18px-400-typography-line-height: 100%;
$es-control-text-18px-400-typography-font-size: 18;
$es-control-text-18px-400-typography-letter-spacing: 0;
$es-control-text-18px-500-typography-font-family: Open Sans;
$es-control-text-18px-500-typography-font-weight: Medium;
$es-control-text-18px-500-typography-line-height: 100%;
$es-control-text-18px-500-typography-font-size: 18;
$es-control-text-18px-500-typography-letter-spacing: 0;
$es-control-text-18px-600-typography-font-family: Open Sans;
$es-control-text-18px-600-typography-font-weight: SemiBold;
$es-control-text-18px-600-typography-line-height: 100%;
$es-control-text-18px-600-typography-font-size: 18;
$es-control-text-18px-600-typography-letter-spacing: 0;
$es-control-text-18px-700-typography-font-family: Open Sans;
$es-control-text-18px-700-typography-font-weight: Bold;
$es-control-text-18px-700-typography-line-height: 100%;
$es-control-text-18px-700-typography-font-size: 18;
$es-control-text-18px-700-typography-letter-spacing: 0;
$es-control-text-18px-800-typography-font-family: Open Sans;
$es-control-text-18px-800-typography-font-weight: ExtraBold;
$es-control-text-18px-800-typography-line-height: 100%;
$es-control-text-18px-800-typography-font-size: 18;
$es-control-text-18px-800-typography-letter-spacing: 0;
$es-control-text-20px-300-typography-font-family: Open Sans;
$es-control-text-20px-300-typography-font-weight: Light;
$es-control-text-20px-300-typography-line-height: 100%;
$es-control-text-20px-300-typography-font-size: 20;
$es-control-text-20px-300-typography-letter-spacing: 0;
$es-control-text-20px-300-typography-text-case: none;
$es-control-text-20px-400-typography-font-family: Open Sans;
$es-control-text-20px-400-typography-font-weight: Regular;
$es-control-text-20px-400-typography-line-height: 100%;
$es-control-text-20px-400-typography-font-size: 20;
$es-control-text-20px-400-typography-letter-spacing: 0;
$es-control-text-20px-500-typography-font-family: Open Sans;
$es-control-text-20px-500-typography-font-weight: Medium;
$es-control-text-20px-500-typography-line-height: 100%;
$es-control-text-20px-500-typography-font-size: 20;
$es-control-text-20px-500-typography-letter-spacing: 0;
$es-control-text-20px-600-typography-font-family: Open Sans;
$es-control-text-20px-600-typography-font-weight: SemiBold;
$es-control-text-20px-600-typography-line-height: 100%;
$es-control-text-20px-600-typography-font-size: 20;
$es-control-text-20px-600-typography-letter-spacing: 0;
$es-control-text-20px-700-typography-font-family: Open Sans;
$es-control-text-20px-700-typography-font-weight: Bold;
$es-control-text-20px-700-typography-line-height: 100%;
$es-control-text-20px-700-typography-font-size: 20;
$es-control-text-20px-700-typography-letter-spacing: 0;
$es-control-text-20px-800-typography-font-family: Open Sans;
$es-control-text-20px-800-typography-font-weight: ExtraBold;
$es-control-text-20px-800-typography-line-height: 100%;
$es-control-text-20px-800-typography-font-size: 20;
$es-control-text-20px-800-typography-letter-spacing: 0;
$es-control-text-24px-300-typography-font-family: Open Sans;
$es-control-text-24px-300-typography-font-weight: Light;
$es-control-text-24px-300-typography-line-height: 100%;
$es-control-text-24px-300-typography-font-size: 24;
$es-control-text-24px-300-typography-letter-spacing: 0;
$es-control-text-24px-300-typography-text-case: none;
$es-control-text-24px-400-typography-font-family: Open Sans;
$es-control-text-24px-400-typography-font-weight: Regular;
$es-control-text-24px-400-typography-line-height: 100%;
$es-control-text-24px-400-typography-font-size: 24;
$es-control-text-24px-400-typography-letter-spacing: 0;
$es-control-text-24px-500-typography-font-family: Open Sans;
$es-control-text-24px-500-typography-font-weight: Medium;
$es-control-text-24px-500-typography-line-height: 100%;
$es-control-text-24px-500-typography-font-size: 24;
$es-control-text-24px-500-typography-letter-spacing: 0;
$es-control-text-24px-600-typography-font-family: Open Sans;
$es-control-text-24px-600-typography-font-weight: SemiBold;
$es-control-text-24px-600-typography-line-height: 100%;
$es-control-text-24px-600-typography-font-size: 24;
$es-control-text-24px-600-typography-letter-spacing: 0;
$es-control-text-24px-700-typography-font-family: Open Sans;
$es-control-text-24px-700-typography-font-weight: Bold;
$es-control-text-24px-700-typography-line-height: 100%;
$es-control-text-24px-700-typography-font-size: 24;
$es-control-text-24px-700-typography-letter-spacing: 0;
$es-control-text-24px-800-typography-font-family: Open Sans;
$es-control-text-24px-800-typography-font-weight: ExtraBold;
$es-control-text-24px-800-typography-line-height: 100%;
$es-control-text-24px-800-typography-font-size: 24;
$es-control-text-24px-800-typography-letter-spacing: 0;
$es-control-text-28px-300-typography-font-family: Open Sans;
$es-control-text-28px-300-typography-font-weight: Light;
$es-control-text-28px-300-typography-line-height: 100%;
$es-control-text-28px-300-typography-font-size: 28;
$es-control-text-28px-300-typography-letter-spacing: 0;
$es-control-text-28px-300-typography-text-case: none;
$es-control-text-28px-400-typography-font-family: Open Sans;
$es-control-text-28px-400-typography-font-weight: Regular;
$es-control-text-28px-400-typography-line-height: 100%;
$es-control-text-28px-400-typography-font-size: 28;
$es-control-text-28px-400-typography-letter-spacing: 0;
$es-control-text-28px-500-typography-font-family: Open Sans;
$es-control-text-28px-500-typography-font-weight: Medium;
$es-control-text-28px-500-typography-line-height: 100%;
$es-control-text-28px-500-typography-font-size: 28;
$es-control-text-28px-500-typography-letter-spacing: 0;
$es-control-text-28px-600-typography-font-family: Open Sans;
$es-control-text-28px-600-typography-font-weight: SemiBold;
$es-control-text-28px-600-typography-line-height: 100%;
$es-control-text-28px-600-typography-font-size: 28;
$es-control-text-28px-600-typography-letter-spacing: 0;
$es-control-text-28px-700-typography-font-family: Open Sans;
$es-control-text-28px-700-typography-font-weight: Bold;
$es-control-text-28px-700-typography-line-height: 100%;
$es-control-text-28px-700-typography-font-size: 28;
$es-control-text-28px-700-typography-letter-spacing: 0;
$es-control-text-28px-800-typography-font-family: Open Sans;
$es-control-text-28px-800-typography-font-weight: ExtraBold;
$es-control-text-28px-800-typography-line-height: 100%;
$es-control-text-28px-800-typography-font-size: 28;
$es-control-text-28px-800-typography-letter-spacing: 0;
$es-control-text-32px-300-typography-font-family: Open Sans;
$es-control-text-32px-300-typography-font-weight: Light;
$es-control-text-32px-300-typography-line-height: 100%;
$es-control-text-32px-300-typography-font-size: 32;
$es-control-text-32px-300-typography-letter-spacing: 0;
$es-control-text-32px-300-typography-text-case: none;
$es-control-text-32px-400-typography-font-family: Open Sans;
$es-control-text-32px-400-typography-font-weight: Regular;
$es-control-text-32px-400-typography-line-height: 100%;
$es-control-text-32px-400-typography-font-size: 32;
$es-control-text-32px-400-typography-letter-spacing: 0;
$es-control-text-32px-500-typography-font-family: Open Sans;
$es-control-text-32px-500-typography-font-weight: Medium;
$es-control-text-32px-500-typography-line-height: 100%;
$es-control-text-32px-500-typography-font-size: 32;
$es-control-text-32px-500-typography-letter-spacing: 0;
$es-control-text-32px-600-typography-font-family: Open Sans;
$es-control-text-32px-600-typography-font-weight: SemiBold;
$es-control-text-32px-600-typography-line-height: 100%;
$es-control-text-32px-600-typography-font-size: 32;
$es-control-text-32px-600-typography-letter-spacing: 0;
$es-control-text-32px-700-typography-font-family: Open Sans;
$es-control-text-32px-700-typography-font-weight: Bold;
$es-control-text-32px-700-typography-line-height: 100%;
$es-control-text-32px-700-typography-font-size: 32;
$es-control-text-32px-700-typography-letter-spacing: 0;
$es-control-text-32px-800-typography-font-family: Open Sans;
$es-control-text-32px-800-typography-font-weight: ExtraBold;
$es-control-text-32px-800-typography-line-height: 100%;
$es-control-text-32px-800-typography-font-size: 32;
$es-control-text-32px-800-typography-letter-spacing: 0;
$es-logo-s-typography-font-family: Open Sans;
$es-logo-s-typography-font-weight: SemiBold;
$es-logo-s-typography-line-height: 145%;
$es-logo-s-typography-font-size: 8;
$es-logo-s-typography-letter-spacing: 0;
$es-logo-s-typography-paragraph-spacing: none;
$es-logo-s-typography-text-case: none;
$es-logo-s-typography-text-decoration: none;
$es-material-xxxxs-box-shadow-x: 0;
$es-material-xxxxs-box-shadow-y: 1;
$es-material-xxxxs-box-shadow-blur: 3;
$es-material-xxxxs-box-shadow-spread: 0;
$es-material-xxxxs-box-shadow-color: #00000026;
$es-material-xxxxs-box-shadow-type: dropShadow;
$es-material-xxxs-box-shadow-x: 0;
$es-material-xxxs-box-shadow-y: 1;
$es-material-xxxs-box-shadow-blur: 2;
$es-material-xxxs-box-shadow-spread: 0;
$es-material-xxxs-box-shadow-color: #00000040;
$es-material-xxxs-box-shadow-type: dropShadow;
$es-material-xxs-box-shadow-x: 0;
$es-material-xxs-box-shadow-y: 2;
$es-material-xxs-box-shadow-blur: 2;
$es-material-xxs-box-shadow-spread: 0;
$es-material-xxs-box-shadow-color: #00000040;
$es-material-xxs-box-shadow-type: dropShadow;
$es-material-xs-box-shadow-x: 0;
$es-material-xs-box-shadow-y: 3;
$es-material-xs-box-shadow-blur: 4;
$es-material-xs-box-shadow-spread: 0;
$es-material-xs-box-shadow-color: #0000004d;
$es-material-xs-box-shadow-type: dropShadow;
$es-material-s-box-shadow-x: 0;
$es-material-s-box-shadow-y: 3;
$es-material-s-box-shadow-blur: 4;
$es-material-s-box-shadow-spread: 0;
$es-material-s-box-shadow-color: #00000059;
$es-material-s-box-shadow-type: dropShadow;
$es-material-m-box-shadow-x: 0;
$es-material-m-box-shadow-y: 4;
$es-material-m-box-shadow-blur: 7;
$es-material-m-box-shadow-spread: 0;
$es-material-m-box-shadow-color: #00000026;
$es-material-m-box-shadow-type: dropShadow;
$es-material-l-box-shadow-x: 0;
$es-material-l-box-shadow-y: 6;
$es-material-l-box-shadow-blur: 10;
$es-material-l-box-shadow-spread: 0;
$es-material-l-box-shadow-color: #00000059;
$es-material-l-box-shadow-type: dropShadow;
$es-material-xl-box-shadow-x: 0;
$es-material-xl-box-shadow-y: 8;
$es-material-xl-box-shadow-blur: 10;
$es-material-xl-box-shadow-spread: 0;
$es-material-xl-box-shadow-color: #00000059;
$es-material-xl-box-shadow-type: dropShadow;
$es-material-xxl-box-shadow-x: 0;
$es-material-xxl-box-shadow-y: 12;
$es-material-xxl-box-shadow-blur: 15;
$es-material-xxl-box-shadow-spread: 0;
$es-material-xxl-box-shadow-color: #00000059;
$es-material-xxl-box-shadow-type: dropShadow;
$es-material-xxxl-box-shadow-x: 0;
$es-material-xxxl-box-shadow-y: 14;
$es-material-xxxl-box-shadow-blur: 22;
$es-material-xxxl-box-shadow-spread: 0;
$es-material-xxxl-box-shadow-color: #00000066;
$es-material-xxxl-box-shadow-type: dropShadow;
$es-light-blur-xxxs-box-shadow-x: 0;
$es-light-blur-xxxs-box-shadow-y: 12;
$es-light-blur-xxxs-box-shadow-blur: 42;
$es-light-blur-xxxs-box-shadow-spread: 0;
$es-light-blur-xxxs-box-shadow-color: #0000000d;
$es-light-blur-xxxs-box-shadow-type: dropShadow;
$es-light-blur-xxs-box-shadow-x: 0;
$es-light-blur-xxs-box-shadow-y: 4;
$es-light-blur-xxs-box-shadow-blur: 20;
$es-light-blur-xxs-box-shadow-spread: 0;
$es-light-blur-xxs-box-shadow-color: #0000001a;
$es-light-blur-xxs-box-shadow-type: dropShadow;
$es-light-blur-xs-box-shadow-x: 0;
$es-light-blur-xs-box-shadow-y: 4;
$es-light-blur-xs-box-shadow-blur: 8;
$es-light-blur-xs-box-shadow-spread: 0;
$es-light-blur-xs-box-shadow-color: #0000001a;
$es-light-blur-xs-box-shadow-type: dropShadow;
$es-light-blur-s-box-shadow-x: 0;
$es-light-blur-s-box-shadow-y: 6;
$es-light-blur-s-box-shadow-blur: 15;
$es-light-blur-s-box-shadow-spread: 0;
$es-light-blur-s-box-shadow-color: #0000001f;
$es-light-blur-s-box-shadow-type: dropShadow;
$es-light-blur-m-box-shadow-x: 0;
$es-light-blur-m-box-shadow-y: 14;
$es-light-blur-m-box-shadow-blur: 20;
$es-light-blur-m-box-shadow-spread: 0;
$es-light-blur-m-box-shadow-color: #0000001a;
$es-light-blur-m-box-shadow-type: dropShadow;
$es-light-blur-l-box-shadow-x: 0;
$es-light-blur-l-box-shadow-y: 20;
$es-light-blur-l-box-shadow-blur: 20;
$es-light-blur-l-box-shadow-spread: 0;
$es-light-blur-l-box-shadow-color: #00000014;
$es-light-blur-l-box-shadow-type: dropShadow;
$es-light-blur-xl-box-shadow-x: 0;
$es-light-blur-xl-box-shadow-y: 16;
$es-light-blur-xl-box-shadow-blur: 30;
$es-light-blur-xl-box-shadow-spread: 0;
$es-light-blur-xl-box-shadow-color: #00000033;
$es-light-blur-xl-box-shadow-type: dropShadow;
$es-light-blur-xxl-box-shadow-x: 0;
$es-light-blur-xxl-box-shadow-y: 40;
$es-light-blur-xxl-box-shadow-blur: 60;
$es-light-blur-xxl-box-shadow-spread: 0;
$es-light-blur-xxl-box-shadow-color: #00000080;
$es-light-blur-xxl-box-shadow-type: dropShadow;
$es-color-red-light-box-shadow-x: #000000;
$es-color-red-light-box-shadow-y: #000000;
$es-color-red-light-box-shadow-blur: #000000;
$es-color-red-light-box-shadow-spread: #000000;
$es-color-red-light-box-shadow-color: rgba(255, 129, 129, 0.08);
$es-color-red-light-box-shadow-type: #000000;
$es-color-red-dark-box-shadow-x: #000000;
$es-color-red-dark-box-shadow-y: #000000;
$es-color-red-dark-box-shadow-blur: #000000;
$es-color-red-dark-box-shadow-spread: #000000;
$es-color-red-dark-box-shadow-color: rgba(255, 16, 16, 0.35);
$es-color-red-dark-box-shadow-type: #000000;
$es-color-green-light-box-shadow-x: #000000;
$es-color-green-light-box-shadow-y: #000000;
$es-color-green-light-box-shadow-blur: #000000;
$es-color-green-light-box-shadow-spread: #000000;
$es-color-green-light-box-shadow-color: rgba(129, 255, 141, 0.08);
$es-color-green-light-box-shadow-type: #000000;
$es-color-green-dark-box-shadow-x: #000000;
$es-color-green-dark-box-shadow-y: #000000;
$es-color-green-dark-box-shadow-blur: #000000;
$es-color-green-dark-box-shadow-spread: #000000;
$es-color-green-dark-box-shadow-color: rgba(46, 125, 50, 0.3);
$es-color-green-dark-box-shadow-type: #000000;
$es-dashed-border-color: #bdbdbd;
$es-dashed-border-width: 1;
$es-dashed-border-style: dashed;
$es-inner-shadow-dark-dg-up-main-box-shadow-x: 0;
$es-inner-shadow-dark-dg-up-main-box-shadow-y: 4;
$es-inner-shadow-dark-dg-up-main-box-shadow-blur: 6;
$es-inner-shadow-dark-dg-up-main-box-shadow-spread: 0;
$es-inner-shadow-dark-dg-up-main-box-shadow-color: #00000014;
$es-inner-shadow-dark-dg-up-main-box-shadow-type: innerShadow;
$es-theme-controls-button-main-l-typography-font-family: Open Sans;
$es-theme-controls-button-main-l-typography-font-weight: SemiBold;
$es-theme-controls-button-main-l-typography-line-height: 100%;
$es-theme-controls-button-main-l-typography-font-size: 18;
$es-theme-controls-button-main-l-typography-letter-spacing: 0;
$es-theme-controls-button-main-m-typography-font-family: Open Sans;
$es-theme-controls-button-main-m-typography-font-weight: Medium;
$es-theme-controls-button-main-m-typography-line-height: 100%;
$es-theme-controls-button-main-m-typography-font-size: 16;
$es-theme-controls-button-main-m-typography-letter-spacing: 0;
$es-theme-controls-button-main-s-typography-font-family: Open Sans;
$es-theme-controls-button-main-s-typography-font-weight: SemiBold;
$es-theme-controls-button-main-s-typography-line-height: 100%;
$es-theme-controls-button-main-s-typography-font-size: 12;
$es-theme-controls-button-main-s-typography-letter-spacing: 0;
$es-theme-controls-button-main-base-primary-default-bg-color: #2e7d32;
$es-theme-controls-button-main-base-primary-default-text-color: #fff;
$es-theme-controls-button-main-base-primary-default-icon-color: #fff;
$es-theme-controls-button-main-base-primary-hover-bg-color: #388e3c;
$es-theme-controls-button-main-base-primary-hover-text-color: #fff;
$es-theme-controls-button-main-base-primary-hover-icon-color: #fff;
$es-theme-controls-button-main-base-primary-active-bg-color: #29702d;
$es-theme-controls-button-main-base-primary-active-text-color: #fff;
$es-theme-controls-button-main-base-primary-active-icon-color: #fff;
$es-theme-controls-button-main-base-primary-disabled-bg-color: #2e7d324d;
$es-theme-controls-button-main-base-primary-disabled-text-color: #ffffff99;
$es-theme-controls-button-main-base-primary-disabled-icon-color: #ffffff99;
$es-theme-controls-button-main-base-secondary-default-border-color: #2e7d32;
$es-theme-controls-button-main-base-secondary-default-text-color: #2e7d32;
$es-theme-controls-button-main-base-secondary-default-icon-color: #2e7d32;
$es-theme-controls-button-main-base-secondary-hover-border-color: #388e3c;
$es-theme-controls-button-main-base-secondary-hover-text-color: #fff;
$es-theme-controls-button-main-base-secondary-hover-icon-color: #fff;
$es-theme-controls-button-main-base-secondary-active-border-color: #2e7d32;
$es-theme-controls-button-main-base-secondary-active-text-color: #1b5e20;
$es-theme-controls-button-main-base-secondary-active-icon-color: #1b5e20;
$es-theme-controls-button-main-base-secondary-disabled-border-color: #2e7d324d;
$es-theme-controls-button-main-base-secondary-disabled-text-color: #2e7d324d;
$es-theme-controls-button-main-base-secondary-disabled-icon-color: #2e7d324d;
$es-theme-controls-button-main-base-tertiary-default-text-color: #2e7d32;
$es-theme-controls-button-main-base-tertiary-default-icon-color: #2e7d32;
$es-theme-controls-button-main-base-tertiary-hover-text-color: #388e3c;
$es-theme-controls-button-main-base-tertiary-hover-icon-color: #388e3c;
$es-theme-controls-button-main-base-tertiary-active-text-color: #1b5e20;
$es-theme-controls-button-main-base-tertiary-active-icon-color: #1b5e20;
$es-theme-controls-button-main-base-tertiary-disabled-text-color: #2e7d324d;
$es-theme-controls-button-main-base-tertiary-disabled-icon-color: #2e7d324d;
$es-theme-controls-button-main-reserve-primary-default-bg-color: #ef6c00;
$es-theme-controls-button-main-reserve-primary-default-text-color: #fff;
$es-theme-controls-button-main-reserve-primary-default-icon-color: #fff;
$es-theme-controls-button-main-reserve-primary-hover-bg-color: #fb8c00;
$es-theme-controls-button-main-reserve-primary-hover-text-color: #fff;
$es-theme-controls-button-main-reserve-primary-hover-icon-color: #fff;
$es-theme-controls-button-main-reserve-primary-active-bg-color: #b74b00;
$es-theme-controls-button-main-reserve-primary-active-text-color: #fff;
$es-theme-controls-button-main-reserve-primary-active-icon-color: #fff;
$es-theme-controls-button-main-reserve-primary-disabled-bg-color: #ef6c004d;
$es-theme-controls-button-main-reserve-primary-disabled-text-color: #ffffff99;
$es-theme-controls-button-main-reserve-primary-disabled-icon-color: #ffffff99;
$es-theme-controls-button-main-reserve-secondary-default-border-color: #e86612;
$es-theme-controls-button-main-reserve-secondary-default-text-color: #e86612;
$es-theme-controls-button-main-reserve-secondary-default-icon-color: #e86612;
$es-theme-controls-button-main-reserve-secondary-hover-border-color: #ff9137;
$es-theme-controls-button-main-reserve-secondary-hover-text-color: #fff;
$es-theme-controls-button-main-reserve-secondary-hover-icon-color: #fff;
$es-theme-controls-button-main-reserve-secondary-active-border-color: #b74b00;
$es-theme-controls-button-main-reserve-secondary-active-text-color: #b74b00;
$es-theme-controls-button-main-reserve-secondary-active-icon-color: #b74b00;
$es-theme-controls-button-main-reserve-secondary-disabled-border-color: #ef6c004d;
$es-theme-controls-button-main-reserve-secondary-disabled-text-color: #ef6c004d;
$es-theme-controls-button-main-reserve-secondary-disabled-icon-color: #ef6c004d;
$es-theme-controls-button-main-reserve-tertiary-default-text-color: #e86612;
$es-theme-controls-button-main-reserve-tertiary-default-icon-color: #e86612;
$es-theme-controls-button-main-reserve-tertiary-hover-text-color: #ff9137;
$es-theme-controls-button-main-reserve-tertiary-hover-icon-color: #ff9137;
$es-theme-controls-button-main-reserve-tertiary-active-text-color: #b74b00;
$es-theme-controls-button-main-reserve-tertiary-active-icon-color: #b74b00;
$es-theme-controls-button-main-reserve-tertiary-disabled-text-color: #ef6c004d;
$es-theme-controls-button-main-reserve-tertiary-disabled-icon-color: #ef6c004d;
$es-theme-controls-button-main-error-primary-default-bg-color: #ff2706;
$es-theme-controls-button-main-error-primary-default-text-color: #fff;
$es-theme-controls-button-main-error-primary-default-icon-color: #fff;
$es-theme-controls-button-main-error-primary-hover-bg-color: #ff4d4d;
$es-theme-controls-button-main-error-primary-hover-text-color: #fff;
$es-theme-controls-button-main-error-primary-hover-icon-color: #fff;
$es-theme-controls-button-main-error-primary-active-bg-color: #c55442;
$es-theme-controls-button-main-error-primary-active-text-color: #fff;
$es-theme-controls-button-main-error-primary-active-icon-color: #fff;
$es-theme-controls-button-main-error-primary-disabled-bg-color: #ff270680;
$es-theme-controls-button-main-error-primary-disabled-text-color: #ffffff99;
$es-theme-controls-button-main-error-primary-disabled-icon-color: #ffffff99;
$es-theme-controls-button-main-error-secondary-default-border-color: #ff2706;
$es-theme-controls-button-main-error-secondary-default-text-color: #ff2706;
$es-theme-controls-button-main-error-secondary-default-icon-color: #ff2706;
$es-theme-controls-button-main-error-secondary-hover-border-color: #ff4d4d;
$es-theme-controls-button-main-error-secondary-hover-text-color: #fff;
$es-theme-controls-button-main-error-secondary-hover-icon-color: #fff;
$es-theme-controls-button-main-error-secondary-active-border-color: #c55442;
$es-theme-controls-button-main-error-secondary-active-text-color: #c55442;
$es-theme-controls-button-main-error-secondary-active-icon-color: #c55442;
$es-theme-controls-button-main-error-secondary-disabled-border-color: #ff270680;
$es-theme-controls-button-main-error-secondary-disabled-text-color: #ff270680;
$es-theme-controls-button-main-error-secondary-disabled-icon-color: #ff270680;
$es-theme-controls-button-main-error-tertiary-default-text-color: #ff2706;
$es-theme-controls-button-main-error-tertiary-default-icon-color: #ff2706;
$es-theme-controls-button-main-error-tertiary-hover-text-color: #ff4d4d;
$es-theme-controls-button-main-error-tertiary-hover-icon-color: #ff4d4d;
$es-theme-controls-button-main-error-tertiary-active-text-color: #c55442;
$es-theme-controls-button-main-error-tertiary-active-icon-color: #c55442;
$es-theme-controls-button-main-error-tertiary-disabled-text-color: #ff270680;
$es-theme-controls-button-main-error-tertiary-disabled-icon-color: #ff270680;
$es-theme-controls-button-main-grayscale-primary-default-bg-color: #464749;
$es-theme-controls-button-main-grayscale-primary-default-text-color: #fff;
$es-theme-controls-button-main-grayscale-primary-default-icon-color: #fff;
$es-theme-controls-button-main-grayscale-primary-hover-bg-color: #828282;
$es-theme-controls-button-main-grayscale-primary-hover-text-color: #fff;
$es-theme-controls-button-main-grayscale-primary-hover-icon-color: #fff;
$es-theme-controls-button-main-grayscale-primary-active-bg-color: #2c2e30;
$es-theme-controls-button-main-grayscale-primary-active-text-color: #fff;
$es-theme-controls-button-main-grayscale-primary-active-icon-color: #fff;
$es-theme-controls-button-main-grayscale-primary-disabled-bg-color: #00000033;
$es-theme-controls-button-main-grayscale-primary-disabled-text-color: #ffffff99;
$es-theme-controls-button-main-grayscale-primary-disabled-icon-color: #ffffff99;
$es-theme-controls-button-main-grayscale-secondary-default-border-color: #464749;
$es-theme-controls-button-main-grayscale-secondary-default-text-color: #464749;
$es-theme-controls-button-main-grayscale-secondary-default-icon-color: #464749;
$es-theme-controls-button-main-grayscale-secondary-hover-border-color: #828282;
$es-theme-controls-button-main-grayscale-secondary-hover-text-color: #fff;
$es-theme-controls-button-main-grayscale-secondary-hover-icon-color: #fff;
$es-theme-controls-button-main-grayscale-secondary-active-border-color: #2c2e30;
$es-theme-controls-button-main-grayscale-secondary-active-text-color: #2c2e30;
$es-theme-controls-button-main-grayscale-secondary-active-icon-color: #2c2e30;
$es-theme-controls-button-main-grayscale-secondary-disabled-border-color: #00000033;
$es-theme-controls-button-main-grayscale-secondary-disabled-text-color: #00000033;
$es-theme-controls-button-main-grayscale-secondary-disabled-icon-color: #00000033;
$es-theme-controls-button-main-grayscale-tertiary-default-text-color: #464749;
$es-theme-controls-button-main-grayscale-tertiary-default-icon-color: #464749;
$es-theme-controls-button-main-grayscale-tertiary-hover-text-color: #828282;
$es-theme-controls-button-main-grayscale-tertiary-hover-icon-color: #828282;
$es-theme-controls-button-main-grayscale-tertiary-active-text-color: #2c2e30;
$es-theme-controls-button-main-grayscale-tertiary-active-icon-color: #2c2e30;
$es-theme-controls-button-main-grayscale-tertiary-disabled-text-color: #00000033;
$es-theme-controls-button-main-grayscale-tertiary-disabled-icon-color: #00000033;
$es-theme-controls-button-main-light-primary-default-bg-color: #fff;
$es-theme-controls-button-main-light-primary-default-text-color: #373839;
$es-theme-controls-button-main-light-primary-default-icon-color: #373839;
$es-theme-controls-button-main-light-primary-hover-bg-color: #eff0f0;
$es-theme-controls-button-main-light-primary-hover-text-color: #373839;
$es-theme-controls-button-main-light-primary-hover-icon-color: #373839;
$es-theme-controls-button-main-light-primary-active-bg-color: #fff;
$es-theme-controls-button-main-light-primary-active-text-color: #373839;
$es-theme-controls-button-main-light-primary-active-icon-color: #373839;
$es-theme-controls-button-main-light-primary-disabled-bg-color: #ffffff33;
$es-theme-controls-button-main-light-primary-disabled-text-color: #828282;
$es-theme-controls-button-main-light-primary-disabled-icon-color: #828282;
$es-theme-controls-button-main-light-secondary-default-border-color: #fff;
$es-theme-controls-button-main-light-secondary-default-text-color: #fff;
$es-theme-controls-button-main-light-secondary-default-icon-color: #fff;
$es-theme-controls-button-main-light-secondary-hover-border-color: #eff0f0;
$es-theme-controls-button-main-light-secondary-hover-text-color: #373839;
$es-theme-controls-button-main-light-secondary-hover-icon-color: #373839;
$es-theme-controls-button-main-light-secondary-active-border-color: #fff;
$es-theme-controls-button-main-light-secondary-active-text-color: #fff;
$es-theme-controls-button-main-light-secondary-active-icon-color: #fff;
$es-theme-controls-button-main-light-secondary-disabled-border-color: #373839;
$es-theme-controls-button-main-light-secondary-disabled-text-color: #373839;
$es-theme-controls-button-main-light-secondary-disabled-icon-color: #373839;
$es-theme-controls-button-main-light-tertiary-default-text-color: #fff;
$es-theme-controls-button-main-light-tertiary-default-icon-color: #fff;
$es-theme-controls-button-main-light-tertiary-hover-text-color: #eff0f0;
$es-theme-controls-button-main-light-tertiary-hover-icon-color: #eff0f0;
$es-theme-controls-button-main-light-tertiary-active-text-color: #fff;
$es-theme-controls-button-main-light-tertiary-active-icon-color: #fff;
$es-theme-controls-button-main-light-tertiary-disabled-text-color: #373839;
$es-theme-controls-button-main-light-tertiary-disabled-icon-color: #373839;
$es-theme-controls-button-admin-l-typography-font-family: Open Sans;
$es-theme-controls-button-admin-l-typography-font-weight: Medium;
$es-theme-controls-button-admin-l-typography-line-height: 100%;
$es-theme-controls-button-admin-l-typography-font-size: 18;
$es-theme-controls-button-admin-l-typography-letter-spacing: 0;
$es-theme-controls-button-admin-m-typography-font-family: Open Sans;
$es-theme-controls-button-admin-m-typography-font-weight: Medium;
$es-theme-controls-button-admin-m-typography-line-height: 100%;
$es-theme-controls-button-admin-m-typography-font-size: 15;
$es-theme-controls-button-admin-m-typography-letter-spacing: 0;
$es-theme-controls-button-admin-s-typography-font-family: Open Sans;
$es-theme-controls-button-admin-s-typography-font-weight: Medium;
$es-theme-controls-button-admin-s-typography-line-height: 100%;
$es-theme-controls-button-admin-s-typography-font-size: 15;
$es-theme-controls-button-admin-s-typography-letter-spacing: 0;
$es-theme-controls-button-admin-xs-typography-font-family: Open Sans;
$es-theme-controls-button-admin-xs-typography-font-weight: Medium;
$es-theme-controls-button-admin-xs-typography-line-height: 100%;
$es-theme-controls-button-admin-xs-typography-font-size: 14;
$es-theme-controls-button-admin-xs-typography-letter-spacing: 0;
$es-theme-controls-button-admin-default-bg-color: #fff;
$es-theme-controls-button-admin-default-text-color: #000;
$es-theme-controls-button-admin-default-icon-color: #2e7d32;
$es-theme-controls-button-admin-hover-bg-color: #fff;
$es-theme-controls-button-admin-hover-text-color: #000;
$es-theme-controls-button-admin-hover-icon-color: #2e7d32;
$es-theme-controls-button-admin-active-bg-color: #d5d6d8;
$es-theme-controls-button-admin-active-text-color: #000;
$es-theme-controls-button-admin-active-icon-color: #2e7d32;
$es-theme-controls-button-admin-disabled-bg-color: #f7f7f7;
$es-theme-controls-button-admin-disabled-text-color: #c1c2c3;
$es-theme-controls-button-admin-disabled-icon-color: #2e7d324d;
$es-theme-controls-button-up-bar-menu-m-typography-font-family: Open Sans;
$es-theme-controls-button-up-bar-menu-m-typography-font-weight: Regular;
$es-theme-controls-button-up-bar-menu-m-typography-line-height: 170%;
$es-theme-controls-button-up-bar-menu-m-typography-font-size: 12;
$es-theme-controls-button-up-bar-menu-m-typography-letter-spacing: 0;
$es-theme-controls-button-up-bar-menu-m-typography-paragraph-spacing: none;
$es-theme-controls-button-up-bar-menu-m-typography-text-case: none;
$es-theme-controls-button-up-bar-menu-m-typography-text-decoration: none;
$es-theme-controls-button-up-bar-menu-default-text-color: #1a1c1e;
$es-theme-controls-button-up-bar-menu-default-icon-color: #828282;
$es-theme-controls-button-m-border-radius: 4;
$es-theme-controls-button-height-xl-sizing: 4 * 16;
$es-theme-controls-button-height-l-sizing: 4 * 12;
$es-theme-controls-button-height-m-sizing: 4 * 10;
$es-theme-controls-button-height-s-sizing: 4 * 7;
$es-theme-controls-button-height-xs-sizing: 4 * 6;
$es-theme-controls-button-height-l-spacing: 4 * 5;
$es-theme-controls-button-height-m-spacing: 4 * 2;
$es-theme-controls-button-height-s-spacing: 4;
$es-theme-controls-button-width-xl-sizing: 4 * 16;
$es-theme-controls-button-width-l-sizing: 4 * 12;
$es-theme-controls-button-width-m-sizing: 4 * 10;
$es-theme-controls-button-width-s-sizing: 4 * 7;
$es-theme-controls-button-width-xs-sizing: 4 * 6;
$es-theme-controls-button-width-l-spacing: 4 * 7;
$es-theme-controls-button-width-m-spacing: 4 * 4;
$es-theme-controls-button-width-s-spacing: 4 * 2;
$es-theme-controls-button-rounded-l-border-radius: 4 * 8;
$es-theme-controls-button-rounded-m-border-radius: 4 * 6;
$es-theme-controls-button-rounded-s-border-radius: 4 * 4;
$es-theme-controls-button-gap-m-spacing: 4 * 2;
$es-theme-controls-button-gap-s-spacing: 4;
$es-theme-controls-button-hover-box-shadow-x: 0;
$es-theme-controls-button-hover-box-shadow-y: 4;
$es-theme-controls-button-hover-box-shadow-blur: 8;
$es-theme-controls-button-hover-box-shadow-spread: 0;
$es-theme-controls-button-hover-box-shadow-color: #0000001a;
$es-theme-controls-button-hover-box-shadow-type: dropShadow;
$es-theme-controls-button-default-box-shadow-x: 0;
$es-theme-controls-button-default-box-shadow-y: 1;
$es-theme-controls-button-default-box-shadow-blur: 2;
$es-theme-controls-button-default-box-shadow-spread: 0;
$es-theme-controls-button-default-box-shadow-color: #00000040;
$es-theme-controls-button-default-box-shadow-type: dropShadow;
$es-theme-controls-card-m-border-radius: 4 * 2;
$es-theme-controls-card-height-xs-spacing: 4;
$es-theme-controls-card-height-s-spacing: 4 * 2;
$es-theme-controls-card-height-m-spacing: 4 * 3;
$es-theme-controls-card-height-l-spacing: 4 * 6;
$es-theme-controls-card-height-xl-spacing: 4 * 10;
$es-theme-controls-card-width-s-spacing: 4 * 2;
$es-theme-controls-card-width-m-spacing: 4 * 3;
$es-theme-controls-card-width-l-spacing: 4 * 4;
$es-theme-controls-card-width-xl-spacing: 4 * 5;
$es-theme-controls-card-width-xxl-spacing: 4 * 6;
$es-theme-controls-card-width-xxxl-spacing: 4 * 7;
$es-theme-controls-card-width-xxxxl-spacing: 4 * 8;
$es-theme-controls-card-gap-xs-spacing: 4;
$es-theme-controls-card-gap-s-spacing: 4 * 2;
$es-theme-controls-card-gap-m-spacing: 4 * 3;
$es-theme-controls-card-gap-l-spacing: 4 * 5;
$es-theme-controls-card-unselected-bg-primary-color: #eff0f0;
$es-theme-controls-card-unselected-bg-secondary-color: #fff;
$es-theme-controls-card-unselected-bg-tertiary-color: #fff;
$es-theme-controls-card-unselected-text-primary-color: #292b2d;
$es-theme-controls-card-unselected-text-secondary-color: #292b2d;
$es-theme-controls-card-unselected-text-tertiary-color: #828282;
$es-theme-controls-card-unselected-divider-pimary-color: #f7f7f7;
$es-theme-controls-card-unselected-divider-secondary-color: #d5d6d8;
$es-theme-controls-card-unselected-icon-primary-color: #2e7d32;
$es-theme-controls-card-unselected-icon-secondary-color: #4b6f98;
$es-theme-controls-card-unselected-icon-tertiary-color: #121416;
$es-theme-controls-card-selected-bg-primary-color: #d5d6d8;
$es-theme-controls-card-selected-bg-secondary-color: #2e7d3233;
$es-theme-controls-card-selected-bg-tertiary-color: #2e7d32;
$es-theme-controls-card-selected-text-primary-color: #fff;
$es-theme-controls-card-selected-text-secondary-color: #292b2d;
$es-theme-controls-card-selected-text-tertiary-color: #292b2d;
$es-theme-controls-card-selected-divider-primary-color: #f7f7f7;
$es-theme-controls-card-selected-divider-secondary-color: #2e7d32;
$es-theme-controls-card-selected-icon-primary-color: #2e7d32;
$es-theme-controls-card-selected-icon-secondary-color: #2e7d32;
$es-theme-controls-card-selected-icon-tertiary-color: #fff;
$es-theme-controls-card-hover-bg-primary-color: #e5e5e6;
$es-theme-controls-card-hover-bg-secondary-color: #e5e5e6;
$es-theme-controls-card-hover-bg-tertiary-color: #fff;
$es-theme-controls-card-hover-text-primary-color: #292b2d;
$es-theme-controls-card-hover-text-secondary-color: #292b2d;
$es-theme-controls-card-hover-text-tertiary-color: #828282;
$es-theme-controls-card-hover-divider-primary-color: #f7f7f7;
$es-theme-controls-card-hover-divider-secondary-color: #d5d6d8;
$es-theme-controls-card-hover-icon-primary-color: #2e7d32;
$es-theme-controls-card-hover-icon-secondary-color: #4b6f98;
$es-theme-controls-card-hover-icon-tertiary-color: #121416;
$es-theme-controls-card-disabled-bg-primary-color: #f7f7f7;
$es-theme-controls-card-disabled-bg-secondary-color: #e5e5e6;
$es-theme-controls-card-disabled-bg-tertiary-color: #eff0f0;
$es-theme-controls-card-disabled-text-primary-color: #828282;
$es-theme-controls-card-disabled-text-secondary-color: #828282;
$es-theme-controls-card-disabled-text-tertiary-color: #828282;
$es-theme-controls-card-disabled-divider-primary-color: #f7f7f7;
$es-theme-controls-card-disabled-divider-secondary-color: #d5d6d8;
$es-theme-controls-card-disabled-icon-primary-color: #4b6f98;
$es-theme-controls-card-disabled-icon-secondary-color: #4b6f98;
$es-theme-controls-card-disabled-icon-tertiary-color: #4b6f98;
$es-theme-controls-input-filled-label-m-typography-font-family: Open Sans;
$es-theme-controls-input-filled-label-m-typography-font-weight: Regular;
$es-theme-controls-input-filled-label-m-typography-line-height: 100%;
$es-theme-controls-input-filled-label-m-typography-font-size: 12;
$es-theme-controls-input-filled-label-m-typography-letter-spacing: 0;
$es-theme-controls-input-filled-body-m-typography-font-family: Open Sans;
$es-theme-controls-input-filled-body-m-typography-font-weight: Regular;
$es-theme-controls-input-filled-body-m-typography-line-height: 100%;
$es-theme-controls-input-filled-body-m-typography-font-size: 16;
$es-theme-controls-input-filled-body-m-typography-letter-spacing: 0;
$es-theme-controls-input-filled-helper-m-typography-font-family: Open Sans;
$es-theme-controls-input-filled-helper-m-typography-font-weight: Regular;
$es-theme-controls-input-filled-helper-m-typography-line-height: 100%;
$es-theme-controls-input-filled-helper-m-typography-font-size: 12;
$es-theme-controls-input-filled-helper-m-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-label-m-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-label-m-typography-font-weight: Regular;
$es-theme-controls-input-outlined-label-m-typography-line-height: 100%;
$es-theme-controls-input-outlined-label-m-typography-font-size: 14;
$es-theme-controls-input-outlined-label-m-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-label-s-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-label-s-typography-font-weight: Regular;
$es-theme-controls-input-outlined-label-s-typography-line-height: 100%;
$es-theme-controls-input-outlined-label-s-typography-font-size: 12;
$es-theme-controls-input-outlined-label-s-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-label-l-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-label-l-typography-font-weight: Regular;
$es-theme-controls-input-outlined-label-l-typography-line-height: 100%;
$es-theme-controls-input-outlined-label-l-typography-font-size: 16;
$es-theme-controls-input-outlined-label-l-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-body-m-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-body-m-typography-font-weight: Regular;
$es-theme-controls-input-outlined-body-m-typography-line-height: 100%;
$es-theme-controls-input-outlined-body-m-typography-font-size: 16;
$es-theme-controls-input-outlined-body-m-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-body-s-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-body-s-typography-font-weight: Regular;
$es-theme-controls-input-outlined-body-s-typography-line-height: 100%;
$es-theme-controls-input-outlined-body-s-typography-font-size: 12;
$es-theme-controls-input-outlined-body-s-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-body-l-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-body-l-typography-font-weight: Regular;
$es-theme-controls-input-outlined-body-l-typography-line-height: 100%;
$es-theme-controls-input-outlined-body-l-typography-font-size: 20;
$es-theme-controls-input-outlined-body-l-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-helper-m-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-helper-m-typography-font-weight: Regular;
$es-theme-controls-input-outlined-helper-m-typography-line-height: 100%;
$es-theme-controls-input-outlined-helper-m-typography-font-size: 12;
$es-theme-controls-input-outlined-helper-m-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-helper-s-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-helper-s-typography-font-weight: Regular;
$es-theme-controls-input-outlined-helper-s-typography-line-height: 100%;
$es-theme-controls-input-outlined-helper-s-typography-font-size: 12;
$es-theme-controls-input-outlined-helper-s-typography-letter-spacing: 0;
$es-theme-controls-input-outlined-helper-l-typography-font-family: Open Sans;
$es-theme-controls-input-outlined-helper-l-typography-font-weight: Regular;
$es-theme-controls-input-outlined-helper-l-typography-line-height: 100%;
$es-theme-controls-input-outlined-helper-l-typography-font-size: 12;
$es-theme-controls-input-outlined-helper-l-typography-letter-spacing: 0;
$es-theme-controls-input-xs-border-radius: 2;
$es-theme-controls-input-s-border-radius: 4;
$es-theme-controls-input-m-border-radius: 4 * 6;
$es-theme-controls-input-l-border-radius: 4 * 10;
$es-theme-controls-input-xl-border-radius: 4 * 7;
$es-theme-controls-input-body-outlined-height-l-sizing: 4 * 10;
$es-theme-controls-input-body-outlined-height-xl-sizing: 4 * 16;
$es-theme-controls-input-body-outlined-height-m-sizing: 4 * 8;
$es-theme-controls-input-body-outlined-height-s-sizing: 4 * 7;
$es-theme-controls-input-body-outlined-height-xs-sizing: 4 * 5;
$es-theme-controls-input-body-outlined-height-xxs-sizing: 4 * 4;
$es-theme-controls-input-body-outlined-width-l-sizing: 4 * 14;
$es-theme-controls-input-body-outlined-width-m-sizing: 4 * 10;
$es-theme-controls-input-body-outlined-width-s-sizing: 4 * 6;
$es-theme-controls-input-line-height-m-sizing: 2;
$es-theme-controls-input-line-height-s-sizing: 1;
$es-theme-controls-input-line-width-m-sizing: 2;
$es-theme-controls-input-line-width-s-sizing: 1;
$es-theme-controls-input-width-xl-spacing: 4 * 6;
$es-theme-controls-input-width-l-spacing: 4 * 4;
$es-theme-controls-input-width-m-spacing: 4 * 3;
$es-theme-controls-input-width-s-spacing: 4 * 2;
$es-theme-controls-input-width-xs-spacing: 4;
$es-theme-controls-input-gap-m-spacing: 4 * 2;
$es-theme-controls-input-gap-s-spacing: 4;
$es-theme-controls-input-height-xl-spacing: 4 * 5;
$es-theme-controls-input-height-l-spacing: 4 * 4;
$es-theme-controls-input-height-m-spacing: 4 * 3;
$es-theme-controls-input-height-s-spacing: 4 * 2;
$es-theme-controls-input-height-xs-spacing: 4;
$es-theme-controls-input-hover-box-shadow-x: 0;
$es-theme-controls-input-hover-box-shadow-y: 4;
$es-theme-controls-input-hover-box-shadow-blur: 8;
$es-theme-controls-input-hover-box-shadow-spread: 0;
$es-theme-controls-input-hover-box-shadow-color: #0000001a;
$es-theme-controls-input-hover-box-shadow-type: dropShadow;
$es-theme-controls-dropdown-menu-label-m-typography-font-family: Open Sans;
$es-theme-controls-dropdown-menu-label-m-typography-font-weight: Medium;
$es-theme-controls-dropdown-menu-label-m-typography-line-height: 100%;
$es-theme-controls-dropdown-menu-label-m-typography-font-size: 18;
$es-theme-controls-dropdown-menu-label-m-typography-letter-spacing: 0;
$es-theme-controls-dropdown-menu-body-m-typography-font-family: Open Sans;
$es-theme-controls-dropdown-menu-body-m-typography-font-weight: Regular;
$es-theme-controls-dropdown-menu-body-m-typography-line-height: 100%;
$es-theme-controls-dropdown-menu-body-m-typography-font-size: 16;
$es-theme-controls-dropdown-menu-body-m-typography-letter-spacing: 0;
$es-theme-controls-dropdown-menu-body-s-typography-font-family: Open Sans;
$es-theme-controls-dropdown-menu-body-s-typography-font-weight: Regular;
$es-theme-controls-dropdown-menu-body-s-typography-line-height: 100%;
$es-theme-controls-dropdown-menu-body-s-typography-font-size: 14;
$es-theme-controls-dropdown-menu-body-s-typography-letter-spacing: 0;
$es-theme-controls-dropdown-menu-trailing-m-typography-font-family: Open Sans;
$es-theme-controls-dropdown-menu-trailing-m-typography-font-weight: Regular;
$es-theme-controls-dropdown-menu-trailing-m-typography-line-height: 100%;
$es-theme-controls-dropdown-menu-trailing-m-typography-font-size: 12;
$es-theme-controls-dropdown-menu-trailing-m-typography-letter-spacing: 0;
$es-theme-controls-dropdown-menu-m-border-radius: 4;
$es-theme-controls-dropdown-menu-unselected-bg-primary-color: #eff0f0;
$es-theme-controls-dropdown-menu-unselected-bg-secondary-color: #e5e5e6;
$es-theme-controls-dropdown-menu-unselected-bg-tertiary-color: #fff;
$es-theme-controls-dropdown-menu-unselected-text-label-color: #292b2d;
$es-theme-controls-dropdown-menu-unselected-text-body-color: #292b2d;
$es-theme-controls-dropdown-menu-unselected-text-trailing-color: #828282;
$es-theme-controls-dropdown-menu-unselected-divider-pimary-color: #f7f7f7;
$es-theme-controls-dropdown-menu-unselected-divider-secondary-color: #d5d6d8;
$es-theme-controls-dropdown-menu-unselected-icon-primary-color: #2e7d32;
$es-theme-controls-dropdown-menu-unselected-icon-secondary-color: #4b6f98;
$es-theme-controls-dropdown-menu-selected-bg-primary-color: #d5d6d8;
$es-theme-controls-dropdown-menu-selected-bg-secondary-color: #e5e5e6;
$es-theme-controls-dropdown-menu-selected-bg-tertiary-color: #2e7d3266;
$es-theme-controls-dropdown-menu-selected-text-label-color: #292b2d;
$es-theme-controls-dropdown-menu-selected-text-body-color: #292b2d;
$es-theme-controls-dropdown-menu-selected-text-trailing-color: #292b2d;
$es-theme-controls-dropdown-menu-selected-divider-primary-color: #f7f7f7;
$es-theme-controls-dropdown-menu-selected-divider-secondary-color: #d5d6d8;
$es-theme-controls-dropdown-menu-selected-icon-primary-color: #2e7d32;
$es-theme-controls-dropdown-menu-selected-icon-secondary-color: #2e7d32;
$es-theme-controls-dropdown-menu-hover-bg-primary-color: #e5e5e6;
$es-theme-controls-dropdown-menu-hover-bg-secondary-color: #e5e5e6;
$es-theme-controls-dropdown-menu-hover-bg-tertiary-color: #e5e5e6;
$es-theme-controls-dropdown-menu-hover-text-label-color: #292b2d;
$es-theme-controls-dropdown-menu-hover-text-body-color: #292b2d;
$es-theme-controls-dropdown-menu-hover-text-trailing-color: #828282;
$es-theme-controls-dropdown-menu-hover-divider-primary-color: #f7f7f7;
$es-theme-controls-dropdown-menu-hover-divider-secondary-color: #d5d6d8;
$es-theme-controls-dropdown-menu-hover-icon-primary-color: #2e7d32;
$es-theme-controls-dropdown-menu-hover-icon-secondary-color: #4b6f98;
$es-theme-controls-dropdown-menu-disabled-bg-primary-color: #f7f7f7;
$es-theme-controls-dropdown-menu-disabled-bg-secondary-color: #e5e5e6;
$es-theme-controls-dropdown-menu-disabled-bg-tertiary-color: #eff0f0;
$es-theme-controls-dropdown-menu-disabled-text-label-color: #828282;
$es-theme-controls-dropdown-menu-disabled-text-body-color: #828282;
$es-theme-controls-dropdown-menu-disabled-text-trailing-color: #828282;
$es-theme-controls-dropdown-menu-disabled-divider-primary-color: #f7f7f7;
$es-theme-controls-dropdown-menu-disabled-divider-secondary-color: #d5d6d8;
$es-theme-controls-dropdown-menu-disabled-icon-primary-color: #4b6f98;
$es-theme-controls-dropdown-menu-disabled-icon-secondary-color: #4b6f98;
$es-theme-controls-dropdown-menu-height-s-spacing: 4 * 2;
$es-theme-controls-dropdown-menu-height-m-spacing: 4 * 3;
$es-theme-controls-dropdown-menu-height-l-spacing: 4 * 6;
$es-theme-controls-dropdown-menu-height-xl-spacing: 4 * 10;
$es-theme-controls-dropdown-menu-height-xl-sizing: 4 * 16;
$es-theme-controls-dropdown-menu-height-l-sizing: 4 * 13;
$es-theme-controls-dropdown-menu-height-m-sizing: 4 * 10;
$es-theme-controls-dropdown-menu-height-s-sizing: 4 * 7;
$es-theme-controls-dropdown-menu-height-xs-sizing: 4 * 5;
$es-theme-controls-dropdown-menu-height-xxs-sizing: 4 * 4;
$es-theme-controls-dropdown-menu-width-s-spacing: 4 * 2;
$es-theme-controls-dropdown-menu-width-m-spacing: 4 * 3;
$es-theme-controls-dropdown-menu-width-l-spacing: 4 * 4;
$es-theme-controls-dropdown-menu-width-xl-spacing: 4 * 5;
$es-theme-controls-dropdown-menu-width-xxl-spacing: 4 * 6;
$es-theme-controls-dropdown-menu-width-xxxl-spacing: 4 * 7;
$es-theme-controls-dropdown-menu-width-xxxxl-spacing: 4 * 8;
$es-theme-controls-dropdown-menu-width-l-sizing: 4 * 16;
$es-theme-controls-dropdown-menu-width-m-sizing: 4 * 12;
$es-theme-controls-dropdown-menu-width-s-sizing: 4 * 7;
$es-theme-controls-dropdown-menu-gap-xs-spacing: 4;
$es-theme-controls-dropdown-menu-gap-s-spacing: 4 * 2;
$es-theme-controls-dropdown-menu-gap-m-spacing: 4 * 3;
$es-theme-controls-dropdown-menu-gap-l-spacing: 4 * 5;
$es-theme-controls-expansion-panels-title-l-typography-font-family: Open Sans;
$es-theme-controls-expansion-panels-title-l-typography-font-weight: Medium;
$es-theme-controls-expansion-panels-title-l-typography-line-height: 100%;
$es-theme-controls-expansion-panels-title-l-typography-font-size: 24;
$es-theme-controls-expansion-panels-title-l-typography-letter-spacing: 0;
$es-theme-controls-expansion-panels-title-m-typography-font-family: Open Sans;
$es-theme-controls-expansion-panels-title-m-typography-font-weight: Regular;
$es-theme-controls-expansion-panels-title-m-typography-line-height: 100%;
$es-theme-controls-expansion-panels-title-m-typography-font-size: 14;
$es-theme-controls-expansion-panels-title-m-typography-letter-spacing: 0;
$es-theme-controls-expansion-panels-title-s-typography-font-family: Open Sans;
$es-theme-controls-expansion-panels-title-s-typography-font-weight: Regular;
$es-theme-controls-expansion-panels-title-s-typography-line-height: 100%;
$es-theme-controls-expansion-panels-title-s-typography-font-size: 14;
$es-theme-controls-expansion-panels-title-s-typography-letter-spacing: 0;
$es-theme-controls-expansion-panels-description-l-typography-font-family: Open
  Sans;
$es-theme-controls-expansion-panels-description-l-typography-font-weight: Regular;
$es-theme-controls-expansion-panels-description-l-typography-line-height: 100%;
$es-theme-controls-expansion-panels-description-l-typography-font-size: 15;
$es-theme-controls-expansion-panels-description-l-typography-letter-spacing: 0;
$es-theme-controls-expansion-panels-description-m-typography-font-family: Open
  Sans;
$es-theme-controls-expansion-panels-description-m-typography-font-weight: Regular;
$es-theme-controls-expansion-panels-description-m-typography-line-height: 100%;
$es-theme-controls-expansion-panels-description-m-typography-font-size: 12;
$es-theme-controls-expansion-panels-description-m-typography-letter-spacing: 0;
$es-theme-controls-expansion-panels-description-s-typography-font-family: Open
  Sans;
$es-theme-controls-expansion-panels-description-s-typography-font-weight: Regular;
$es-theme-controls-expansion-panels-description-s-typography-line-height: 100%;
$es-theme-controls-expansion-panels-description-s-typography-font-size: 10;
$es-theme-controls-expansion-panels-description-s-typography-letter-spacing: 0;
$es-theme-controls-expansion-panels-default-bg-color: #fff;
$es-theme-controls-expansion-panels-default-text-title-color: #000;
$es-theme-controls-expansion-panels-default-text-description-color: #828282;
$es-theme-controls-expansion-panels-default-line-color: #e5e5e6;
$es-theme-controls-expansion-panels-default-icon-primary-color: #2e7d32;
$es-theme-controls-expansion-panels-default-icon-secondary-color: #000;
$es-theme-controls-expansion-panels-hover-bg-color: #eff0f0;
$es-theme-controls-expansion-panels-hover-text-title-color: #000;
$es-theme-controls-expansion-panels-hover-text-description-color: #828282;
$es-theme-controls-expansion-panels-hover-line-color: #e5e5e6;
$es-theme-controls-expansion-panels-hover-icon-primary-color: #2e7d32;
$es-theme-controls-expansion-panels-hover-icon-secondary-color: #000;
$es-theme-controls-expansion-panels-focused-bg-color: #e5e5e6;
$es-theme-controls-expansion-panels-focused-text-title-color: #000;
$es-theme-controls-expansion-panels-focused-text-description-color: #828282;
$es-theme-controls-expansion-panels-focused-line-color: #e5e5e6;
$es-theme-controls-expansion-panels-focused-icon-primary-color: #2e7d32;
$es-theme-controls-expansion-panels-focused-icon-secondary-color: #000;
$es-theme-controls-expansion-panels-active-opened-bg-color: #2e7d321a;
$es-theme-controls-expansion-panels-active-opened-text-title-color: #2e7d32;
$es-theme-controls-expansion-panels-active-opened-text-description-color: #2e7d32;
$es-theme-controls-expansion-panels-active-opened-line-color: #e5e5e6;
$es-theme-controls-expansion-panels-active-opened-icon-primary-color: #2e7d32;
$es-theme-controls-expansion-panels-active-opened-icon-secondary-color: #000;
$es-theme-controls-expansion-panels-disabled-bg-color: #f7f7f7;
$es-theme-controls-expansion-panels-disabled-text-title-color: #c1c2c3;
$es-theme-controls-expansion-panels-disabled-text-description-color: #c1c2c3;
$es-theme-controls-expansion-panels-disabled-line-color: #e5e5e6;
$es-theme-controls-expansion-panels-disabled-icon-primary-color: #4b6f98;
$es-theme-controls-expansion-panels-disabled-icon-secondary-color: #4b6f98;
$es-theme-controls-expansion-panels-m-box-shadow-x: 0;
$es-theme-controls-expansion-panels-m-box-shadow-y: 1;
$es-theme-controls-expansion-panels-m-box-shadow-blur: 2;
$es-theme-controls-expansion-panels-m-box-shadow-spread: 0;
$es-theme-controls-expansion-panels-m-box-shadow-color: #00000040;
$es-theme-controls-expansion-panels-m-box-shadow-type: dropShadow;
$es-theme-controls-chips-m-typography-font-family: Open Sans;
$es-theme-controls-chips-m-typography-font-weight: SemiBold;
$es-theme-controls-chips-m-typography-line-height: 100%;
$es-theme-controls-chips-m-typography-font-size: 14;
$es-theme-controls-chips-m-typography-letter-spacing: 0;
$es-theme-controls-chips-s-typography-font-family: Open Sans;
$es-theme-controls-chips-s-typography-font-weight: SemiBold;
$es-theme-controls-chips-s-typography-line-height: 100%;
$es-theme-controls-chips-s-typography-font-size: 12;
$es-theme-controls-chips-s-typography-letter-spacing: 0;
$es-theme-controls-chips-enabled-bg-primary-color: #eff0f0;
$es-theme-controls-chips-enabled-bg-secondary-color: #d5d6d8;
$es-theme-controls-chips-enabled-text-title-color: #292b2d;
$es-theme-controls-chips-enabled-border-color: #e5e5e6;
$es-theme-controls-chips-enabled-icon-primary-color: #2e7d32;
$es-theme-controls-chips-enabled-icon-secondary-color: #000;
$es-theme-controls-chips-hover-bg-primary-color: #e5e5e6;
$es-theme-controls-chips-hover-bg-secondary-color: #d5d6d8;
$es-theme-controls-chips-hover-text-title-color: #292b2d;
$es-theme-controls-chips-hover-border-color: #e5e5e6;
$es-theme-controls-chips-hover-icon-primary-color: #2e7d32;
$es-theme-controls-chips-hover-icon-secondary-color: #000;
$es-theme-controls-chips-focused-bg-primary-color: #e5e5e6;
$es-theme-controls-chips-focused-bg-secondary-color: #d5d6d8;
$es-theme-controls-chips-focused-text-title-color: #292b2d;
$es-theme-controls-chips-focused-border-color: #f28933;
$es-theme-controls-chips-focused-icon-primary-color: #2e7d32;
$es-theme-controls-chips-focused-icon-secondary-color: #000;
$es-theme-controls-chips-pressed-bg-primary-color: #d5d6d8;
$es-theme-controls-chips-pressed-bg-secondary-color: #d5d6d8;
$es-theme-controls-chips-pressed-text-title-color: #292b2d;
$es-theme-controls-chips-pressed-border-color: #e5e5e6;
$es-theme-controls-chips-pressed-icon-primary-color: #2e7d32;
$es-theme-controls-chips-pressed-icon-secondary-color: #000;
$es-theme-controls-chips-dragged-bg-primary-color: #eff0f0;
$es-theme-controls-chips-dragged-bg-secondary-color: #d5d6d8;
$es-theme-controls-chips-dragged-text-title-color: #292b2d;
$es-theme-controls-chips-dragged-border-color: #c1c2c3;
$es-theme-controls-chips-dragged-icon-primary-color: #2e7d32;
$es-theme-controls-chips-dragged-icon-secondary-color: #000;
$es-theme-controls-chips-disabled-bg-primary-color: #ebebeb;
$es-theme-controls-chips-disabled-bg-secondary-color: #ebebeb;
$es-theme-controls-chips-disabled-text-title-color: #c1c2c3;
$es-theme-controls-chips-disabled-border-color: #e5e5e6;
$es-theme-controls-chips-disabled-icon-primary-color: #2e7d324d;
$es-theme-controls-chips-disabled-icon-secondary-color: #c1c2c3;
$es-theme-controls-status-m-typography-font-family: Open Sans;
$es-theme-controls-status-m-typography-font-weight: SemiBold;
$es-theme-controls-status-m-typography-line-height: 100%;
$es-theme-controls-status-m-typography-font-size: 10;
$es-theme-controls-status-m-typography-letter-spacing: 0;
$es-theme-controls-status-l-typography-font-family: Open Sans;
$es-theme-controls-status-l-typography-font-weight: SemiBold;
$es-theme-controls-status-l-typography-line-height: 100%;
$es-theme-controls-status-l-typography-font-size: 14;
$es-theme-controls-status-l-typography-letter-spacing: 0;
$es-theme-controls-status-gap-s-spacing: 4 * 2;
$es-theme-controls-status-gap-xs-spacing: 4;
$es-theme-controls-pagination-s-typography-font-family: Open Sans;
$es-theme-controls-pagination-s-typography-font-weight: Regular;
$es-theme-controls-pagination-s-typography-line-height: 100%;
$es-theme-controls-pagination-s-typography-font-size: 12;
$es-theme-controls-pagination-s-typography-letter-spacing: 0;
$es-theme-controls-pagination-m-typography-font-family: Open Sans;
$es-theme-controls-pagination-m-typography-font-weight: Regular;
$es-theme-controls-pagination-m-typography-line-height: 100%;
$es-theme-controls-pagination-m-typography-font-size: 14;
$es-theme-controls-pagination-m-typography-letter-spacing: 0;
$es-theme-controls-pagination-l-typography-font-family: Open Sans;
$es-theme-controls-pagination-l-typography-font-weight: Regular;
$es-theme-controls-pagination-l-typography-line-height: 100%;
$es-theme-controls-pagination-l-typography-font-size: 20;
$es-theme-controls-pagination-l-typography-letter-spacing: 0;
$es-theme-controls-pagination-height-s-sizing: 4 * 7;
$es-theme-controls-pagination-height-m-sizing: 4 * 10;
$es-theme-controls-pagination-height-l-sizing: 4 * 16;
$es-theme-controls-pagination-width-s-sizing: 4 * 7;
$es-theme-controls-pagination-width-m-sizing: 4 * 10;
$es-theme-controls-pagination-width-l-sizing: 4 * 16;
$es-theme-controls-pagination-m-border-radius: 4;
$es-theme-controls-pagination-default-icon-color: #2e7d32;
$es-theme-controls-pagination-default-text-color: #1a1c1e;
$es-theme-controls-pagination-default-bg-primary-color: #f7f7f7;
$es-theme-controls-pagination-default-bg-secondary-color: #ebebeb;
$es-theme-controls-pagination-pressed-icon-color: #fff;
$es-theme-controls-pagination-pressed-text-color: #fff;
$es-theme-controls-pagination-pressed-bg-primary-color: #828282;
$es-theme-controls-pagination-pressed-bg-secondary-color: #828282;
$es-theme-controls-pagination-hover-icon-color: #fff;
$es-theme-controls-pagination-hover-text-color: #fff;
$es-theme-controls-pagination-hover-bg-primary-color: #2e7d32;
$es-theme-controls-pagination-hover-bg-secondary-color: #2e7d32;
$es-theme-controls-pagination-disabled-icon-color: #bdbdbd;
$es-theme-controls-pagination-disabled-text-color: #bdbdbd;
$es-theme-controls-pagination-disabled-bg-primary-color: #eff0f0;
$es-theme-controls-pagination-disabled-bg-secondary-color: #eff0f0;
$es-theme-controls-menu-m-typography-font-family: Open Sans;
$es-theme-controls-menu-m-typography-font-weight: SemiBold;
$es-theme-controls-menu-m-typography-line-height: 100%;
$es-theme-controls-menu-m-typography-font-size: 20;
$es-theme-controls-menu-m-typography-letter-spacing: 0;
$es-theme-controls-menu-xxxxs-typography-font-family: Open Sans;
$es-theme-controls-menu-xxxxs-typography-font-weight: SemiBold;
$es-theme-controls-menu-xxxxs-typography-line-height: 100%;
$es-theme-controls-menu-xxxxs-typography-font-size: 12;
$es-theme-controls-menu-xxxxs-typography-letter-spacing: 0;
$es-theme-controls-menu-header-m-typography-font-family: Open Sans;
$es-theme-controls-menu-header-m-typography-font-weight: Regular;
$es-theme-controls-menu-header-m-typography-line-height: 100%;
$es-theme-controls-menu-header-m-typography-font-size: 16;
$es-theme-controls-menu-header-m-typography-letter-spacing: 0;
$es-theme-controls-menu-header-s-typography-font-family: Open Sans;
$es-theme-controls-menu-header-s-typography-font-weight: Regular;
$es-theme-controls-menu-header-s-typography-line-height: 100%;
$es-theme-controls-menu-header-s-typography-font-size: 13;
$es-theme-controls-menu-header-s-typography-letter-spacing: 0;
$es-theme-controls-menu-default-bg-tertiary-color: #2e7d3266;
$es-theme-controls-menu-default-bg-primary-color: #fff;
$es-theme-controls-menu-default-bg-secondary-color: #f7f7f7;
$es-theme-controls-menu-default-text-primary-color: #000;
$es-theme-controls-menu-default-text-secondary-color: #2e7d32;
$es-theme-controls-menu-default-text-tertiary-color: #2e7d32;
$es-theme-controls-menu-default-line-color: #e5e5e6;
$es-theme-controls-menu-default-icon-primary-color: #2e7d32;
$es-theme-controls-menu-default-icon-secondary-color: #828282;
$es-theme-controls-menu-hover-bg-tertiary-color: #2e7d3266;
$es-theme-controls-menu-hover-bg-primary-color: #2e7d3233;
$es-theme-controls-menu-hover-bg-secondary-color: #ebebeb;
$es-theme-controls-menu-hover-text-primary-color: #000;
$es-theme-controls-menu-hover-text-secondary-color: #2e7d32;
$es-theme-controls-menu-hover-text-tertiary-color: #2e7d32;
$es-theme-controls-menu-hover-line-color: #e5e5e6;
$es-theme-controls-menu-hover-icon-primary-color: #2e7d32;
$es-theme-controls-menu-hover-icon-secondary-color: #000;
$es-theme-controls-menu-focused-bg-tertiary-color: #2e7d3266;
$es-theme-controls-menu-focused-bg-primary-color: #2e7d3233;
$es-theme-controls-menu-focused-bg-secondary-color: #464749;
$es-theme-controls-menu-focused-text-primary-color: #000;
$es-theme-controls-menu-focused-text-secondary-color: #2e7d32;
$es-theme-controls-menu-focused-text-tertiary-color: #2e7d32;
$es-theme-controls-menu-focused-line-color: #e5e5e6;
$es-theme-controls-menu-focused-icon-primary-color: #2e7d32;
$es-theme-controls-menu-focused-icon-secondary-color: #000;
$es-theme-controls-menu-active-opened-bg-tertiary-color: #2e7d3233;
$es-theme-controls-menu-active-opened-bg-primary-color: #d5d6d8;
$es-theme-controls-menu-active-opened-bg-secondary-color: #d5d6d8;
$es-theme-controls-menu-active-opened-text-primary-color: #000;
$es-theme-controls-menu-active-opened-text-secondary-color: #2e7d32;
$es-theme-controls-menu-active-opened-text-tertiary-color: #2e7d32;
$es-theme-controls-menu-active-opened-line-color: #e5e5e6;
$es-theme-controls-menu-active-opened-icon-primary-color: #2e7d32;
$es-theme-controls-menu-active-opened-icon-secondary-color: #000;
$es-theme-controls-menu-disabled-bg-primary-color: #2e7d3266;
$es-theme-controls-menu-disabled-bg-secondary-color: #f7f7f7;
$es-theme-controls-menu-disabled-bg-tertiary-color: #f7f7f7;
$es-theme-controls-menu-disabled-text-primary-color: #d5d6d8;
$es-theme-controls-menu-disabled-text-secondary-color: #d5d6d8;
$es-theme-controls-menu-disabled-text-tertiary-color: #d5d6d8;
$es-theme-controls-menu-disabled-line-color: #e5e5e6;
$es-theme-controls-menu-disabled-icon-primary-color: #2e7d324d;
$es-theme-controls-menu-disabled-icon-secondary-color: #ffffff99;
$es-theme-controls-menu-height-m-sizing: 4 * 7;
$es-theme-controls-menu-height-xl-sizing: 4 * 12;
$es-theme-controls-menu-height-m-spacing: 4 * 3;
$es-theme-controls-menu-height-l-spacing: 4 * 4;
$es-theme-controls-menu-height-xl-spacing: 4 * 5;
$es-theme-controls-menu-height-s-spacing: 4 * 2;
$es-theme-controls-menu-width-m-sizing: 4 * 7;
$es-theme-controls-menu-width-m-spacing: 4 * 3;
$es-theme-controls-menu-width-l-spacing: 4 * 4;
$es-theme-controls-menu-width-xl-spacing: 4 * 5;
$es-theme-controls-menu-width-s-spacing: 4 * 2;
$es-theme-controls-menu-m-border-radius: 4;
$es-theme-controls-menu-gap-m-spacing: 4 * 2;
$es-theme-controls-menu-gap-l-spacing: 4 * 3;
$es-theme-controls-menu-gap-s-spacing: 4;
$es-theme-controls-data-table-header-l-typography-font-family: Open Sans;
$es-theme-controls-data-table-header-l-typography-font-weight: Medium;
$es-theme-controls-data-table-header-l-typography-line-height: 100%;
$es-theme-controls-data-table-header-l-typography-font-size: 14;
$es-theme-controls-data-table-header-l-typography-letter-spacing: 0;
$es-theme-controls-data-table-header-m-typography-font-family: Open Sans;
$es-theme-controls-data-table-header-m-typography-font-weight: Medium;
$es-theme-controls-data-table-header-m-typography-line-height: 100%;
$es-theme-controls-data-table-header-m-typography-font-size: 12;
$es-theme-controls-data-table-header-m-typography-letter-spacing: 0;
$es-theme-controls-data-table-header-s-typography-font-family: Open Sans;
$es-theme-controls-data-table-header-s-typography-font-weight: Medium;
$es-theme-controls-data-table-header-s-typography-line-height: 100%;
$es-theme-controls-data-table-header-s-typography-font-size: 11;
$es-theme-controls-data-table-header-s-typography-letter-spacing: 0;
$es-theme-controls-data-table-header-height-m-spacing: 4 * 3;
$es-theme-controls-data-table-header-height-s-spacing: 4 * 2;
$es-theme-controls-data-table-header-height-l-sizing: 4 * 11;
$es-theme-controls-data-table-header-height-m-sizing: 4 * 9;
$es-theme-controls-data-table-header-height-s-sizing: 4 * 8;
$es-theme-controls-data-table-header-default-bg-primary-color: #eff0f0;
$es-theme-controls-data-table-header-default-bg-secondary-color: #eff0f0;
$es-theme-controls-data-table-header-default-bg-tertiary-color: #fff;
$es-theme-controls-data-table-header-default-text-color: #292b2d;
$es-theme-controls-data-table-header-default-icon-color: #666666;
$es-theme-controls-data-table-header-default-divider-primary-color: #bdbdbd;
$es-theme-controls-data-table-header-default-divider-secondary-color: #d5d6d8;
$es-theme-controls-data-table-header-default-divider-tertiary-color: #eff0f0;
$es-theme-controls-data-table-header-hover-bg-primary-color: #e5e5e6;
$es-theme-controls-data-table-header-hover-bg-secondary-color: #eff0f0;
$es-theme-controls-data-table-header-hover-bg-tertiary-color: #fff;
$es-theme-controls-data-table-header-hover-text-color: #292b2d;
$es-theme-controls-data-table-header-hover-icon-color: #666666;
$es-theme-controls-data-table-header-hover-divider-primary-color: #bdbdbd;
$es-theme-controls-data-table-header-hover-divider-secondary-color: #d5d6d8;
$es-theme-controls-data-table-header-hover-divider-tertiary-color: #eff0f0;
$es-theme-controls-data-table-header-click-bg-primary-color: #d5d6d8;
$es-theme-controls-data-table-header-click-bg-secondary-color: #eff0f0;
$es-theme-controls-data-table-header-click-bg-tertiary-color: #eff0f0;
$es-theme-controls-data-table-header-click-text-color: #292b2d;
$es-theme-controls-data-table-header-click-icon-color: #121416;
$es-theme-controls-data-table-header-click-divider-primary-color: #bdbdbd;
$es-theme-controls-data-table-header-click-divider-secondary-color: #d5d6d8;
$es-theme-controls-data-table-header-click-divider-tertiary-color: #eff0f0;
$es-theme-controls-data-table-header-width-m-spacing: 4 * 3;
$es-theme-controls-data-table-header-width-s-spacing: 4 * 2;
$es-theme-controls-data-table-header-width-l-sizing: 4 * 11;
$es-theme-controls-data-table-header-width-m-sizing: 4 * 9;
$es-theme-controls-data-table-header-width-s-sizing: 4 * 8;
$es-theme-controls-data-table-header-gap-l-spacing: 4 * 3;
$es-theme-controls-data-table-header-gap-m-spacing: 4 * 2;
$es-theme-controls-data-table-header-gap-s-spacing: 4;
$es-theme-controls-data-table-body-height-l-sizing: 4 * 11;
$es-theme-controls-data-table-body-height-m-sizing: 4 * 9;
$es-theme-controls-data-table-body-height-s-sizing: 4 * 8;
$es-theme-controls-data-table-body-height-xxs-spacing: 4/2;
$es-theme-controls-data-table-body-height-xs-spacing: 4;
$es-theme-controls-data-table-body-height-s-spacing: 4 * 2;
$es-theme-controls-data-table-body-height-m-spacing: 4 * 3;
$es-theme-controls-data-table-body-height-l-spacing: 4 * 4;
$es-theme-controls-data-table-body-width-l-sizing: 4 * 11;
$es-theme-controls-data-table-body-width-m-sizing: 4 * 9;
$es-theme-controls-data-table-body-width-s-sizing: 4 * 8;
$es-theme-controls-data-table-body-width-m-spacing: 4 * 3;
$es-theme-controls-data-table-body-width-l-spacing: 4 * 4;
$es-theme-controls-data-table-body-width-s-spacing: 4 * 2;
$es-theme-controls-data-table-body-width-xs-spacing: 4;
$es-theme-controls-data-table-body-m-typography-font-family: Open Sans;
$es-theme-controls-data-table-body-m-typography-font-weight: Regular;
$es-theme-controls-data-table-body-m-typography-line-height: 100%;
$es-theme-controls-data-table-body-m-typography-font-size: 12;
$es-theme-controls-data-table-body-m-typography-letter-spacing: 0;
$es-theme-controls-data-table-body-l-typography-font-family: Open Sans;
$es-theme-controls-data-table-body-l-typography-font-weight: Regular;
$es-theme-controls-data-table-body-l-typography-line-height: 100%;
$es-theme-controls-data-table-body-l-typography-font-size: 14;
$es-theme-controls-data-table-body-l-typography-letter-spacing: 0;
$es-theme-controls-data-table-body-s-typography-font-family: Open Sans;
$es-theme-controls-data-table-body-s-typography-font-weight: Regular;
$es-theme-controls-data-table-body-s-typography-line-height: 100%;
$es-theme-controls-data-table-body-s-typography-font-size: 10;
$es-theme-controls-data-table-body-s-typography-letter-spacing: 0;
$es-theme-controls-data-table-body-gap-l-spacing: 4 * 3;
$es-theme-controls-data-table-body-gap-m-spacing: 4 * 2;
$es-theme-controls-data-table-body-gap-s-spacing: 4;
$es-theme-controls-data-table-body-default-bg-primary-color: #fff;
$es-theme-controls-data-table-body-default-bg-secondary-color: #eff0f0;
$es-theme-controls-data-table-body-default-text-primary-color: #292b2d;
$es-theme-controls-data-table-body-default-text-secondary-color: #828282;
$es-theme-controls-data-table-body-default-text-tertiary-color: #c1c2c3;
$es-theme-controls-data-table-body-default-text-error-color: #ff2706;
$es-theme-controls-data-table-body-default-text-attention-color: #f28933;
$es-theme-controls-data-table-body-default-text-success-color: #58975b;
$es-theme-controls-data-table-body-default-text-link-color: #6fa1d9;
$es-theme-controls-data-table-body-default-icon-primary-color: #2e7d32;
$es-theme-controls-data-table-body-default-icon-secondary-color: #e86612;
$es-theme-controls-data-table-body-default-icon-tertiary-color: #666666;
$es-theme-controls-data-table-body-default-icon-shades-color: #4b6f98;
$es-theme-controls-data-table-body-default-icon-error-color: #ff2706;
$es-theme-controls-data-table-body-default-icon-attention-color: #f28933;
$es-theme-controls-data-table-body-default-icon-success-color: #58975b;
$es-theme-controls-data-table-body-default-divider-primary-color: #bdbdbd;
$es-theme-controls-data-table-body-default-divider-secondary-color: #d5d6d8;
$es-theme-controls-data-table-body-default-divider-lvl1-color: #fff;
$es-theme-controls-data-table-body-default-divider-lvl2-color: #f7f7f7;
$es-theme-controls-data-table-body-default-divider-lvl3-color: #eff0f0;
$es-theme-controls-data-table-body-hover-bg-primary-color: #e5e5e6;
$es-theme-controls-data-table-body-hover-bg-secondary-color: #eff0f0;
$es-theme-controls-data-table-body-hover-text-primary-color: #292b2d;
$es-theme-controls-data-table-body-hover-text-secondary-color: #828282;
$es-theme-controls-data-table-body-hover-text-tertiary-color: #c1c2c3;
$es-theme-controls-data-table-body-hover-text-error-color: #ff2706;
$es-theme-controls-data-table-body-hover-text-attention-color: #f28933;
$es-theme-controls-data-table-body-hover-text-success-color: #58975b;
$es-theme-controls-data-table-body-hover-text-link-color: #6fa1d9;
$es-theme-controls-data-table-body-hover-icon-primary-color: #2e7d32;
$es-theme-controls-data-table-body-hover-icon-secondary-color: #e86612;
$es-theme-controls-data-table-body-hover-icon-tertiary-color: #666666;
$es-theme-controls-data-table-body-hover-icon-shades-color: #4b6f98;
$es-theme-controls-data-table-body-hover-icon-error-color: #ff2706;
$es-theme-controls-data-table-body-hover-icon-attention-color: #f28933;
$es-theme-controls-data-table-body-hover-icon-success-color: #58975b;
$es-theme-controls-data-table-body-hover-divider-primary-color: #bdbdbd;
$es-theme-controls-data-table-body-hover-divider-secondary-color: #d5d6d8;
$es-theme-controls-data-table-body-hover-divider-lvl1-color: #fff;
$es-theme-controls-data-table-body-hover-divider-lvl2-color: #f7f7f7;
$es-theme-controls-data-table-body-hover-divider-lvl3-color: #eff0f0;
$es-theme-controls-data-table-body-click-bg-primary-color: #2e7d321a;
$es-theme-controls-data-table-body-click-bg-secondary-color: #eff0f0;
$es-theme-controls-data-table-body-click-text-primary-color: #292b2d;
$es-theme-controls-data-table-body-click-text-secondary-color: #828282;
$es-theme-controls-data-table-body-click-text-tertiary-color: #c1c2c3;
$es-theme-controls-data-table-body-click-text-error-color: #ff2706;
$es-theme-controls-data-table-body-click-text-attention-color: #f28933;
$es-theme-controls-data-table-body-click-text-success-color: #58975b;
$es-theme-controls-data-table-body-click-text-link-color: #6fa1d9;
$es-theme-controls-data-table-body-click-icon-primary-color: #2e7d32;
$es-theme-controls-data-table-body-click-icon-secondary-color: #e86612;
$es-theme-controls-data-table-body-click-icon-tertiary-color: #666666;
$es-theme-controls-data-table-body-click-icon-shades-color: #4b6f98;
$es-theme-controls-data-table-body-click-icon-error-color: #ff2706;
$es-theme-controls-data-table-body-click-icon-attention-color: #f28933;
$es-theme-controls-data-table-body-click-icon-success-color: #58975b;
$es-theme-controls-data-table-body-click-divider-primary-color: #bdbdbd;
$es-theme-controls-data-table-body-click-divider-secondary-color: #d5d6d8;
$es-theme-controls-data-table-body-click-divider-lvl1-color: #fff;
$es-theme-controls-data-table-body-click-divider-lvl2-color: #f7f7f7;
$es-theme-controls-data-table-body-click-divider-lvl3-color: #eff0f0;
$es-theme-controls-link-s-typography-font-family: Open Sans;
$es-theme-controls-link-s-typography-font-weight: Regular;
$es-theme-controls-link-s-typography-line-height: 100%;
$es-theme-controls-link-s-typography-font-size: 13;
$es-theme-controls-link-s-typography-letter-spacing: 0;
$es-theme-controls-link-m-typography-font-family: Open Sans;
$es-theme-controls-link-m-typography-font-weight: Regular;
$es-theme-controls-link-m-typography-line-height: 100%;
$es-theme-controls-link-m-typography-font-size: 16;
$es-theme-controls-link-m-typography-letter-spacing: 0;
$es-theme-controls-link-l-typography-font-family: Open Sans;
$es-theme-controls-link-l-typography-font-weight: Regular;
$es-theme-controls-link-l-typography-line-height: 100%;
$es-theme-controls-link-l-typography-font-size: 20;
$es-theme-controls-link-l-typography-letter-spacing: 0;
$es-theme-controls-link-default-primary-color: #557aa5;
$es-theme-controls-link-default-on-primary-color: #6fa1d9;
$es-theme-controls-link-default-secondary-color: #ef6c00;
$es-theme-controls-link-default-on-secondary-color: #ef6c00;
$es-theme-controls-link-default-tertiary-color: #83f105;
$es-theme-controls-link-hover-primary-color: #6fa1d9;
$es-theme-controls-link-hover-on-primary-color: #bedaff;
$es-theme-controls-link-hover-secondary-color: #292b2d;
$es-theme-controls-link-hover-on-secondary-color: #fff;
$es-theme-controls-link-hover-tertiary-color: #fff;
$es-theme-controls-link-height-s-sizing: 4 * 3;
$es-theme-controls-link-height-m-sizing: 4 * 4;
$es-theme-controls-link-height-l-sizing: 4 * 6;
$es-theme-controls-breadcrumb-m-typography-font-family: Open Sans;
$es-theme-controls-breadcrumb-m-typography-font-weight: Regular;
$es-theme-controls-breadcrumb-m-typography-line-height: 100%;
$es-theme-controls-breadcrumb-m-typography-font-size: 16;
$es-theme-controls-breadcrumb-m-typography-letter-spacing: 0;
$es-theme-controls-breadcrumb-s-typography-font-family: Open Sans;
$es-theme-controls-breadcrumb-s-typography-font-weight: Regular;
$es-theme-controls-breadcrumb-s-typography-line-height: 100%;
$es-theme-controls-breadcrumb-s-typography-font-size: 12;
$es-theme-controls-breadcrumb-s-typography-letter-spacing: 0;
$es-theme-controls-breadcrumb-height-s-sizing: 4 * 5;
$es-theme-controls-breadcrumb-height-m-sizing: 4 * 7;
$es-theme-controls-breadcrumb-height-m-spacing: 4 * 3;
$es-theme-controls-breadcrumb-height-s-spacing: 4 * 2;
$es-theme-controls-breadcrumb-width-m-spacing: 4 * 3;
$es-theme-controls-breadcrumb-width-l-spacing: 4 * 4;
$es-theme-controls-breadcrumb-width-xl-spacing: 4 * 5;
$es-theme-controls-breadcrumb-width-s-spacing: 4 * 2;
$es-theme-controls-breadcrumb-width-xs-spacing: 4;
$es-theme-controls-breadcrumb-gap-m-spacing: 4 * 2;
$es-theme-controls-breadcrumb-gap-s-spacing: 4;
$es-theme-controls-breadcrumb-default-bg-primary-color: #f7f7f7;
$es-theme-controls-breadcrumb-default-bg-secondary-color: #fff;
$es-theme-controls-breadcrumb-default-text-color: #292b2d;
$es-theme-controls-breadcrumb-default-icon-primary-color: #2e7d32;
$es-theme-controls-breadcrumb-default-icon-secondary-color: #121416;
$es-theme-controls-breadcrumb-hover-bg-primary-color: #f7f7f7;
$es-theme-controls-breadcrumb-hover-bg-secondary-color: #fff;
$es-theme-controls-breadcrumb-hover-text-color: #2e7d32;
$es-theme-controls-breadcrumb-hover-icon-primary-color: #2e7d32;
$es-theme-controls-breadcrumb-hover-icon-secondary-color: #121416;
$es-theme-controls-breadcrumb-disabled-bg-primary-color: #f7f7f7;
$es-theme-controls-breadcrumb-disabled-bg-secondary-color: #fff;
$es-theme-controls-breadcrumb-disabled-text-color: #828282;
$es-theme-controls-breadcrumb-disabled-icon-primary-color: #666666;
$es-theme-controls-breadcrumb-disabled-icon-secondary-color: #4b6f98;
$es-theme-controls-sidebar-m-typography-font-family: Open Sans;
$es-theme-controls-sidebar-m-typography-font-weight: Regular;
$es-theme-controls-sidebar-m-typography-line-height: 100%;
$es-theme-controls-sidebar-m-typography-font-size: 14;
$es-theme-controls-sidebar-m-typography-letter-spacing: 0;
$es-theme-controls-sidebar-s-typography-font-family: Open Sans;
$es-theme-controls-sidebar-s-typography-font-weight: SemiBold;
$es-theme-controls-sidebar-s-typography-line-height: 100%;
$es-theme-controls-sidebar-s-typography-font-size: 12;
$es-theme-controls-sidebar-s-typography-letter-spacing: 0;
$es-theme-controls-sidebar-height-m-sizing: 4 * 12;
$es-theme-controls-sidebar-height-xxl-sizing: 4 * 16;
$es-theme-controls-sidebar-height-s-sizing: 4 * 9;
$es-theme-controls-sidebar-height-s-spacing: 4 * 2;
$es-theme-controls-sidebar-height-m-spacing: 4 * 3;
$es-theme-controls-sidebar-height-l-spacing: 4 * 6;
$es-theme-controls-sidebar-height-xl-spacing: 4 * 10;
$es-theme-controls-sidebar-width-m-sizing: 4 * 12;
$es-theme-controls-sidebar-width-s-spacing: 4 * 2;
$es-theme-controls-sidebar-width-m-spacing: 4 * 3;
$es-theme-controls-sidebar-width-l-spacing: 4 * 4;
$es-theme-controls-sidebar-width-xl-spacing: 4 * 5;
$es-theme-controls-sidebar-width-xxl-spacing: 4 * 6;
$es-theme-controls-sidebar-width-xxxl-spacing: 4 * 7;
$es-theme-controls-sidebar-width-xxxxl-spacing: 4 * 8;
$es-theme-controls-sidebar-default-bg-primary-color: #fff;
$es-theme-controls-sidebar-default-bg-secondary-color: #ebebeb;
$es-theme-controls-sidebar-default-bg-tertiary-color: #e5e5e6;
$es-theme-controls-sidebar-default-text-primary-color: #000;
$es-theme-controls-sidebar-default-text-secondary-color: #2e7d32;
$es-theme-controls-sidebar-default-text-tertiary-color: #fff;
$es-theme-controls-sidebar-default-line-color: #e5e5e6;
$es-theme-controls-sidebar-default-icon-primary-color: #2e7d32;
$es-theme-controls-sidebar-default-icon-secondary-color: #121416;
$es-theme-controls-sidebar-default-icon-tertiary-color: #828282;
$es-theme-controls-sidebar-hover-bg-primary-color: #f7f7f7;
$es-theme-controls-sidebar-hover-bg-secondary-color: #fff;
$es-theme-controls-sidebar-hover-bg-tertiary-color: #fff;
$es-theme-controls-sidebar-hover-text-primary-color: #000;
$es-theme-controls-sidebar-hover-text-secondary-color: #2e7d32;
$es-theme-controls-sidebar-hover-text-tertiary-color: #fff;
$es-theme-controls-sidebar-hover-line-color: #e5e5e6;
$es-theme-controls-sidebar-hover-icon-primary-color: #2e7d32;
$es-theme-controls-sidebar-hover-icon-secondary-color: #121416;
$es-theme-controls-sidebar-hover-icon-tertiary-color: #828282;
$es-theme-controls-sidebar-active-opened-bg-primary-color: #2e7d3233;
$es-theme-controls-sidebar-active-opened-bg-secondary-color: #2e7d3233;
$es-theme-controls-sidebar-active-opened-bg-tertiary-color: #2e7d3233;
$es-theme-controls-sidebar-active-opened-text-primary-color: #000;
$es-theme-controls-sidebar-active-opened-text-secondary-color: #2e7d32;
$es-theme-controls-sidebar-active-opened-text-tertiary-color: #fff;
$es-theme-controls-sidebar-active-opened-line-color: #e5e5e6;
$es-theme-controls-sidebar-active-opened-icon-primary-color: #2e7d32;
$es-theme-controls-sidebar-active-opened-icon-secondary-color: #2e7d32;
$es-theme-controls-sidebar-active-opened-icon-tertiary-color: #828282;
$es-theme-controls-sidebar-gap-m-spacing: 4 * 3;
$es-theme-controls-sidebar-gap-s-spacing: 4 * 2;
$es-theme-controls-sidebar-gap-l-spacing: 4 * 5;
$es-theme-controls-sidebar-m-box-shadow-x: 0;
$es-theme-controls-sidebar-m-box-shadow-y: 4;
$es-theme-controls-sidebar-m-box-shadow-blur: 7;
$es-theme-controls-sidebar-m-box-shadow-spread: 0;
$es-theme-controls-sidebar-m-box-shadow-color: #00000026;
$es-theme-controls-sidebar-m-box-shadow-type: dropShadow;
$es-theme-controls-list-item-m-typography-font-family: Open Sans;
$es-theme-controls-list-item-m-typography-font-weight: Regular;
$es-theme-controls-list-item-m-typography-line-height: 100%;
$es-theme-controls-list-item-m-typography-font-size: 14;
$es-theme-controls-list-item-m-typography-letter-spacing: 0;
$es-theme-controls-list-item-l-typography-font-family: Open Sans;
$es-theme-controls-list-item-l-typography-font-weight: Regular;
$es-theme-controls-list-item-l-typography-line-height: 100%;
$es-theme-controls-list-item-l-typography-font-size: 15;
$es-theme-controls-list-item-l-typography-letter-spacing: 0;
$es-theme-controls-list-item-s-typography-font-family: Open Sans;
$es-theme-controls-list-item-s-typography-font-weight: Regular;
$es-theme-controls-list-item-s-typography-line-height: 100%;
$es-theme-controls-list-item-s-typography-font-size: 12;
$es-theme-controls-list-item-s-typography-letter-spacing: 0;
$es-theme-controls-list-item-m-border-radius: 4;
$es-theme-controls-list-item-default-bg-primary-color: #fff;
$es-theme-controls-list-item-default-bg-secondary-color: #f7f7f7;
$es-theme-controls-list-item-default-text-title-color: #292b2d;
$es-theme-controls-list-item-default-text-description-color: #828282;
$es-theme-controls-list-item-default-border-color: #e5e5e6;
$es-theme-controls-list-item-default-icon-primary-color: #2e7d32;
$es-theme-controls-list-item-default-icon-secondary-color: #000;
$es-theme-controls-list-item-default-icon-tertiary-color: #c1c2c3;
$es-theme-controls-list-item-hover-bg-primary-color: #eff0f0;
$es-theme-controls-list-item-hover-bg-secondary-color: #d5d6d8;
$es-theme-controls-list-item-hover-text-title-color: #292b2d;
$es-theme-controls-list-item-hover-text-description-color: #292b2d;
$es-theme-controls-list-item-hover-border-color: #fff;
$es-theme-controls-list-item-hover-icon-primary-color: #2e7d32;
$es-theme-controls-list-item-hover-icon-secondary-color: #000;
$es-theme-controls-list-item-hover-icon-tertiary-color: #c1c2c3;
$es-theme-controls-list-item-focused-bg-primary-color: #eff0f0;
$es-theme-controls-list-item-focused-bg-secondary-color: #d5d6d8;
$es-theme-controls-list-item-focused-text-title-color: #292b2d;
$es-theme-controls-list-item-focused-text-description-color: #828282;
$es-theme-controls-list-item-focused-border-color: #f28933;
$es-theme-controls-list-item-focused-icon-primary-color: #2e7d32;
$es-theme-controls-list-item-focused-icon-secondary-color: #000;
$es-theme-controls-list-item-focused-icon-tertiary-color: #c1c2c3;
$es-theme-controls-list-item-pressed-bg-primary-color: #d5d6d8;
$es-theme-controls-list-item-pressed-bg-secondary-color: #d5d6d8;
$es-theme-controls-list-item-pressed-text-title-color: #292b2d;
$es-theme-controls-list-item-pressed-text-description-color: #828282;
$es-theme-controls-list-item-pressed-border-color: #e5e5e6;
$es-theme-controls-list-item-pressed-icon-primary-color: #2e7d32;
$es-theme-controls-list-item-pressed-icon-secondary-color: #000;
$es-theme-controls-list-item-pressed-icon-tertiary-color: #c1c2c3;
$es-theme-controls-list-item-dragged-bg-primary-color: #fff;
$es-theme-controls-list-item-dragged-bg-secondary-color: #eff0f0;
$es-theme-controls-list-item-dragged-text-title-color: #292b2d;
$es-theme-controls-list-item-dragged-text-description-color: #828282;
$es-theme-controls-list-item-dragged-border-color: #c1c2c3;
$es-theme-controls-list-item-dragged-icon-primary-color: #2e7d32;
$es-theme-controls-list-item-dragged-icon-secondary-color: #000;
$es-theme-controls-list-item-dragged-icon-tertiary-color: #c1c2c3;
$es-theme-controls-list-item-disabled-bg-primary-color: #ebebeb;
$es-theme-controls-list-item-disabled-bg-secondary-color: #ebebeb;
$es-theme-controls-list-item-disabled-text-title-color: #c1c2c3;
$es-theme-controls-list-item-disabled-text-description-color: #c1c2c3;
$es-theme-controls-list-item-disabled-border-color: #e5e5e6;
$es-theme-controls-list-item-disabled-icon-primary-color: #2e7d324d;
$es-theme-controls-list-item-disabled-icon-secondary-color: #c1c2c3;
$es-theme-controls-list-item-disabled-icon-tertiary-color: #c1c2c3;
$es-theme-controls-list-item-height-m-spacing: 4;
$es-theme-controls-list-item-height-l-spacing: 4 * 2;
$es-theme-controls-list-item-height-xl-spacing: 4 * 3;
$es-theme-controls-list-item-width-m-spacing: 4;
$es-theme-controls-list-item-width-l-spacing: 4 * 2;
$es-theme-controls-list-item-width-xl-spacing: 4 * 3;
$es-theme-controls-list-item-width-xxl-spacing: 4 * 4;
$es-theme-controls-list-item-width-xxxl-spacing: 4 * 6;
$es-theme-controls-list-item-gap-m-spacing: 4;
$es-theme-controls-list-item-gap-l-spacing: 4 * 2;
$es-theme-controls-list-item-gap-xl-spacing: 4 * 3;
$es-theme-controls-list-item-gap-xxl-spacing: 4 * 4;
$es-theme-controls-list-item-gap-xxxl-spacing: 4 * 5;
$es-theme-controls-list-item-gap-xxxxl-spacing: 4 * 6;
$es-theme-controls-inputs-outlined-inactive-default-bg-primary-color: #fff;
$es-theme-controls-inputs-outlined-inactive-default-bg-secondary-color: #d5d6d8;
$es-theme-controls-inputs-outlined-inactive-default-text-label-color: #292b2d;
$es-theme-controls-inputs-outlined-inactive-default-text-input-color: #292b2d;
$es-theme-controls-inputs-outlined-inactive-default-text-placeholder-color: #828282;
$es-theme-controls-inputs-outlined-inactive-default-text-helper-color: #828282;
$es-theme-controls-inputs-outlined-inactive-default-border-color: #d5d6d8;
$es-theme-controls-inputs-outlined-inactive-default-divider-color: #bdbdbd;
$es-theme-controls-inputs-outlined-inactive-default-icon-primary-color: #2e7d32;
$es-theme-controls-inputs-outlined-inactive-default-icon-secondary-color: #828282;
$es-theme-controls-inputs-outlined-inactive-default-icon-tertiary-color: #121416;
$es-theme-controls-inputs-outlined-inactive-default-icon-success-color: #58975b;
$es-theme-controls-inputs-outlined-inactive-default-icon-error-color: #ff2706;
$es-theme-controls-inputs-outlined-inactive-default-icon-attention-color: #f28933;
$es-theme-controls-inputs-outlined-focused-bg-primary-color: #fff;
$es-theme-controls-inputs-outlined-focused-bg-secondary-color: #d5d6d8;
$es-theme-controls-inputs-outlined-focused-text-label-color: #58975b;
$es-theme-controls-inputs-outlined-focused-text-input-color: #292b2d;
$es-theme-controls-inputs-outlined-focused-text-placeholder-color: #828282;
$es-theme-controls-inputs-outlined-focused-text-helper-color: #828282;
$es-theme-controls-inputs-outlined-focused-border-color: #58975b;
$es-theme-controls-inputs-outlined-focused-divider-color: #58975b;
$es-theme-controls-inputs-outlined-focused-icon-primary-color: #2e7d32;
$es-theme-controls-inputs-outlined-focused-icon-secondary-color: #828282;
$es-theme-controls-inputs-outlined-focused-icon-tertiary-color: #121416;
$es-theme-controls-inputs-outlined-focused-icon-success-color: #58975b;
$es-theme-controls-inputs-outlined-focused-icon-error-color: #ff2706;
$es-theme-controls-inputs-outlined-focused-icon-attention-color: #f28933;
$es-theme-controls-inputs-outlined-hover-bg-primary-color: #fff;
$es-theme-controls-inputs-outlined-hover-bg-secondary-color: #ebebeb;
$es-theme-controls-inputs-outlined-hover-text-label-color: #292b2d;
$es-theme-controls-inputs-outlined-hover-text-input-color: #292b2d;
$es-theme-controls-inputs-outlined-hover-text-placeholder-color: #828282;
$es-theme-controls-inputs-outlined-hover-text-helper-color: #828282;
$es-theme-controls-inputs-outlined-hover-border-color: #c1c2c3;
$es-theme-controls-inputs-outlined-hover-divider-color: #bdbdbd;
$es-theme-controls-inputs-outlined-hover-icon-primary-color: #2e7d32;
$es-theme-controls-inputs-outlined-hover-icon-secondary-color: #828282;
$es-theme-controls-inputs-outlined-hover-icon-tertiary-color: #121416;
$es-theme-controls-inputs-outlined-hover-icon-success-color: #58975b;
$es-theme-controls-inputs-outlined-hover-icon-error-color: #ff2706;
$es-theme-controls-inputs-outlined-hover-icon-attention-color: #f28933;
$es-theme-controls-inputs-outlined-error-bg-primary-color: #fff;
$es-theme-controls-inputs-outlined-error-bg-secondary-color: #eff0f0;
$es-theme-controls-inputs-outlined-error-text-label-color: #c55442;
$es-theme-controls-inputs-outlined-error-text-input-color: #292b2d;
$es-theme-controls-inputs-outlined-error-text-placeholder-color: #828282;
$es-theme-controls-inputs-outlined-error-text-helper-color: #c55442;
$es-theme-controls-inputs-outlined-error-border-color: #ff4d4d;
$es-theme-controls-inputs-outlined-error-divider-color: #ff2706;
$es-theme-controls-inputs-outlined-error-icon-primary-color: #2e7d32;
$es-theme-controls-inputs-outlined-error-icon-secondary-color: #828282;
$es-theme-controls-inputs-outlined-error-icon-tertiary-color: #121416;
$es-theme-controls-inputs-outlined-error-icon-success-color: #58975b;
$es-theme-controls-inputs-outlined-error-icon-error-color: #ff2706;
$es-theme-controls-inputs-outlined-error-icon-attention-color: #f28933;
$es-theme-controls-inputs-outlined-disabled-bg-primary-color: #ebebeb;
$es-theme-controls-inputs-outlined-disabled-bg-secondary-color: #e5e5e6;
$es-theme-controls-inputs-outlined-disabled-text-label-color: #292b2d;
$es-theme-controls-inputs-outlined-disabled-text-input-color: #292b2d;
$es-theme-controls-inputs-outlined-disabled-text-placeholder-color: #828282;
$es-theme-controls-inputs-outlined-disabled-text-helper-color: #828282;
$es-theme-controls-inputs-outlined-disabled-border-color: #e5e5e6;
$es-theme-controls-inputs-outlined-disabled-divider-color: #e5e5e6;
$es-theme-controls-inputs-outlined-disabled-icon-primary-color: #4b6f98;
$es-theme-controls-inputs-outlined-disabled-icon-secondary-color: #828282;
$es-theme-controls-inputs-outlined-disabled-icon-tertiary-color: #4b6f98;
$es-theme-controls-inputs-outlined-disabled-icon-success-color: #58975b;
$es-theme-controls-inputs-outlined-disabled-icon-error-color: #ff2706;
$es-theme-controls-inputs-outlined-disabled-icon-attention-color: #f28933;
$es-theme-controls-expansion-panel-xl-border-radius: 4 * 2;
$es-theme-controls-expansion-panel-height-l-sizing: 4 * 25;
$es-theme-controls-expansion-panel-height-m-sizing: 4 * 14;
$es-theme-controls-expansion-panel-height-s-sizing: 4 * 10;
$es-theme-controls-expansion-panel-height-m-spacing: 4;
$es-theme-controls-expansion-panel-height-l-spacing: 4 * 2;
$es-theme-controls-expansion-panel-height-xl-spacing: 4 * 3;
$es-theme-controls-expansion-panel-width-m-spacing: 4;
$es-theme-controls-expansion-panel-width-l-spacing: 4 * 2;
$es-theme-controls-expansion-panel-width-xl-spacing: 4 * 3;
$es-theme-controls-expansion-panel-width-xxl-spacing: 4 * 4;
$es-theme-controls-expansion-panel-width-xxxl-spacing: 4 * 6;
$es-theme-controls-expansion-panel-gap-m-spacing: 4;
$es-theme-controls-expansion-panel-gap-l-spacing: 4 * 2;
$es-theme-controls-expansion-panel-gap-xl-spacing: 4 * 3;
$es-theme-controls-expansion-panel-gap-xxl-spacing: 4 * 4;
$es-theme-controls-expansion-panel-gap-xxxl-spacing: 4 * 5;
$es-theme-controls-expansion-panel-gap-xxxxl-spacing: 4 * 6;
$es-theme-controls-chip-s-border-radius: 4 * 2;
$es-theme-controls-chip-l-border-radius: 4 * 8;
$es-theme-controls-chip-height-m-sizing: 4 * 8;
$es-theme-controls-chip-height-s-sizing: 4 * 6;
$es-theme-controls-chip-default-box-shadow-x: 0;
$es-theme-controls-chip-default-box-shadow-y: 1;
$es-theme-controls-chip-default-box-shadow-blur: 2;
$es-theme-controls-chip-default-box-shadow-spread: 0;
$es-theme-controls-chip-default-box-shadow-color: #00000040;
$es-theme-controls-chip-default-box-shadow-type: dropShadow;
$es-theme-controls-chip-dragged-box-shadow-x: 0;
$es-theme-controls-chip-dragged-box-shadow-y: 4;
$es-theme-controls-chip-dragged-box-shadow-blur: 7;
$es-theme-controls-chip-dragged-box-shadow-spread: 0;
$es-theme-controls-chip-dragged-box-shadow-color: #00000026;
$es-theme-controls-chip-dragged-box-shadow-type: dropShadow;
$es-theme-controls-chip-width-m-spacing: 4;
$es-theme-controls-chip-width-l-spacing: 4 * 2;
$es-theme-controls-chip-width-xl-spacing: 4 * 3;
$es-theme-controls-chip-gap-m-spacing: 4;
$es-theme-controls-chip-gap-l-spacing: 4 * 2;
$es-theme-controls-badge-l-border-radius: 4 * 5;
$es-theme-controls-badge-height-s-sizing: 4 * 5;
$es-theme-controls-badge-height-xs-sizing: 4 * 2;
$es-theme-controls-badge-width-s-sizing: 4 * 5;
$es-theme-controls-badge-width-xs-sizing: 4 * 2;
$es-theme-controls-check-width-m-sizing: 4 * 5;
$es-theme-controls-check-width-s-sizing: 4 * 4;
$es-theme-controls-check-height-m-sizing: 4 * 5;
$es-theme-controls-check-height-s-sizing: 4 * 4;
$es-theme-controls-check-default-bg-primary-color: #2e7d32;
$es-theme-controls-check-default-border-color: #2e7d32;
$es-theme-controls-check-default-icon-color: #fff;
$es-theme-controls-check-disabled-bg-primary-color: #2e7d324d;
$es-theme-controls-check-disabled-border-color: #2e7d324d;
$es-theme-controls-check-disabled-icon-color: #ffffff99;
$es-theme-controls-check-m-border-radius: 2;
$es-theme-controls-check-hover-bg-primary-color: #2e7d32;
$es-theme-controls-check-hover-border-color: #2e7d32;
$es-theme-controls-check-hover-icon-color: #fff;
$es-theme-controls-radio-default-bg-primary-color: #2e7d32;
$es-theme-controls-radio-default-border-color: #2e7d32;
$es-theme-controls-radio-default-icon-color: #fff;
$es-theme-controls-radio-disabled-bg-primary-color: #2e7d324d;
$es-theme-controls-radio-disabled-border-color: #2e7d324d;
$es-theme-controls-radio-disabled-icon-color: #ffffff99;
$es-theme-controls-radio-hover-bg-primary-color: #2e7d32;
$es-theme-controls-radio-hover-border-color: #2e7d32;
$es-theme-controls-radio-hover-icon-color: #fff;
$es-theme-controls-radio-bg-width-m-sizing: 4 * 5;
$es-theme-controls-radio-bg-width-s-sizing: 4 * 4;
$es-theme-controls-radio-bg-height-m-sizing: 4 * 5;
$es-theme-controls-radio-bg-height-s-sizing: 4 * 4;
$es-theme-controls-radio-icon-width-m-sizing: 4 * 5 / 2;
$es-theme-controls-radio-icon-width-s-sizing: 4 * 4 / 2;
$es-theme-controls-radio-icon-height-m-sizing: 4 * 5 / 2;
$es-theme-controls-radio-icon-height-s-sizing: 4 * 4 / 2;
$es-theme-controls-toggle-bg-height-m-sizing: 4 * 5;
$es-theme-controls-toggle-bg-height-s-sizing: 4 * 4;
$es-theme-controls-toggle-bg-width-m-sizing: 4 * 10;
$es-theme-controls-toggle-bg-width-s-sizing: 4 * 8;
$es-theme-controls-toggle-icon-height-m-sizing: 4 * 5 / 1.5;
$es-theme-controls-toggle-icon-height-s-sizing: 4 * 4 / 1.5;
$es-theme-controls-toggle-icon-width-m-sizing: 4 * 5 / 1.5;
$es-theme-controls-toggle-icon-width-s-sizing: 4 * 4 / 1.5;
$es-theme-controls-toggle-default-bg-primary-color: #2e7d32;
$es-theme-controls-toggle-default-border-color: #828282;
$es-theme-controls-toggle-default-icon-primary-color: #2e7d32;
$es-theme-controls-toggle-default-icon-secondary-color: #828282;
$es-theme-controls-toggle-disabled-bg-primary-color: #2e7d324d;
$es-theme-controls-toggle-disabled-border-color: #2e7d324d;
$es-theme-controls-toggle-disabled-icon-primary-color: #2e7d324d;
$es-theme-controls-toggle-disabled-icon-secondary-color: #2e7d324d;
$es-theme-controls-toggle-hover-bg-primary-color: #2e7d32;
$es-theme-controls-toggle-hover-border-color: #2e7d32;
$es-theme-controls-toggle-hover-icon-primary-color: #2e7d32;
$es-theme-controls-toggle-hover-icon-secondary-color: #828282;
$es-theme-controls-toggle-width-m-spacing: 4;
$es-theme-controls-toggle-width-s-spacing: 4/2;
$es-theme-controls-alert-m-border-radius: 4 * 2;
$es-theme-controls-alert-s-border-radius: 4;
$es-theme-controls-tooltip-m-border-radius: 4;
$es-theme-controls-scrollbar-default-track-color: #fff;
$es-theme-controls-scrollbar-default-button-color: #e5e5e6;
$es-theme-controls-scrollbar-default-thumb-color: #e5e5e6;
$es-theme-controls-scrollbar-default-resizer-color: #828282;
$es-theme-controls-scrollbar-hover-track-color: #eff0f0;
$es-theme-controls-scrollbar-hover-button-color: #373839;
$es-theme-controls-scrollbar-hover-thumb-color: #828282;
$es-theme-controls-scrollbar-hover-resizer-color: #828282;
$es-theme-controls-scrollbar-pressed-track-color: #373839;
$es-theme-controls-scrollbar-pressed-button-color: #fff;
$es-theme-controls-scrollbar-pressed-thumb-color: #373839;
$es-theme-controls-scrollbar-pressed-resizer-color: #828282;
$es-theme-controls-scrollbar-disabled-track-color: #fff;
$es-theme-controls-scrollbar-disabled-button-color: #d5d6d8;
$es-theme-controls-scrollbar-disabled-thumb-color: #f7f7f7;
$es-theme-controls-scrollbar-disabled-resizer-color: #d5d6d8;
$es-theme-controls-stepper-height-m-spacing: 4;
$es-theme-controls-stepper-height-l-spacing: 4 * 2;
$es-theme-controls-stepper-height-xl-spacing: 4 * 3;
$es-theme-controls-stepper-height-xxl-spacing: 4 * 4;
$es-theme-controls-stepper-height-xxxl-spacing: 4 * 5;
$es-theme-controls-stepper-height-xxxxl-spacing: 4 * 6;
$es-theme-controls-stepper-height-5-xl-spacing: 4 * 7;
$es-theme-controls-stepper-width-m-spacing: 4;
$es-theme-controls-stepper-width-l-spacing: 4 * 2;
$es-theme-controls-stepper-width-xl-spacing: 4 * 3;
$es-theme-controls-stepper-width-xxl-spacing: 4 * 4;
$es-theme-controls-stepper-width-xxxl-spacing: 4 * 6;
$es-theme-controls-stepper-width-xxxxl-spacing: 4 * 7;
$es-theme-controls-stepper-gap-m-spacing: 4;
$es-theme-controls-stepper-gap-l-spacing: 4 * 2;
$es-theme-controls-stepper-gap-xl-spacing: 4 * 3;
$es-theme-controls-stepper-gap-xxl-spacing: 4 * 4;
$es-theme-controls-stepper-gap-xxxl-spacing: 4 * 5;
$es-theme-controls-stepper-gap-xxxxl-spacing: 4 * 6;
$es-theme-controls-stepper-default-bg-primary-color: #2e7d32;
$es-theme-controls-stepper-default-bg-secondary-color: #d5d6d8;
$es-theme-controls-stepper-default-bg-tertiary-color: #fff;
$es-theme-controls-stepper-default-text-primary-color: #2e7d32;
$es-theme-controls-stepper-default-text-secondary-color: #292b2d;
$es-theme-controls-stepper-default-text-success-color: #58975b;
$es-theme-controls-stepper-default-text-tertiary-color: #fff;
$es-theme-controls-stepper-default-icon-success-color: #58975b;
$es-theme-controls-stepper-default-icon-secondary-color: #f28933;
$es-theme-controls-stepper-default-icon-tertiary-color: #c1c2c3;
$es-theme-controls-stepper-default-icon-white-everywhere-color: #fff;
$es-theme-controls-stepper-default-divider-primary-color: #bdbdbd;
$es-theme-controls-stepper-default-divider-secondary-color: #2e7d32;
$es-theme-controls-stepper-default-border-primary-color: #2e7d32;
$es-theme-controls-stepper-default-border-secondary-color: #ff9137;
$es-theme-controls-stepper-default-border-tertiary-color: #828282;
$es-theme-controls-stepper-hover-bg-primary-color: #2e7d32;
$es-theme-controls-stepper-hover-bg-secondary-color: #d5d6d8;
$es-theme-controls-stepper-hover-bg-tertiary-color: #fff;
$es-theme-controls-stepper-hover-text-primary-color: #2e7d32;
$es-theme-controls-stepper-hover-text-secondary-color: #292b2d;
$es-theme-controls-stepper-hover-text-success-color: #58975b;
$es-theme-controls-stepper-hover-text-tertiary-color: #fff;
$es-theme-controls-stepper-hover-icon-success-color: #58975b;
$es-theme-controls-stepper-hover-icon-secondary-color: #f28933;
$es-theme-controls-stepper-hover-icon-tertiary-color: #c1c2c3;
$es-theme-controls-stepper-hover-icon-white-everywhere-color: #fff;
$es-theme-controls-stepper-hover-divider-primary-color: #bdbdbd;
$es-theme-controls-stepper-hover-divider-secondary-color: #2e7d32;
$es-theme-controls-stepper-hover-border-primary-color: #2e7d32;
$es-theme-controls-stepper-hover-border-secondary-color: #ff9137;
$es-theme-controls-stepper-hover-border-tertiary-color: #828282;
$es-theme-controls-stepper-pressed-bg-primary-color: #2e7d32;
$es-theme-controls-stepper-pressed-bg-secondary-color: #d5d6d8;
$es-theme-controls-stepper-pressed-bg-tertiary-color: #fff;
$es-theme-controls-stepper-pressed-text-primary-color: #2e7d32;
$es-theme-controls-stepper-pressed-text-secondary-color: #292b2d;
$es-theme-controls-stepper-pressed-text-success-color: #58975b;
$es-theme-controls-stepper-pressed-text-tertiary-color: #fff;
$es-theme-controls-stepper-pressed-icon-success-color: #58975b;
$es-theme-controls-stepper-pressed-icon-secondary-color: #f28933;
$es-theme-controls-stepper-pressed-icon-tertiary-color: #c1c2c3;
$es-theme-controls-stepper-pressed-icon-white-everywhere-color: #fff;
$es-theme-controls-stepper-pressed-divider-primary-color: #bdbdbd;
$es-theme-controls-stepper-pressed-divider-secondary-color: #2e7d32;
$es-theme-controls-stepper-pressed-border-primary-color: #2e7d32;
$es-theme-controls-stepper-pressed-border-secondary-color: #ff9137;
$es-theme-controls-stepper-pressed-border-tertiary-color: #828282;
$es-theme-controls-stepper-disabled-bg-primary-color: #eff0f0;
$es-theme-controls-stepper-disabled-bg-secondary-color: #d5d6d8;
$es-theme-controls-stepper-disabled-bg-tertiary-color: #fff;
$es-theme-controls-stepper-disabled-text-primary-color: #c1c2c3;
$es-theme-controls-stepper-disabled-text-secondary-color: #c1c2c3;
$es-theme-controls-stepper-disabled-text-success-color: #c1c2c3;
$es-theme-controls-stepper-disabled-text-tertiary-color: #fff;
$es-theme-controls-stepper-disabled-icon-success-color: #bdbdbd;
$es-theme-controls-stepper-disabled-icon-secondary-color: #bdbdbd;
$es-theme-controls-stepper-disabled-icon-tertiary-color: #c1c2c3;
$es-theme-controls-stepper-disabled-icon-white-everywhere-color: #fff;
$es-theme-controls-stepper-disabled-divider-primary-color: #bdbdbd;
$es-theme-controls-stepper-disabled-divider-secondary-color: #d5d6d8;
$es-theme-controls-stepper-disabled-border-primary-color: #828282;
$es-theme-controls-stepper-disabled-border-secondary-color: #828282;
$es-theme-controls-stepper-disabled-border-tertiary-color: #828282;
$es-theme-controls-stepper-m-typography-font-family: Open Sans;
$es-theme-controls-stepper-m-typography-font-weight: Regular;
$es-theme-controls-stepper-m-typography-line-height: 170%;
$es-theme-controls-stepper-m-typography-font-size: 14;
$es-theme-controls-stepper-m-typography-letter-spacing: 0;
$es-theme-controls-stepper-m-typography-paragraph-spacing: none;
$es-theme-controls-stepper-m-typography-text-case: none;
$es-theme-controls-stepper-m-typography-text-decoration: none;
$es-theme-controls-stepper-s-typography-font-family: Open Sans;
$es-theme-controls-stepper-s-typography-font-weight: Regular;
$es-theme-controls-stepper-s-typography-line-height: 100%;
$es-theme-controls-stepper-s-typography-font-size: 13;
$es-theme-controls-stepper-s-typography-letter-spacing: 0;
$es-theme-controls-stepper-l-typography-font-family: Open Sans;
$es-theme-controls-stepper-l-typography-font-weight: SemiBold;
$es-theme-controls-stepper-l-typography-line-height: 170%;
$es-theme-controls-stepper-l-typography-font-size: 14;
$es-theme-controls-stepper-l-typography-letter-spacing: 0;
$es-theme-controls-stepper-l-typography-paragraph-spacing: none;
$es-theme-controls-stepper-l-typography-text-case: none;
$es-theme-controls-stepper-l-typography-text-decoration: none;
$es-theme-controls-stepper-m-box-shadow-x: 0;
$es-theme-controls-stepper-m-box-shadow-y: 1;
$es-theme-controls-stepper-m-box-shadow-blur: 3;
$es-theme-controls-stepper-m-box-shadow-spread: 0;
$es-theme-controls-stepper-m-box-shadow-color: #00000026;
$es-theme-controls-stepper-m-box-shadow-type: dropShadow;
$es-theme-controls-stepper-rounded-border-radius: 4 * 5;
$es-theme-controls-tabs-height-m-sizing: 4 * 10;
$es-theme-controls-tabs-height-xs-sizing: 4 * 7;
$es-theme-controls-tabs-height-m-spacing: 4;
$es-theme-controls-tabs-height-l-spacing: 4 * 2;
$es-theme-controls-tabs-height-xl-spacing: 4 * 3;
$es-theme-controls-tabs-width-m-spacing: 4;
$es-theme-controls-tabs-width-l-spacing: 4 * 2;
$es-theme-controls-tabs-width-xl-spacing: 4 * 3;
$es-theme-controls-tabs-width-xxl-spacing: 4 * 4;
$es-theme-controls-tabs-width-xxxl-spacing: 4 * 6;
$es-theme-controls-tabs-gap-m-spacing: 4;
$es-theme-controls-tabs-gap-l-spacing: 4 * 2;
$es-theme-controls-tabs-gap-xl-spacing: 4 * 3;
$es-theme-controls-tabs-gap-xxl-spacing: 4 * 4;
$es-theme-controls-tabs-gap-xxxl-spacing: 4 * 5;
$es-theme-controls-tabs-gap-xxxxl-spacing: 4 * 6;
$es-theme-controls-tabs-default-bg-primary-color: #fff;
$es-theme-controls-tabs-default-text-primary-color: #2e7d32;
$es-theme-controls-tabs-default-text-secondary-color: #828282;
$es-theme-controls-tabs-default-text-tertiary-color: #fff;
$es-theme-controls-tabs-default-line-primary-color: #2e7d32;
$es-theme-controls-tabs-default-line-secondary-color: #dddedf;
$es-theme-controls-tabs-default-line-tertiary-color: #ffffff33;
$es-theme-controls-tabs-default-icon-primary-color: #2e7d32;
$es-theme-controls-tabs-default-icon-secondary-color: #4b6f98;
$es-theme-controls-tabs-disabled-bg-primary-color: #fff;
$es-theme-controls-tabs-disabled-text-primary-color: #2e7d324d;
$es-theme-controls-tabs-disabled-text-secondary-color: #2e7d324d;
$es-theme-controls-tabs-disabled-text-tertiary-color: #fff;
$es-theme-controls-tabs-disabled-line-primary-color: #2e7d324d;
$es-theme-controls-tabs-disabled-line-secondary-color: #2e7d324d;
$es-theme-controls-tabs-disabled-line-tertiary-color: #2e7d324d;
$es-theme-controls-tabs-disabled-icon-primary-color: #2e7d324d;
$es-theme-controls-tabs-disabled-icon-secondary-color: #2e7d324d;
$es-theme-controls-tabs-hover-bg-primary-color: #eff0f0;
$es-theme-controls-tabs-hover-text-primary-color: #388e3c;
$es-theme-controls-tabs-hover-text-secondary-color: #828282;
$es-theme-controls-tabs-hover-text-tertiary-color: #fff;
$es-theme-controls-tabs-hover-line-primary-color: #388e3c;
$es-theme-controls-tabs-hover-line-secondary-color: #388e3c;
$es-theme-controls-tabs-hover-line-tertiary-color: #388e3c;
$es-theme-controls-tabs-hover-icon-primary-color: #388e3c;
$es-theme-controls-tabs-hover-icon-secondary-color: #4b6f98;
$es-theme-controls-tabs-m-typography-font-family: Open Sans;
$es-theme-controls-tabs-m-typography-font-weight: Medium;
$es-theme-controls-tabs-m-typography-line-height: 100%;
$es-theme-controls-tabs-m-typography-font-size: 14;
$es-theme-controls-tabs-m-typography-letter-spacing: 0;
$es-theme-controls-tabs-l-typography-font-family: Open Sans;
$es-theme-controls-tabs-l-typography-font-weight: Medium;
$es-theme-controls-tabs-l-typography-line-height: 100%;
$es-theme-controls-tabs-l-typography-font-size: 16;
$es-theme-controls-tabs-l-typography-letter-spacing: 0;
$es-theme-controls-tabs-pressed-bg-primary-color: #fff;
$es-theme-controls-tabs-pressed-text-primary-color: #2e7d32;
$es-theme-controls-tabs-pressed-text-secondary-color: #828282;
$es-theme-controls-tabs-pressed-text-tertiary-color: #fff;
$es-theme-controls-tabs-pressed-line-primary-color: #2e7d32;
$es-theme-controls-tabs-pressed-line-secondary-color: #d5d6d8;
$es-theme-controls-tabs-pressed-line-tertiary-color: #fff;
$es-theme-controls-tabs-pressed-icon-primary-color: #2e7d32;
$es-theme-controls-tabs-pressed-icon-secondary-color: #4b6f98;
$es-theme-controls-search-cell-label-m-typography-font-family: Open Sans;
$es-theme-controls-search-cell-label-m-typography-font-weight: Medium;
$es-theme-controls-search-cell-label-m-typography-line-height: 100%;
$es-theme-controls-search-cell-label-m-typography-font-size: 18;
$es-theme-controls-search-cell-label-m-typography-letter-spacing: 0;
$es-theme-controls-search-cell-body-m-typography-font-family: Open Sans;
$es-theme-controls-search-cell-body-m-typography-font-weight: Regular;
$es-theme-controls-search-cell-body-m-typography-line-height: 100%;
$es-theme-controls-search-cell-body-m-typography-font-size: 16;
$es-theme-controls-search-cell-body-m-typography-letter-spacing: 0;
$es-theme-controls-search-cell-body-s-typography-font-family: Open Sans;
$es-theme-controls-search-cell-body-s-typography-font-weight: Regular;
$es-theme-controls-search-cell-body-s-typography-line-height: 100%;
$es-theme-controls-search-cell-body-s-typography-font-size: 14;
$es-theme-controls-search-cell-body-s-typography-letter-spacing: 0;
$es-theme-controls-search-cell-body-xs-typography-font-family: Open Sans;
$es-theme-controls-search-cell-body-xs-typography-font-weight: Regular;
$es-theme-controls-search-cell-body-xs-typography-line-height: 170%;
$es-theme-controls-search-cell-body-xs-typography-font-size: 12;
$es-theme-controls-search-cell-body-xs-typography-letter-spacing: 0;
$es-theme-controls-search-cell-body-xs-typography-paragraph-spacing: none;
$es-theme-controls-search-cell-body-xs-typography-text-case: none;
$es-theme-controls-search-cell-body-xs-typography-text-decoration: none;
$es-theme-controls-search-cell-trailing-m-typography-font-family: Open Sans;
$es-theme-controls-search-cell-trailing-m-typography-font-weight: Regular;
$es-theme-controls-search-cell-trailing-m-typography-line-height: 100%;
$es-theme-controls-search-cell-trailing-m-typography-font-size: 12;
$es-theme-controls-search-cell-trailing-m-typography-letter-spacing: 0;
$es-theme-controls-search-cell-height-s-spacing: 4 * 2;
$es-theme-controls-search-cell-height-m-spacing: 4 * 3;
$es-theme-controls-search-cell-height-l-spacing: 4 * 6;
$es-theme-controls-search-cell-height-xl-spacing: 4 * 10;
$es-theme-controls-search-cell-width-s-spacing: 4 * 2;
$es-theme-controls-search-cell-width-m-spacing: 4 * 3;
$es-theme-controls-search-cell-width-l-spacing: 4 * 4;
$es-theme-controls-search-cell-width-xl-spacing: 4 * 5;
$es-theme-controls-search-cell-width-xxl-spacing: 4 * 6;
$es-theme-controls-search-cell-width-xxxl-spacing: 4 * 7;
$es-theme-controls-search-cell-width-xxxxl-spacing: 4 * 8;
$es-theme-controls-search-cell-gap-xs-spacing: 4;
$es-theme-controls-search-cell-gap-s-spacing: 4 * 2;
$es-theme-controls-search-cell-gap-m-spacing: 4 * 3;
$es-theme-controls-search-cell-gap-l-spacing: 4 * 5;
$es-theme-controls-search-cell-unselected-bg-primary-color: #eff0f0;
$es-theme-controls-search-cell-unselected-bg-secondary-color: #e5e5e6;
$es-theme-controls-search-cell-unselected-bg-tertiary-color: #fff;
$es-theme-controls-search-cell-unselected-text-label-color: #292b2d;
$es-theme-controls-search-cell-unselected-text-body-color: #292b2d;
$es-theme-controls-search-cell-unselected-text-trailing-color: #828282;
$es-theme-controls-search-cell-unselected-divider-pimary-color: #f7f7f7;
$es-theme-controls-search-cell-unselected-divider-secondary-color: #d5d6d8;
$es-theme-controls-search-cell-unselected-icon-primary-color: #2e7d32;
$es-theme-controls-search-cell-unselected-icon-secondary-color: #4b6f98;
$es-theme-controls-search-cell-selected-bg-primary-color: #d5d6d8;
$es-theme-controls-search-cell-selected-bg-secondary-color: #e5e5e6;
$es-theme-controls-search-cell-selected-bg-tertiary-color: #2e7d3233;
$es-theme-controls-search-cell-selected-text-label-color: #292b2d;
$es-theme-controls-search-cell-selected-text-body-color: #292b2d;
$es-theme-controls-search-cell-selected-text-trailing-color: #292b2d;
$es-theme-controls-search-cell-selected-divider-primary-color: #f7f7f7;
$es-theme-controls-search-cell-selected-divider-secondary-color: #d5d6d8;
$es-theme-controls-search-cell-selected-icon-primary-color: #2e7d32;
$es-theme-controls-search-cell-selected-icon-secondary-color: #2e7d32;
$es-theme-controls-search-cell-hover-bg-primary-color: #e5e5e6;
$es-theme-controls-search-cell-hover-bg-secondary-color: #e5e5e6;
$es-theme-controls-search-cell-hover-bg-tertiary-color: #e5e5e6;
$es-theme-controls-search-cell-hover-text-label-color: #292b2d;
$es-theme-controls-search-cell-hover-text-body-color: #292b2d;
$es-theme-controls-search-cell-hover-text-trailing-color: #828282;
$es-theme-controls-search-cell-hover-divider-primary-color: #f7f7f7;
$es-theme-controls-search-cell-hover-divider-secondary-color: #d5d6d8;
$es-theme-controls-search-cell-hover-icon-primary-color: #2e7d32;
$es-theme-controls-search-cell-hover-icon-secondary-color: #4b6f98;
$es-theme-controls-search-cell-disabled-bg-primary-color: #f7f7f7;
$es-theme-controls-search-cell-disabled-bg-secondary-color: #e5e5e6;
$es-theme-controls-search-cell-disabled-bg-tertiary-color: #eff0f0;
$es-theme-controls-search-cell-disabled-text-label-color: #828282;
$es-theme-controls-search-cell-disabled-text-body-color: #828282;
$es-theme-controls-search-cell-disabled-text-trailing-color: #828282;
$es-theme-controls-search-cell-disabled-divider-primary-color: #f7f7f7;
$es-theme-controls-search-cell-disabled-divider-secondary-color: #d5d6d8;
$es-theme-controls-search-cell-disabled-icon-primary-color: #4b6f98;
$es-theme-controls-search-cell-disabled-icon-secondary-color: #4b6f98;
$es-theme-controls-file-upload-color: #bdbdbd;
$es-theme-controls-file-upload-width: 1;
$es-theme-controls-file-upload-style: dashed;
$es-theme-on-surface-attention30-color: #ff963f4d;
$es-theme-on-surface-shades20-color: #4b6f9833;
$es-theme-on-surface-error20-color: #ff270680;
$es-theme-on-surface-text-primary-color: #292b2d;
$es-theme-on-surface-text-secondary-color: #ef6c00;
$es-theme-on-surface-text-gray-color: #828282;
$es-theme-on-surface-text-gray-light-color: #c1c2c3;
$es-theme-on-surface-text-primary-accent-color: #2e7d32;
$es-theme-on-surface-text-inverse-color: #fff;
$es-theme-on-surface-text-inverse-reserve-color: #ebebeb;
$es-theme-on-surface-text-shades-color: #d5d6d8;
$es-theme-on-surface-text-error-color: #ff2706;
$es-theme-on-surface-text-attention-color: #f28933;
$es-theme-on-surface-text-success-color: #58975b;
$es-theme-on-surface-text-link-primary-color: #6fa1d9;
$es-theme-on-surface-text-link-secondary-color: #ef6c00;
$es-theme-on-surface-text-white-everywhere-color: #fff;
$es-theme-on-surface-text-black-everywhere-color: #121416;
$es-theme-on-surface-main-lvl1-color: #fff;
$es-theme-on-surface-main-lvl2-color: #f7f7f7;
$es-theme-on-surface-main-lvl3-color: #eff0f0;
$es-theme-on-surface-main-lvl4-color: #ebebeb;
$es-theme-on-surface-main-lvl5-color: #e5e5e6;
$es-theme-on-surface-main-lvl6-color: #d5d6d8;
$es-theme-on-surface-gray-color: #828282;
$es-theme-on-surface-gray-light-color: #c1c2c3;
$es-theme-on-surface-shades-color: #d5d6d8;
$es-theme-on-surface-primary-color: #2e7d32;
$es-theme-on-surface-secondary-color: #ff9137;
$es-theme-on-surface-success-color: #58975b;
$es-theme-on-surface-error-color: #ff4d4d;
$es-theme-on-surface-attention-color: #ef6c00;
$es-theme-on-surface-inverse-lvl1-color: #1a1c1e;
$es-theme-on-surface-inverse-lvl2-color: #1f2123;
$es-theme-on-surface-inverse-lvl3-color: #292b2d;
$es-theme-on-surface-inverse-lvl4-color: #2f3032;
$es-theme-on-surface-inverse-lvl5-color: #373839;
$es-theme-on-surface-inverse-lvl6-color: #464749;
$es-theme-on-surface-primary-lvl1-color: #2e7d321a;
$es-theme-on-surface-primary-lvl2-color: #2e7d3233;
$es-theme-on-surface-primary-lvl3-color: #2e7d3266;
$es-theme-on-surface-white-everywhere-color: #fff;
$es-theme-on-surface-black-everywhere-lvl1-color: #000;
$es-theme-on-surface-black-everywhere-lvl2-color: #121416;
$es-theme-on-surface-black-everywhere-lvl3-color: #1f2123;
$es-theme-on-surface-background-main-bg-color: #eff0f0;
$es-theme-on-surface-background-0-color: #d5d6d8;
$es-theme-on-surface-background-inverse-bg-color: #121416;
$es-theme-on-surface-background-inverse0-color: #000;
$es-theme-on-surface-background-primary-color: #2e7d32;
$es-theme-on-surface-gradient-neutral-bg-color: linear-gradient(
  0deg,
  #373839 0%,
  #2f3032 100%
);
$es-theme-on-surface-gradient-error-color: linear-gradient(
  180deg,
  #f9f9f900 0%,
  #ff270666 100%
);
$es-theme-on-surface-divider-primary-color: #2e7d32;
$es-theme-on-surface-divider-secondary-color: #3c3d3e;
$es-theme-on-surface-divider-tertiary-color: #bdbdbd;
$es-theme-on-surface-divider-gray-color: #666666;
$es-theme-on-surface-divider-shades-color: #d5d6d8;
$es-theme-on-surface-divider-white-everywhere-color: #fff;
$es-theme-on-surface-divider-success-color: #58975b;
$es-theme-on-surface-divider-error-color: #ff2706;
$es-theme-on-surface-divider-main-lvl1-color: #fff;
$es-theme-on-surface-divider-main-lvl2-color: #f7f7f7;
$es-theme-on-surface-divider-main-lvl3-color: #eff0f0;
$es-theme-on-surface-divider-main-lvl4-color: #ebebeb;
$es-theme-on-surface-divider-main-lvl5-color: #e5e5e6;
$es-theme-on-surface-divider-main-lvl6-color: #d5d6d8;
$es-theme-on-surface-divider-height-s-sizing: 1;
$es-theme-on-surface-divider-height-m-sizing: 2;
$es-theme-on-surface-divider-height-l-sizing: 4;
$es-theme-on-surface-divider-width-s-sizing: 1;
$es-theme-on-surface-divider-width-m-sizing: 2;
$es-theme-on-surface-divider-width-l-sizing: 4;
$es-theme-on-surface-icon-primary-color: #2e7d32;
$es-theme-on-surface-icon-secondary-color: #e86612;
$es-theme-on-surface-icon-inverse-color: #fff;
$es-theme-on-surface-icon-shades-color: #4b6f98;
$es-theme-on-surface-icon-shades-secondary-color: #4b6f98;
$es-theme-on-surface-icon-gray-color: #666666;
$es-theme-on-surface-icon-gray-light-color: #828282;
$es-theme-on-surface-icon-tertiary-color: #c1c2c3;
$es-theme-on-surface-icon-main-bg-color: #eff0f0;
$es-theme-on-surface-icon-inverse-bg-color: #121416;
$es-theme-on-surface-icon-error-color: #ff2706;
$es-theme-on-surface-icon-attention-color: #f28933;
$es-theme-on-surface-icon-success-color: #58975b;
$es-theme-on-surface-icon-white-everywhere-color: #fff;
$es-theme-on-surface-border-main-lvl1-color: #fff;
$es-theme-on-surface-border-main-lvl2-color: #f7f7f7;
$es-theme-on-surface-border-main-lvl3-color: #eff0f0;
$es-theme-on-surface-border-main-lvl4-color: #ebebeb;
$es-theme-on-surface-border-main-lvl5-color: #e5e5e6;
$es-theme-on-surface-border-main-lvl6-color: #d5d6d8;
$es-theme-on-surface-border-gray-color: #828282;
$es-theme-on-surface-border-gray-light-color: #c1c2c3;
$es-theme-on-surface-border-shades-color: #d5d6d8;
$es-theme-on-surface-border-primary-color: #2e7d32;
$es-theme-on-surface-border-secondary-color: #ff9137;
$es-theme-on-surface-border-primary-lvl1-color: #2e7d321a;
$es-theme-on-surface-border-primary-lvl2-color: #2e7d3233;
$es-theme-on-surface-border-primary-lvl3-color: #2e7d3266;
$es-theme-on-surface-border-disabled-color: #c1c2c3;
$es-theme-on-surface-border-success-color: #58975b;
$es-theme-on-surface-border-error-color: #ff4d4d;
$es-theme-on-surface-border-attention-color: #ef6c00;
$es-theme-on-surface-border-inverse-lvl1-color: #1a1c1e;
$es-theme-on-surface-border-inverse-lvl2-color: #1f2123;
$es-theme-on-surface-border-inverse-lvl3-color: #292b2d;
$es-theme-on-surface-border-inverse-lvl4-color: #2f3032;
$es-theme-on-surface-border-inverse-lvl5-color: #373839;
$es-theme-on-surface-border-inverse-lvl6-color: #464749;
$es-theme-on-surface-mobile-header-box-shadow-color: #00000014;
$es-theme-on-surface-mobile-header-box-shadow-type: dropShadow;
$es-theme-on-surface-mobile-header-box-shadow-x: 0;
$es-theme-on-surface-mobile-header-box-shadow-y: 4;
$es-theme-on-surface-mobile-header-box-shadow-blur: 8;
$es-theme-on-surface-mobile-header-box-shadow-spread: 0;
$es-theme-on-surface-mobile-footer-box-shadow-color: #0000000f;
$es-theme-on-surface-mobile-footer-box-shadow-type: dropShadow;
$es-theme-on-surface-mobile-footer-box-shadow-x: 0;
$es-theme-on-surface-mobile-footer-box-shadow-y: -4;
$es-theme-on-surface-mobile-footer-box-shadow-blur: 8;
$es-theme-on-surface-mobile-footer-box-shadow-spread: 0;
$es-theme-on-surface-card-xxxs-box-shadow-x: 0;
$es-theme-on-surface-card-xxxs-box-shadow-y: 1;
$es-theme-on-surface-card-xxxs-box-shadow-blur: 2;
$es-theme-on-surface-card-xxxs-box-shadow-spread: 0;
$es-theme-on-surface-card-xxxs-box-shadow-color: #00000040;
$es-theme-on-surface-card-xxxs-box-shadow-type: dropShadow;
$es-theme-on-surface-card-l-box-shadow-x: 0;
$es-theme-on-surface-card-l-box-shadow-y: 6;
$es-theme-on-surface-card-l-box-shadow-blur: 15;
$es-theme-on-surface-card-l-box-shadow-spread: 0;
$es-theme-on-surface-card-l-box-shadow-color: #0000001f;
$es-theme-on-surface-card-l-box-shadow-type: dropShadow;
$es-theme-on-surface-color-header-box-shadow-x: 0;
$es-theme-on-surface-color-header-box-shadow-y: 10;
$es-theme-on-surface-color-header-box-shadow-blur: 30;
$es-theme-on-surface-color-header-box-shadow-spread: 0;
$es-theme-on-surface-color-header-box-shadow-color: #81ff8d14;
$es-theme-on-surface-color-header-box-shadow-type: dropShadow;
$es-theme-on-surface-height-6-xs-spacing: 4/4;
$es-theme-on-surface-height-5-xs-spacing: 4/2;
$es-theme-on-surface-height-xxxxs-spacing: 4;
$es-theme-on-surface-height-xxxs-spacing: 4 * 2;
$es-theme-on-surface-height-xxs-spacing: 4 * 3;
$es-theme-on-surface-height-xs-spacing: 4 * 4;
$es-theme-on-surface-height-s-spacing: 4 * 5;
$es-theme-on-surface-height-m-spacing: 4 * 6;
$es-theme-on-surface-height-l-spacing: 4 * 7;
$es-theme-on-surface-height-xl-spacing: 4 * 8;
$es-theme-on-surface-height-xxl-spacing: 4 * 9;
$es-theme-on-surface-height-xxxl-spacing: 4 * 10;
$es-theme-on-surface-height-xxxxl-spacing: 4 * 11;
$es-theme-on-surface-height-5-xl-spacing: 4 * 12;
$es-theme-on-surface-height-6-xs-sizing: 1;
$es-theme-on-surface-height-5-xs-sizing: 2;
$es-theme-on-surface-height-xxxxs-sizing: 4;
$es-theme-on-surface-height-xxxs-sizing: 4 * 2;
$es-theme-on-surface-height-xxs-sizing: 4 * 3;
$es-theme-on-surface-height-xs-sizing: 4 * 4;
$es-theme-on-surface-height-s-sizing: 4 * 5;
$es-theme-on-surface-height-m-sizing: 4 * 6;
$es-theme-on-surface-height-l-sizing: 4 * 7;
$es-theme-on-surface-height-xl-sizing: 4 * 8;
$es-theme-on-surface-height-xxl-sizing: 4 * 9;
$es-theme-on-surface-height-xxxl-sizing: 4 * 10;
$es-theme-on-surface-height-xxxxl-sizing: 4 * 11;
$es-theme-on-surface-height-5-xl-sizing: 4 * 12;
$es-theme-on-surface-height-6-xl-sizing: 4 * 13;
$es-theme-on-surface-height-7-xl-sizing: 4 * 14;
$es-theme-on-surface-height-8-xl-sizing: 4 * 15;
$es-theme-on-surface-height-9-xl-sizing: 4 * 16;
$es-theme-on-surface-height-10-xl-sizing: 4 * 17;
$es-theme-on-surface-height-11-xl-sizing: 4 * 18;
$es-theme-on-surface-height-12-xl-sizing: 4 * 19;
$es-theme-on-surface-height-13-xl-sizing: 4 * 20;
$es-theme-on-surface-height-14-xl-sizing: 4 * 21;
$es-theme-on-surface-height-15-xl-sizing: 4 * 22;
$es-theme-on-surface-height-16-xl-sizing: 4 * 23;
$es-theme-on-surface-height-17-xl-sizing: 4 * 24;
$es-theme-on-surface-height-18-xl-sizing: 4 * 25;
$es-theme-on-surface-height-19-xl-sizing: 4 * 26;
$es-theme-on-surface-height-20-xl-sizing: 4 * 27;
$es-theme-on-surface-height-21-xl-sizing: 4 * 28;
$es-theme-on-surface-height-22-xl-sizing: 4 * 29;
$es-theme-on-surface-height-23-xl-sizing: 4 * 30;
$es-theme-on-surface-height-24-xl-sizing: 4 * 31;
$es-theme-on-surface-height-25-xl-sizing: 4 * 32;
$es-theme-on-surface-height-26-xl-sizing: 4 * 33;
$es-theme-on-surface-height-27-xl-sizing: 4 * 34;
$es-theme-on-surface-height-28-xl-sizing: 4 * 35;
$es-theme-on-surface-height-29-xl-sizing: 4 * 36;
$es-theme-on-surface-height-30-xl-sizing: 4 * 37;
$es-theme-on-surface-height-31-xl-sizing: 4 * 38;
$es-theme-on-surface-height-32-xl-sizing: 4 * 39;
$es-theme-on-surface-height-33-xl-sizing: 4 * 40;
$es-theme-on-surface-height-34-xl-sizing: 4 * 41;
$es-theme-on-surface-height-35-xl-sizing: 4 * 42;
$es-theme-on-surface-height-36-xl-sizing: 4 * 43;
$es-theme-on-surface-height-37-xl-sizing: 4 * 44;
$es-theme-on-surface-height-38-xl-sizing: 4 * 45;
$es-theme-on-surface-height-39-xl-sizing: 4 * 46;
$es-theme-on-surface-height-40-xl-sizing: 4 * 47;
$es-theme-on-surface-height-41-xl-sizing: 4 * 48;
$es-theme-on-surface-height-42-xl-sizing: 4 * 49;
$es-theme-on-surface-height-43-xl-sizing: 4 * 50;
$es-theme-on-surface-height-44-xl-sizing: 4 * 51;
$es-theme-on-surface-height-45-xl-sizing: 4 * 52;
$es-theme-on-surface-height-46-xl-sizing: 4 * 53;
$es-theme-on-surface-height-47-xl-sizing: 4 * 54;
$es-theme-on-surface-height-48-xl-sizing: 4 * 55;
$es-theme-on-surface-height-49-xl-sizing: 4 * 56;
$es-theme-on-surface-height-50-xl-sizing: 4 * 57;
$es-theme-on-surface-gap-6-xs-spacing: 4/4;
$es-theme-on-surface-gap-5-xs-spacing: 4/2;
$es-theme-on-surface-gap-xxxxs-spacing: 4;
$es-theme-on-surface-gap-xxxs-spacing: 4 * 2;
$es-theme-on-surface-gap-xxs-spacing: 4 * 3;
$es-theme-on-surface-gap-xs-spacing: 4 * 4;
$es-theme-on-surface-gap-s-spacing: 4 * 5;
$es-theme-on-surface-gap-m-spacing: 4 * 6;
$es-theme-on-surface-gap-l-spacing: 4 * 7;
$es-theme-on-surface-gap-xl-spacing: 4 * 8;
$es-theme-on-surface-gap-xxl-spacing: 4 * 9;
$es-theme-on-surface-gap-xxxl-spacing: 4 * 10;
$es-theme-on-surface-gap-xxxxl-spacing: 4 * 11;
$es-theme-on-surface-gap-5-xl-spacing: 4 * 12;
$es-theme-on-surface-width-6-xs-spacing: 4/4;
$es-theme-on-surface-width-5-xs-spacing: 4/2;
$es-theme-on-surface-width-xxxxs-spacing: 4;
$es-theme-on-surface-width-xxxs-spacing: 4 * 2;
$es-theme-on-surface-width-xxs-spacing: 4 * 3;
$es-theme-on-surface-width-xs-spacing: 4 * 4;
$es-theme-on-surface-width-s-spacing: 4 * 5;
$es-theme-on-surface-width-m-spacing: 4 * 6;
$es-theme-on-surface-width-l-spacing: 4 * 7;
$es-theme-on-surface-width-xl-spacing: 4 * 8;
$es-theme-on-surface-width-xxl-spacing: 4 * 9;
$es-theme-on-surface-width-xxxl-spacing: 4 * 10;
$es-theme-on-surface-width-xxxxl-spacing: 4 * 11;
$es-theme-on-surface-width-5-xl-spacing: 4 * 12;
$es-theme-on-surface-width-83-xl-spacing: 4 * 90;
$es-theme-on-surface-width-6-xs-sizing: 1;
$es-theme-on-surface-width-5-xs-sizing: 2;
$es-theme-on-surface-width-xxxxs-sizing: 4;
$es-theme-on-surface-width-xxxs-sizing: 4 * 2;
$es-theme-on-surface-width-xxs-sizing: 4 * 3;
$es-theme-on-surface-width-xs-sizing: 4 * 4;
$es-theme-on-surface-width-s-sizing: 4 * 5;
$es-theme-on-surface-width-m-sizing: 4 * 6;
$es-theme-on-surface-width-l-sizing: 4 * 7;
$es-theme-on-surface-width-xl-sizing: 4 * 8;
$es-theme-on-surface-width-xxl-sizing: 4 * 9;
$es-theme-on-surface-width-xxxl-sizing: 4 * 10;
$es-theme-on-surface-width-xxxxl-sizing: 4 * 11;
$es-theme-on-surface-width-5-xl-sizing: 4 * 12;
$es-theme-on-surface-width-6-xl-sizing: 413;
$es-theme-on-surface-width-7-xl-sizing: 4 * 14;
$es-theme-on-surface-width-8-xl-sizing: 4 * 15;
$es-theme-on-surface-width-9-xl-sizing: 4 * 16;
$es-theme-on-surface-width-10-xl-sizing: 4 * 17;
$es-theme-on-surface-width-11-xl-sizing: 4 * 18;
$es-theme-on-surface-width-12-xl-sizing: 4 * 19;
$es-theme-on-surface-width-13-xl-sizing: 4 * 20;
$es-theme-on-surface-width-14-xl-sizing: 4 * 21;
$es-theme-on-surface-width-15-xl-sizing: 4 * 22;
$es-theme-on-surface-width-16-xl-sizing: 4 * 23;
$es-theme-on-surface-width-17-xl-sizing: 4 * 24;
$es-theme-on-surface-width-18-xl-sizing: 4 * 25;
$es-theme-on-surface-width-19-xl-sizing: 4 * 26;
$es-theme-on-surface-width-20-xl-sizing: 4 * 27;
$es-theme-on-surface-width-21-xl-sizing: 4 * 28;
$es-theme-on-surface-width-22-xl-sizing: 4 * 29;
$es-theme-on-surface-width-23-xl-sizing: 4 * 30;
$es-theme-on-surface-width-24-xl-sizing: 4 * 31;
$es-theme-on-surface-width-25-xl-sizing: 4 * 32;
$es-theme-on-surface-width-26-xl-sizing: 4 * 33;
$es-theme-on-surface-width-27-xl-sizing: 4 * 34;
$es-theme-on-surface-width-28-xl-sizing: 4 * 35;
$es-theme-on-surface-width-29-xl-sizing: 4 * 36;
$es-theme-on-surface-width-30-xl-sizing: 4 * 37;
$es-theme-on-surface-width-31-xl-sizing: 4 * 38;
$es-theme-on-surface-width-32-xl-sizing: 4 * 39;
$es-theme-on-surface-width-33-xl-sizing: 4 * 40;
$es-theme-on-surface-width-34-xl-sizing: 4 * 41;
$es-theme-on-surface-width-35-xl-sizing: 4 * 42;
$es-theme-on-surface-width-36-xl-sizing: 4 * 43;
$es-theme-on-surface-width-37-xl-sizing: 4 * 44;
$es-theme-on-surface-width-38-xl-sizing: 4 * 45;
$es-theme-on-surface-width-39-xl-sizing: 4 * 46;
$es-theme-on-surface-width-40-xl-sizing: 4 * 47;
$es-theme-on-surface-width-41-xl-sizing: 4 * 48;
$es-theme-on-surface-width-42-xl-sizing: 4 * 49;
$es-theme-on-surface-width-43-xl-sizing: 4 * 50;
$es-theme-on-surface-width-44-xl-sizing: 4 * 51;
$es-theme-on-surface-width-45-xl-sizing: 4 * 52;
$es-theme-on-surface-width-46-xl-sizing: 4 * 53;
$es-theme-on-surface-width-47-xl-sizing: 4 * 54;
$es-theme-on-surface-width-48-xl-sizing: 4 * 55;
$es-theme-on-surface-width-49-xl-sizing: 4 * 56;
$es-theme-on-surface-width-50-xl-sizing: 4 * 57;
$es-theme-on-surface-s-border-radius: 4;
$es-theme-on-surface-m-border-radius: 4 * 2;
$es-theme-on-surface-p6-300-typography-font-family: Open Sans;
$es-theme-on-surface-p6-300-typography-font-weight: Light;
$es-theme-on-surface-p6-300-typography-line-height: 170%;
$es-theme-on-surface-p6-300-typography-font-size: 10;
$es-theme-on-surface-p6-300-typography-letter-spacing: 0;
$es-theme-on-surface-p6-300-typography-paragraph-spacing: none;
$es-theme-on-surface-p6-300-typography-text-case: none;
$es-theme-on-surface-p6-300-typography-text-decoration: none;
$es-theme-on-surface-p6-400-typography-font-family: Open Sans;
$es-theme-on-surface-p6-400-typography-font-weight: Regular;
$es-theme-on-surface-p6-400-typography-line-height: 170%;
$es-theme-on-surface-p6-400-typography-font-size: 10;
$es-theme-on-surface-p6-400-typography-letter-spacing: 0;
$es-theme-on-surface-p6-400-typography-paragraph-spacing: none;
$es-theme-on-surface-p6-400-typography-text-case: none;
$es-theme-on-surface-p6-400-typography-text-decoration: none;
$es-theme-on-surface-p6-500-typography-font-family: Open Sans;
$es-theme-on-surface-p6-500-typography-font-weight: Medium;
$es-theme-on-surface-p6-500-typography-line-height: 170%;
$es-theme-on-surface-p6-500-typography-font-size: 12;
$es-theme-on-surface-p6-500-typography-letter-spacing: 0;
$es-theme-on-surface-p6-500-typography-paragraph-spacing: none;
$es-theme-on-surface-p6-500-typography-text-case: none;
$es-theme-on-surface-p6-500-typography-text-decoration: none;
$es-theme-on-surface-p6-600-typography-font-family: Open Sans;
$es-theme-on-surface-p6-600-typography-font-weight: SemiBold;
$es-theme-on-surface-p6-600-typography-line-height: 170%;
$es-theme-on-surface-p6-600-typography-font-size: 10;
$es-theme-on-surface-p6-600-typography-letter-spacing: 0;
$es-theme-on-surface-p6-600-typography-paragraph-spacing: none;
$es-theme-on-surface-p6-600-typography-text-case: none;
$es-theme-on-surface-p6-600-typography-text-decoration: none;
$es-theme-on-surface-p6-700-typography-font-family: Open Sans;
$es-theme-on-surface-p6-700-typography-font-weight: Bold;
$es-theme-on-surface-p6-700-typography-line-height: 170%;
$es-theme-on-surface-p6-700-typography-font-size: 10;
$es-theme-on-surface-p6-700-typography-letter-spacing: 0;
$es-theme-on-surface-p6-700-typography-paragraph-spacing: none;
$es-theme-on-surface-p6-700-typography-text-case: none;
$es-theme-on-surface-p6-700-typography-text-decoration: none;
$es-theme-on-surface-p6-800-typography-font-family: Open Sans;
$es-theme-on-surface-p6-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-p6-800-typography-line-height: 170%;
$es-theme-on-surface-p6-800-typography-font-size: 10;
$es-theme-on-surface-p6-800-typography-letter-spacing: 0;
$es-theme-on-surface-p6-800-typography-paragraph-spacing: none;
$es-theme-on-surface-p6-800-typography-text-case: none;
$es-theme-on-surface-p6-800-typography-text-decoration: none;
$es-theme-on-surface-p5-300-typography-font-family: Open Sans;
$es-theme-on-surface-p5-300-typography-font-weight: Light;
$es-theme-on-surface-p5-300-typography-line-height: 170%;
$es-theme-on-surface-p5-300-typography-font-size: 12;
$es-theme-on-surface-p5-300-typography-letter-spacing: 0;
$es-theme-on-surface-p5-300-typography-paragraph-spacing: none;
$es-theme-on-surface-p5-300-typography-text-case: none;
$es-theme-on-surface-p5-300-typography-text-decoration: none;
$es-theme-on-surface-p5-400-typography-font-family: Open Sans;
$es-theme-on-surface-p5-400-typography-font-weight: Regular;
$es-theme-on-surface-p5-400-typography-line-height: 170%;
$es-theme-on-surface-p5-400-typography-font-size: 12;
$es-theme-on-surface-p5-400-typography-letter-spacing: 0;
$es-theme-on-surface-p5-400-typography-paragraph-spacing: none;
$es-theme-on-surface-p5-400-typography-text-case: none;
$es-theme-on-surface-p5-400-typography-text-decoration: none;
$es-theme-on-surface-p5-500-typography-font-family: Open Sans;
$es-theme-on-surface-p5-500-typography-font-weight: Medium;
$es-theme-on-surface-p5-500-typography-line-height: 170%;
$es-theme-on-surface-p5-500-typography-font-size: 12;
$es-theme-on-surface-p5-500-typography-letter-spacing: 0;
$es-theme-on-surface-p5-500-typography-paragraph-spacing: none;
$es-theme-on-surface-p5-500-typography-text-case: none;
$es-theme-on-surface-p5-500-typography-text-decoration: none;
$es-theme-on-surface-p5-600-typography-font-family: Open Sans;
$es-theme-on-surface-p5-600-typography-font-weight: SemiBold;
$es-theme-on-surface-p5-600-typography-line-height: 170%;
$es-theme-on-surface-p5-600-typography-font-size: 12;
$es-theme-on-surface-p5-600-typography-letter-spacing: 0;
$es-theme-on-surface-p5-600-typography-paragraph-spacing: none;
$es-theme-on-surface-p5-600-typography-text-case: none;
$es-theme-on-surface-p5-600-typography-text-decoration: none;
$es-theme-on-surface-p5-700-typography-font-family: Open Sans;
$es-theme-on-surface-p5-700-typography-font-weight: Bold;
$es-theme-on-surface-p5-700-typography-line-height: 170%;
$es-theme-on-surface-p5-700-typography-font-size: 12;
$es-theme-on-surface-p5-700-typography-letter-spacing: 0;
$es-theme-on-surface-p5-700-typography-paragraph-spacing: none;
$es-theme-on-surface-p5-700-typography-text-case: none;
$es-theme-on-surface-p5-700-typography-text-decoration: none;
$es-theme-on-surface-p5-800-typography-font-family: Open Sans;
$es-theme-on-surface-p5-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-p5-800-typography-line-height: 170%;
$es-theme-on-surface-p5-800-typography-font-size: 12;
$es-theme-on-surface-p5-800-typography-letter-spacing: 0;
$es-theme-on-surface-p5-800-typography-paragraph-spacing: none;
$es-theme-on-surface-p5-800-typography-text-case: none;
$es-theme-on-surface-p5-800-typography-text-decoration: none;
$es-theme-on-surface-p4-300-typography-font-family: Open Sans;
$es-theme-on-surface-p4-300-typography-font-weight: Light;
$es-theme-on-surface-p4-300-typography-line-height: 170%;
$es-theme-on-surface-p4-300-typography-font-size: 14;
$es-theme-on-surface-p4-300-typography-letter-spacing: 0;
$es-theme-on-surface-p4-300-typography-paragraph-spacing: none;
$es-theme-on-surface-p4-300-typography-text-case: none;
$es-theme-on-surface-p4-300-typography-text-decoration: none;
$es-theme-on-surface-p4-400-typography-font-family: Open Sans;
$es-theme-on-surface-p4-400-typography-font-weight: Regular;
$es-theme-on-surface-p4-400-typography-line-height: 170%;
$es-theme-on-surface-p4-400-typography-font-size: 14;
$es-theme-on-surface-p4-400-typography-letter-spacing: 0;
$es-theme-on-surface-p4-400-typography-paragraph-spacing: none;
$es-theme-on-surface-p4-400-typography-text-case: none;
$es-theme-on-surface-p4-400-typography-text-decoration: none;
$es-theme-on-surface-p4-500-typography-font-family: Open Sans;
$es-theme-on-surface-p4-500-typography-font-weight: Medium;
$es-theme-on-surface-p4-500-typography-line-height: 170%;
$es-theme-on-surface-p4-500-typography-font-size: 14;
$es-theme-on-surface-p4-500-typography-letter-spacing: 0;
$es-theme-on-surface-p4-500-typography-paragraph-spacing: none;
$es-theme-on-surface-p4-500-typography-text-case: none;
$es-theme-on-surface-p4-500-typography-text-decoration: none;
$es-theme-on-surface-p4-600-typography-font-family: Open Sans;
$es-theme-on-surface-p4-600-typography-font-weight: SemiBold;
$es-theme-on-surface-p4-600-typography-line-height: 170%;
$es-theme-on-surface-p4-600-typography-font-size: 14;
$es-theme-on-surface-p4-600-typography-letter-spacing: 0;
$es-theme-on-surface-p4-600-typography-paragraph-spacing: none;
$es-theme-on-surface-p4-600-typography-text-case: none;
$es-theme-on-surface-p4-600-typography-text-decoration: none;
$es-theme-on-surface-p4-700-typography-font-family: Open Sans;
$es-theme-on-surface-p4-700-typography-font-weight: Bold;
$es-theme-on-surface-p4-700-typography-line-height: 170%;
$es-theme-on-surface-p4-700-typography-font-size: 14;
$es-theme-on-surface-p4-700-typography-letter-spacing: 0;
$es-theme-on-surface-p4-700-typography-paragraph-spacing: none;
$es-theme-on-surface-p4-700-typography-text-case: none;
$es-theme-on-surface-p4-700-typography-text-decoration: none;
$es-theme-on-surface-p4-800-typography-font-family: Open Sans;
$es-theme-on-surface-p4-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-p4-800-typography-line-height: 170%;
$es-theme-on-surface-p4-800-typography-font-size: 14;
$es-theme-on-surface-p4-800-typography-letter-spacing: 0;
$es-theme-on-surface-p4-800-typography-paragraph-spacing: none;
$es-theme-on-surface-p4-800-typography-text-case: none;
$es-theme-on-surface-p4-800-typography-text-decoration: none;
$es-theme-on-surface-p3-300-typography-font-family: Open Sans;
$es-theme-on-surface-p3-300-typography-font-weight: Light;
$es-theme-on-surface-p3-300-typography-line-height: 170%;
$es-theme-on-surface-p3-300-typography-font-size: 15;
$es-theme-on-surface-p3-300-typography-letter-spacing: 0;
$es-theme-on-surface-p3-300-typography-paragraph-spacing: none;
$es-theme-on-surface-p3-300-typography-text-case: none;
$es-theme-on-surface-p3-300-typography-text-decoration: none;
$es-theme-on-surface-p3-400-typography-font-family: Open Sans;
$es-theme-on-surface-p3-400-typography-font-weight: Regular;
$es-theme-on-surface-p3-400-typography-line-height: 170%;
$es-theme-on-surface-p3-400-typography-font-size: 15;
$es-theme-on-surface-p3-400-typography-letter-spacing: 0;
$es-theme-on-surface-p3-400-typography-paragraph-spacing: none;
$es-theme-on-surface-p3-400-typography-text-case: none;
$es-theme-on-surface-p3-400-typography-text-decoration: none;
$es-theme-on-surface-p3-500-typography-font-family: Open Sans;
$es-theme-on-surface-p3-500-typography-font-weight: Medium;
$es-theme-on-surface-p3-500-typography-line-height: 170%;
$es-theme-on-surface-p3-500-typography-font-size: 15;
$es-theme-on-surface-p3-500-typography-letter-spacing: 0;
$es-theme-on-surface-p3-500-typography-paragraph-spacing: none;
$es-theme-on-surface-p3-500-typography-text-case: none;
$es-theme-on-surface-p3-500-typography-text-decoration: none;
$es-theme-on-surface-p3-600-typography-font-family: Open Sans;
$es-theme-on-surface-p3-600-typography-font-weight: SemiBold;
$es-theme-on-surface-p3-600-typography-line-height: 170%;
$es-theme-on-surface-p3-600-typography-font-size: 15;
$es-theme-on-surface-p3-600-typography-letter-spacing: 0;
$es-theme-on-surface-p3-600-typography-paragraph-spacing: none;
$es-theme-on-surface-p3-600-typography-text-case: none;
$es-theme-on-surface-p3-600-typography-text-decoration: none;
$es-theme-on-surface-p3-700-typography-font-family: Open Sans;
$es-theme-on-surface-p3-700-typography-font-weight: Bold;
$es-theme-on-surface-p3-700-typography-line-height: 170%;
$es-theme-on-surface-p3-700-typography-font-size: 15;
$es-theme-on-surface-p3-700-typography-letter-spacing: 0;
$es-theme-on-surface-p3-700-typography-paragraph-spacing: none;
$es-theme-on-surface-p3-700-typography-text-case: none;
$es-theme-on-surface-p3-700-typography-text-decoration: none;
$es-theme-on-surface-p3-800-typography-font-family: Open Sans;
$es-theme-on-surface-p3-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-p3-800-typography-line-height: 170%;
$es-theme-on-surface-p3-800-typography-font-size: 15;
$es-theme-on-surface-p3-800-typography-letter-spacing: 0;
$es-theme-on-surface-p3-800-typography-paragraph-spacing: none;
$es-theme-on-surface-p3-800-typography-text-case: none;
$es-theme-on-surface-p3-800-typography-text-decoration: none;
$es-theme-on-surface-p2-300-typography-font-family: Open Sans;
$es-theme-on-surface-p2-300-typography-font-weight: Light;
$es-theme-on-surface-p2-300-typography-line-height: 170%;
$es-theme-on-surface-p2-300-typography-font-size: 18;
$es-theme-on-surface-p2-300-typography-letter-spacing: 0;
$es-theme-on-surface-p2-300-typography-paragraph-spacing: none;
$es-theme-on-surface-p2-300-typography-text-case: none;
$es-theme-on-surface-p2-300-typography-text-decoration: none;
$es-theme-on-surface-p2-400-typography-font-family: Open Sans;
$es-theme-on-surface-p2-400-typography-font-weight: Regular;
$es-theme-on-surface-p2-400-typography-line-height: 160%;
$es-theme-on-surface-p2-400-typography-font-size: 18;
$es-theme-on-surface-p2-400-typography-letter-spacing: 0;
$es-theme-on-surface-p2-400-typography-paragraph-spacing: none;
$es-theme-on-surface-p2-400-typography-text-case: none;
$es-theme-on-surface-p2-400-typography-text-decoration: none;
$es-theme-on-surface-p2-500-typography-font-family: Open Sans;
$es-theme-on-surface-p2-500-typography-font-weight: Medium;
$es-theme-on-surface-p2-500-typography-line-height: 160%;
$es-theme-on-surface-p2-500-typography-font-size: 18;
$es-theme-on-surface-p2-500-typography-letter-spacing: 0;
$es-theme-on-surface-p2-500-typography-paragraph-spacing: none;
$es-theme-on-surface-p2-500-typography-text-case: none;
$es-theme-on-surface-p2-500-typography-text-decoration: none;
$es-theme-on-surface-p2-600-typography-font-family: Open Sans;
$es-theme-on-surface-p2-600-typography-font-weight: SemiBold;
$es-theme-on-surface-p2-600-typography-line-height: 170%;
$es-theme-on-surface-p2-600-typography-font-size: 18;
$es-theme-on-surface-p2-600-typography-letter-spacing: 0;
$es-theme-on-surface-p2-600-typography-paragraph-spacing: none;
$es-theme-on-surface-p2-600-typography-text-case: none;
$es-theme-on-surface-p2-600-typography-text-decoration: none;
$es-theme-on-surface-p2-700-typography-font-family: Open Sans;
$es-theme-on-surface-p2-700-typography-font-weight: Bold;
$es-theme-on-surface-p2-700-typography-line-height: 170%;
$es-theme-on-surface-p2-700-typography-font-size: 18;
$es-theme-on-surface-p2-700-typography-letter-spacing: 0;
$es-theme-on-surface-p2-700-typography-paragraph-spacing: none;
$es-theme-on-surface-p2-700-typography-text-case: none;
$es-theme-on-surface-p2-700-typography-text-decoration: none;
$es-theme-on-surface-p2-800-typography-font-family: Open Sans;
$es-theme-on-surface-p2-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-p2-800-typography-line-height: 170%;
$es-theme-on-surface-p2-800-typography-font-size: 18;
$es-theme-on-surface-p2-800-typography-letter-spacing: 0;
$es-theme-on-surface-p2-800-typography-paragraph-spacing: none;
$es-theme-on-surface-p2-800-typography-text-case: none;
$es-theme-on-surface-p2-800-typography-text-decoration: none;
$es-theme-on-surface-h5-300-typography-font-family: Open Sans;
$es-theme-on-surface-h5-300-typography-font-weight: Light;
$es-theme-on-surface-h5-300-typography-line-height: 170%;
$es-theme-on-surface-h5-300-typography-font-size: 20;
$es-theme-on-surface-h5-300-typography-letter-spacing: 0;
$es-theme-on-surface-h5-300-typography-paragraph-spacing: none;
$es-theme-on-surface-h5-300-typography-text-case: none;
$es-theme-on-surface-h5-300-typography-text-decoration: none;
$es-theme-on-surface-h5-400-typography-font-family: Open Sans;
$es-theme-on-surface-h5-400-typography-font-weight: Regular;
$es-theme-on-surface-h5-400-typography-line-height: 170%;
$es-theme-on-surface-h5-400-typography-font-size: 20;
$es-theme-on-surface-h5-400-typography-letter-spacing: 0;
$es-theme-on-surface-h5-400-typography-paragraph-spacing: none;
$es-theme-on-surface-h5-400-typography-text-case: none;
$es-theme-on-surface-h5-400-typography-text-decoration: none;
$es-theme-on-surface-h5-500-typography-font-family: Open Sans;
$es-theme-on-surface-h5-500-typography-font-weight: Medium;
$es-theme-on-surface-h5-500-typography-line-height: 170%;
$es-theme-on-surface-h5-500-typography-font-size: 20;
$es-theme-on-surface-h5-500-typography-letter-spacing: 0;
$es-theme-on-surface-h5-500-typography-paragraph-spacing: none;
$es-theme-on-surface-h5-500-typography-text-case: none;
$es-theme-on-surface-h5-500-typography-text-decoration: none;
$es-theme-on-surface-h5-600-typography-font-family: Open Sans;
$es-theme-on-surface-h5-600-typography-font-weight: SemiBold;
$es-theme-on-surface-h5-600-typography-line-height: 170%;
$es-theme-on-surface-h5-600-typography-font-size: 20;
$es-theme-on-surface-h5-600-typography-letter-spacing: 0;
$es-theme-on-surface-h5-600-typography-paragraph-spacing: none;
$es-theme-on-surface-h5-600-typography-text-case: none;
$es-theme-on-surface-h5-600-typography-text-decoration: none;
$es-theme-on-surface-h5-700-typography-font-family: Open Sans;
$es-theme-on-surface-h5-700-typography-font-weight: Bold;
$es-theme-on-surface-h5-700-typography-line-height: 170%;
$es-theme-on-surface-h5-700-typography-font-size: 20;
$es-theme-on-surface-h5-700-typography-letter-spacing: 0;
$es-theme-on-surface-h5-700-typography-paragraph-spacing: none;
$es-theme-on-surface-h5-700-typography-text-case: none;
$es-theme-on-surface-h5-700-typography-text-decoration: none;
$es-theme-on-surface-h5-800-typography-font-family: Open Sans;
$es-theme-on-surface-h5-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-h5-800-typography-line-height: 170%;
$es-theme-on-surface-h5-800-typography-font-size: 20;
$es-theme-on-surface-h5-800-typography-letter-spacing: 0;
$es-theme-on-surface-h5-800-typography-paragraph-spacing: none;
$es-theme-on-surface-h5-800-typography-text-case: none;
$es-theme-on-surface-h5-800-typography-text-decoration: none;
$es-theme-on-surface-p1-300-typography-font-family: Open Sans;
$es-theme-on-surface-p1-300-typography-font-weight: Light;
$es-theme-on-surface-p1-300-typography-line-height: 170%;
$es-theme-on-surface-p1-300-typography-font-size: 22;
$es-theme-on-surface-p1-300-typography-letter-spacing: 0;
$es-theme-on-surface-p1-300-typography-paragraph-spacing: none;
$es-theme-on-surface-p1-300-typography-text-case: none;
$es-theme-on-surface-p1-300-typography-text-decoration: none;
$es-theme-on-surface-p1-400-typography-font-family: Open Sans;
$es-theme-on-surface-p1-400-typography-font-weight: Regular;
$es-theme-on-surface-p1-400-typography-line-height: 170%;
$es-theme-on-surface-p1-400-typography-font-size: 22;
$es-theme-on-surface-p1-400-typography-letter-spacing: 0;
$es-theme-on-surface-p1-400-typography-paragraph-spacing: none;
$es-theme-on-surface-p1-400-typography-text-case: none;
$es-theme-on-surface-p1-400-typography-text-decoration: none;
$es-theme-on-surface-p1-500-typography-font-family: Open Sans;
$es-theme-on-surface-p1-500-typography-font-weight: Medium;
$es-theme-on-surface-p1-500-typography-line-height: 170%;
$es-theme-on-surface-p1-500-typography-font-size: 22;
$es-theme-on-surface-p1-500-typography-letter-spacing: 0;
$es-theme-on-surface-p1-500-typography-paragraph-spacing: none;
$es-theme-on-surface-p1-500-typography-text-case: none;
$es-theme-on-surface-p1-500-typography-text-decoration: none;
$es-theme-on-surface-p1-600-typography-font-family: Open Sans;
$es-theme-on-surface-p1-600-typography-font-weight: SemiBold;
$es-theme-on-surface-p1-600-typography-line-height: 170%;
$es-theme-on-surface-p1-600-typography-font-size: 22;
$es-theme-on-surface-p1-600-typography-letter-spacing: 0;
$es-theme-on-surface-p1-600-typography-paragraph-spacing: none;
$es-theme-on-surface-p1-600-typography-text-case: none;
$es-theme-on-surface-p1-600-typography-text-decoration: none;
$es-theme-on-surface-p1-700-typography-font-family: Open Sans;
$es-theme-on-surface-p1-700-typography-font-weight: Bold;
$es-theme-on-surface-p1-700-typography-line-height: 170%;
$es-theme-on-surface-p1-700-typography-font-size: 22;
$es-theme-on-surface-p1-700-typography-letter-spacing: 0;
$es-theme-on-surface-p1-700-typography-paragraph-spacing: none;
$es-theme-on-surface-p1-700-typography-text-case: none;
$es-theme-on-surface-p1-700-typography-text-decoration: none;
$es-theme-on-surface-p1-800-typography-font-family: Open Sans;
$es-theme-on-surface-p1-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-p1-800-typography-line-height: 170%;
$es-theme-on-surface-p1-800-typography-font-size: 22;
$es-theme-on-surface-p1-800-typography-letter-spacing: 0;
$es-theme-on-surface-p1-800-typography-paragraph-spacing: none;
$es-theme-on-surface-p1-800-typography-text-case: none;
$es-theme-on-surface-p1-800-typography-text-decoration: none;
$es-theme-on-surface-h4-300-typography-font-family: Open Sans;
$es-theme-on-surface-h4-300-typography-font-weight: Light;
$es-theme-on-surface-h4-300-typography-line-height: 170%;
$es-theme-on-surface-h4-300-typography-font-size: 24;
$es-theme-on-surface-h4-300-typography-letter-spacing: 0;
$es-theme-on-surface-h4-300-typography-paragraph-spacing: none;
$es-theme-on-surface-h4-300-typography-text-case: none;
$es-theme-on-surface-h4-300-typography-text-decoration: none;
$es-theme-on-surface-h4-400-typography-font-family: Open Sans;
$es-theme-on-surface-h4-400-typography-font-weight: Regular;
$es-theme-on-surface-h4-400-typography-line-height: 170%;
$es-theme-on-surface-h4-400-typography-font-size: 24;
$es-theme-on-surface-h4-400-typography-letter-spacing: 0;
$es-theme-on-surface-h4-400-typography-paragraph-spacing: none;
$es-theme-on-surface-h4-400-typography-text-case: none;
$es-theme-on-surface-h4-400-typography-text-decoration: none;
$es-theme-on-surface-h4-500-typography-font-family: Open Sans;
$es-theme-on-surface-h4-500-typography-font-weight: Medium;
$es-theme-on-surface-h4-500-typography-line-height: 170%;
$es-theme-on-surface-h4-500-typography-font-size: 24;
$es-theme-on-surface-h4-500-typography-letter-spacing: 0;
$es-theme-on-surface-h4-500-typography-paragraph-spacing: none;
$es-theme-on-surface-h4-500-typography-text-case: none;
$es-theme-on-surface-h4-500-typography-text-decoration: none;
$es-theme-on-surface-h4-600-typography-font-family: Open Sans;
$es-theme-on-surface-h4-600-typography-font-weight: SemiBold;
$es-theme-on-surface-h4-600-typography-line-height: 170%;
$es-theme-on-surface-h4-600-typography-font-size: 24;
$es-theme-on-surface-h4-600-typography-letter-spacing: 0;
$es-theme-on-surface-h4-600-typography-paragraph-spacing: none;
$es-theme-on-surface-h4-600-typography-text-case: none;
$es-theme-on-surface-h4-600-typography-text-decoration: none;
$es-theme-on-surface-h4-700-typography-font-family: Open Sans;
$es-theme-on-surface-h4-700-typography-font-weight: Bold;
$es-theme-on-surface-h4-700-typography-line-height: 170%;
$es-theme-on-surface-h4-700-typography-font-size: 24;
$es-theme-on-surface-h4-700-typography-letter-spacing: 0;
$es-theme-on-surface-h4-700-typography-paragraph-spacing: none;
$es-theme-on-surface-h4-700-typography-text-case: none;
$es-theme-on-surface-h4-700-typography-text-decoration: none;
$es-theme-on-surface-h4-800-typography-font-family: Open Sans;
$es-theme-on-surface-h4-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-h4-800-typography-line-height: 170%;
$es-theme-on-surface-h4-800-typography-font-size: 24;
$es-theme-on-surface-h4-800-typography-letter-spacing: 0;
$es-theme-on-surface-h4-800-typography-paragraph-spacing: none;
$es-theme-on-surface-h4-800-typography-text-case: none;
$es-theme-on-surface-h4-800-typography-text-decoration: none;
$es-theme-on-surface-h3-300-typography-font-family: Open Sans;
$es-theme-on-surface-h3-300-typography-font-weight: Light;
$es-theme-on-surface-h3-300-typography-line-height: 170%;
$es-theme-on-surface-h3-300-typography-font-size: 32;
$es-theme-on-surface-h3-300-typography-letter-spacing: 0;
$es-theme-on-surface-h3-300-typography-paragraph-spacing: none;
$es-theme-on-surface-h3-300-typography-text-case: none;
$es-theme-on-surface-h3-300-typography-text-decoration: none;
$es-theme-on-surface-h3-400-typography-font-family: Open Sans;
$es-theme-on-surface-h3-400-typography-font-weight: Regular;
$es-theme-on-surface-h3-400-typography-line-height: 170%;
$es-theme-on-surface-h3-400-typography-font-size: 32;
$es-theme-on-surface-h3-400-typography-letter-spacing: 0;
$es-theme-on-surface-h3-400-typography-paragraph-spacing: none;
$es-theme-on-surface-h3-400-typography-text-case: none;
$es-theme-on-surface-h3-400-typography-text-decoration: none;
$es-theme-on-surface-h3-500-typography-font-family: Open Sans;
$es-theme-on-surface-h3-500-typography-font-weight: Medium;
$es-theme-on-surface-h3-500-typography-line-height: 170%;
$es-theme-on-surface-h3-500-typography-font-size: 32;
$es-theme-on-surface-h3-500-typography-letter-spacing: 0;
$es-theme-on-surface-h3-500-typography-paragraph-spacing: none;
$es-theme-on-surface-h3-500-typography-text-case: none;
$es-theme-on-surface-h3-500-typography-text-decoration: none;
$es-theme-on-surface-h3-600-typography-font-family: Open Sans;
$es-theme-on-surface-h3-600-typography-font-weight: SemiBold;
$es-theme-on-surface-h3-600-typography-line-height: 170%;
$es-theme-on-surface-h3-600-typography-font-size: 32;
$es-theme-on-surface-h3-600-typography-letter-spacing: 0;
$es-theme-on-surface-h3-600-typography-paragraph-spacing: none;
$es-theme-on-surface-h3-600-typography-text-case: none;
$es-theme-on-surface-h3-600-typography-text-decoration: none;
$es-theme-on-surface-h3-700-typography-font-family: Open Sans;
$es-theme-on-surface-h3-700-typography-font-weight: Bold;
$es-theme-on-surface-h3-700-typography-line-height: 170%;
$es-theme-on-surface-h3-700-typography-font-size: 32;
$es-theme-on-surface-h3-700-typography-letter-spacing: 0;
$es-theme-on-surface-h3-700-typography-paragraph-spacing: none;
$es-theme-on-surface-h3-700-typography-text-case: none;
$es-theme-on-surface-h3-700-typography-text-decoration: none;
$es-theme-on-surface-h3-800-typography-font-family: Open Sans;
$es-theme-on-surface-h3-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-h3-800-typography-line-height: 170%;
$es-theme-on-surface-h3-800-typography-font-size: 32;
$es-theme-on-surface-h3-800-typography-letter-spacing: 0;
$es-theme-on-surface-h3-800-typography-paragraph-spacing: none;
$es-theme-on-surface-h3-800-typography-text-case: none;
$es-theme-on-surface-h3-800-typography-text-decoration: none;
$es-theme-on-surface-h2-300-typography-font-family: Open Sans;
$es-theme-on-surface-h2-300-typography-font-weight: Light;
$es-theme-on-surface-h2-300-typography-line-height: 150%;
$es-theme-on-surface-h2-300-typography-font-size: 42;
$es-theme-on-surface-h2-300-typography-letter-spacing: 0;
$es-theme-on-surface-h2-300-typography-paragraph-spacing: none;
$es-theme-on-surface-h2-300-typography-text-case: none;
$es-theme-on-surface-h2-300-typography-text-decoration: none;
$es-theme-on-surface-h2-400-typography-font-family: Open Sans;
$es-theme-on-surface-h2-400-typography-font-weight: Regular;
$es-theme-on-surface-h2-400-typography-line-height: 150%;
$es-theme-on-surface-h2-400-typography-font-size: 42;
$es-theme-on-surface-h2-400-typography-letter-spacing: 0;
$es-theme-on-surface-h2-400-typography-paragraph-spacing: none;
$es-theme-on-surface-h2-400-typography-text-case: none;
$es-theme-on-surface-h2-400-typography-text-decoration: none;
$es-theme-on-surface-h2-500-typography-font-family: Open Sans;
$es-theme-on-surface-h2-500-typography-font-weight: Medium;
$es-theme-on-surface-h2-500-typography-line-height: 150%;
$es-theme-on-surface-h2-500-typography-font-size: 42;
$es-theme-on-surface-h2-500-typography-letter-spacing: 0;
$es-theme-on-surface-h2-500-typography-paragraph-spacing: none;
$es-theme-on-surface-h2-500-typography-text-case: none;
$es-theme-on-surface-h2-500-typography-text-decoration: none;
$es-theme-on-surface-h2-600-typography-font-family: Open Sans;
$es-theme-on-surface-h2-600-typography-font-weight: SemiBold;
$es-theme-on-surface-h2-600-typography-line-height: 150%;
$es-theme-on-surface-h2-600-typography-font-size: 42;
$es-theme-on-surface-h2-600-typography-letter-spacing: 0;
$es-theme-on-surface-h2-600-typography-paragraph-spacing: none;
$es-theme-on-surface-h2-600-typography-text-case: none;
$es-theme-on-surface-h2-600-typography-text-decoration: none;
$es-theme-on-surface-h2-700-typography-font-family: Open Sans;
$es-theme-on-surface-h2-700-typography-font-weight: Bold;
$es-theme-on-surface-h2-700-typography-line-height: 150%;
$es-theme-on-surface-h2-700-typography-font-size: 42;
$es-theme-on-surface-h2-700-typography-letter-spacing: 0;
$es-theme-on-surface-h2-700-typography-paragraph-spacing: none;
$es-theme-on-surface-h2-700-typography-text-case: none;
$es-theme-on-surface-h2-700-typography-text-decoration: none;
$es-theme-on-surface-h2-800-typography-font-family: Open Sans;
$es-theme-on-surface-h2-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-h2-800-typography-line-height: 150%;
$es-theme-on-surface-h2-800-typography-font-size: 42;
$es-theme-on-surface-h2-800-typography-letter-spacing: 0;
$es-theme-on-surface-h2-800-typography-paragraph-spacing: none;
$es-theme-on-surface-h2-800-typography-text-case: none;
$es-theme-on-surface-h2-800-typography-text-decoration: none;
$es-theme-on-surface-h1-300-typography-font-family: Open Sans;
$es-theme-on-surface-h1-300-typography-font-weight: Light;
$es-theme-on-surface-h1-300-typography-line-height: 140%;
$es-theme-on-surface-h1-300-typography-font-size: 60;
$es-theme-on-surface-h1-300-typography-letter-spacing: 0;
$es-theme-on-surface-h1-300-typography-paragraph-spacing: none;
$es-theme-on-surface-h1-300-typography-text-case: none;
$es-theme-on-surface-h1-300-typography-text-decoration: none;
$es-theme-on-surface-h1-400-typography-font-family: Open Sans;
$es-theme-on-surface-h1-400-typography-font-weight: Regular;
$es-theme-on-surface-h1-400-typography-line-height: 140%;
$es-theme-on-surface-h1-400-typography-font-size: 60;
$es-theme-on-surface-h1-400-typography-letter-spacing: 0;
$es-theme-on-surface-h1-400-typography-paragraph-spacing: none;
$es-theme-on-surface-h1-400-typography-text-case: none;
$es-theme-on-surface-h1-400-typography-text-decoration: none;
$es-theme-on-surface-h1-500-typography-font-family: Open Sans;
$es-theme-on-surface-h1-500-typography-font-weight: Medium;
$es-theme-on-surface-h1-500-typography-line-height: 140%;
$es-theme-on-surface-h1-500-typography-font-size: 60;
$es-theme-on-surface-h1-500-typography-letter-spacing: 0;
$es-theme-on-surface-h1-500-typography-paragraph-spacing: none;
$es-theme-on-surface-h1-500-typography-text-case: none;
$es-theme-on-surface-h1-500-typography-text-decoration: none;
$es-theme-on-surface-h1-600-typography-font-family: Open Sans;
$es-theme-on-surface-h1-600-typography-font-weight: SemiBold;
$es-theme-on-surface-h1-600-typography-line-height: 140%;
$es-theme-on-surface-h1-600-typography-font-size: 60;
$es-theme-on-surface-h1-600-typography-letter-spacing: 0;
$es-theme-on-surface-h1-600-typography-paragraph-spacing: none;
$es-theme-on-surface-h1-600-typography-text-case: none;
$es-theme-on-surface-h1-600-typography-text-decoration: none;
$es-theme-on-surface-h1-700-typography-font-family: Open Sans;
$es-theme-on-surface-h1-700-typography-font-weight: Bold;
$es-theme-on-surface-h1-700-typography-line-height: 140%;
$es-theme-on-surface-h1-700-typography-font-size: 60;
$es-theme-on-surface-h1-700-typography-letter-spacing: 0;
$es-theme-on-surface-h1-700-typography-paragraph-spacing: none;
$es-theme-on-surface-h1-700-typography-text-case: none;
$es-theme-on-surface-h1-700-typography-text-decoration: none;
$es-theme-on-surface-h1-800-typography-font-family: Open Sans;
$es-theme-on-surface-h1-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-h1-800-typography-line-height: 150%;
$es-theme-on-surface-h1-800-typography-font-size: 60;
$es-theme-on-surface-h1-800-typography-letter-spacing: 0;
$es-theme-on-surface-h1-800-typography-paragraph-spacing: none;
$es-theme-on-surface-h1-800-typography-text-case: none;
$es-theme-on-surface-h1-800-typography-text-decoration: none;
$es-theme-on-surface-d1-300-typography-font-family: Open Sans;
$es-theme-on-surface-d1-300-typography-font-weight: Light;
$es-theme-on-surface-d1-300-typography-line-height: 150%;
$es-theme-on-surface-d1-300-typography-font-size: 80;
$es-theme-on-surface-d1-300-typography-letter-spacing: 0;
$es-theme-on-surface-d1-300-typography-paragraph-spacing: none;
$es-theme-on-surface-d1-300-typography-text-case: none;
$es-theme-on-surface-d1-300-typography-text-decoration: none;
$es-theme-on-surface-d1-400-typography-font-family: Open Sans;
$es-theme-on-surface-d1-400-typography-font-weight: Regular;
$es-theme-on-surface-d1-400-typography-line-height: 150%;
$es-theme-on-surface-d1-400-typography-font-size: 80;
$es-theme-on-surface-d1-400-typography-letter-spacing: 0;
$es-theme-on-surface-d1-400-typography-paragraph-spacing: none;
$es-theme-on-surface-d1-400-typography-text-case: none;
$es-theme-on-surface-d1-400-typography-text-decoration: none;
$es-theme-on-surface-d1-500-typography-font-family: Open Sans;
$es-theme-on-surface-d1-500-typography-font-weight: Medium;
$es-theme-on-surface-d1-500-typography-line-height: 150%;
$es-theme-on-surface-d1-500-typography-font-size: 80;
$es-theme-on-surface-d1-500-typography-letter-spacing: 0;
$es-theme-on-surface-d1-500-typography-paragraph-spacing: none;
$es-theme-on-surface-d1-500-typography-text-case: none;
$es-theme-on-surface-d1-500-typography-text-decoration: none;
$es-theme-on-surface-d1-600-typography-font-family: Open Sans;
$es-theme-on-surface-d1-600-typography-font-weight: SemiBold;
$es-theme-on-surface-d1-600-typography-line-height: 150%;
$es-theme-on-surface-d1-600-typography-font-size: 80;
$es-theme-on-surface-d1-600-typography-letter-spacing: 0;
$es-theme-on-surface-d1-600-typography-paragraph-spacing: none;
$es-theme-on-surface-d1-600-typography-text-case: none;
$es-theme-on-surface-d1-600-typography-text-decoration: none;
$es-theme-on-surface-d1-700-typography-font-family: Open Sans;
$es-theme-on-surface-d1-700-typography-font-weight: Bold;
$es-theme-on-surface-d1-700-typography-line-height: 150%;
$es-theme-on-surface-d1-700-typography-font-size: 80;
$es-theme-on-surface-d1-700-typography-letter-spacing: 0;
$es-theme-on-surface-d1-700-typography-paragraph-spacing: none;
$es-theme-on-surface-d1-700-typography-text-case: none;
$es-theme-on-surface-d1-700-typography-text-decoration: none;
$es-theme-on-surface-d1-800-typography-font-family: Open Sans;
$es-theme-on-surface-d1-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-d1-800-typography-line-height: 150%;
$es-theme-on-surface-d1-800-typography-font-size: 80;
$es-theme-on-surface-d1-800-typography-letter-spacing: 0;
$es-theme-on-surface-d1-800-typography-paragraph-spacing: none;
$es-theme-on-surface-d1-800-typography-text-case: none;
$es-theme-on-surface-d1-800-typography-text-decoration: none;
$es-theme-on-surface-h6-300-typography-font-family: Open Sans;
$es-theme-on-surface-h6-300-typography-font-weight: Light;
$es-theme-on-surface-h6-300-typography-line-height: 170%;
$es-theme-on-surface-h6-300-typography-font-size: 16;
$es-theme-on-surface-h6-300-typography-letter-spacing: 0;
$es-theme-on-surface-h6-300-typography-paragraph-spacing: none;
$es-theme-on-surface-h6-300-typography-text-case: none;
$es-theme-on-surface-h6-300-typography-text-decoration: none;
$es-theme-on-surface-h6-400-typography-font-family: Open Sans;
$es-theme-on-surface-h6-400-typography-font-weight: Regular;
$es-theme-on-surface-h6-400-typography-line-height: 170%;
$es-theme-on-surface-h6-400-typography-font-size: 16;
$es-theme-on-surface-h6-400-typography-letter-spacing: 0;
$es-theme-on-surface-h6-400-typography-paragraph-spacing: none;
$es-theme-on-surface-h6-400-typography-text-case: none;
$es-theme-on-surface-h6-400-typography-text-decoration: none;
$es-theme-on-surface-h6-500-typography-font-family: Open Sans;
$es-theme-on-surface-h6-500-typography-font-weight: Medium;
$es-theme-on-surface-h6-500-typography-line-height: 170%;
$es-theme-on-surface-h6-500-typography-font-size: 16;
$es-theme-on-surface-h6-500-typography-letter-spacing: 0;
$es-theme-on-surface-h6-500-typography-paragraph-spacing: none;
$es-theme-on-surface-h6-500-typography-text-case: none;
$es-theme-on-surface-h6-500-typography-text-decoration: none;
$es-theme-on-surface-h6-600-typography-font-family: Open Sans;
$es-theme-on-surface-h6-600-typography-font-weight: SemiBold;
$es-theme-on-surface-h6-600-typography-line-height: 170%;
$es-theme-on-surface-h6-600-typography-font-size: 16;
$es-theme-on-surface-h6-600-typography-letter-spacing: 0;
$es-theme-on-surface-h6-600-typography-paragraph-spacing: none;
$es-theme-on-surface-h6-600-typography-text-case: none;
$es-theme-on-surface-h6-600-typography-text-decoration: none;
$es-theme-on-surface-h6-700-typography-font-family: Open Sans;
$es-theme-on-surface-h6-700-typography-font-weight: Bold;
$es-theme-on-surface-h6-700-typography-line-height: 170%;
$es-theme-on-surface-h6-700-typography-font-size: 16;
$es-theme-on-surface-h6-700-typography-letter-spacing: 0;
$es-theme-on-surface-h6-700-typography-paragraph-spacing: none;
$es-theme-on-surface-h6-700-typography-text-case: none;
$es-theme-on-surface-h6-700-typography-text-decoration: none;
$es-theme-on-surface-h6-800-typography-font-family: Open Sans;
$es-theme-on-surface-h6-800-typography-font-weight: ExtraBold;
$es-theme-on-surface-h6-800-typography-line-height: 170%;
$es-theme-on-surface-h6-800-typography-font-size: 16;
$es-theme-on-surface-h6-800-typography-letter-spacing: 0;
$es-theme-on-surface-h6-800-typography-paragraph-spacing: none;
$es-theme-on-surface-h6-800-typography-text-case: none;
$es-theme-on-surface-h6-800-typography-text-decoration: none;
$es-theme-on-surface-progress-height-s-sizing: 2;
$es-theme-on-surface-progress-height-m-sizing: 4;
$es-theme-on-surface-progress-height-l-sizing: 4 * 2;
$es-theme-on-surface-progress-width-s-sizing: 1;
$es-theme-on-surface-progress-width-m-sizing: 2;
$es-theme-on-surface-progress-width-l-sizing: 4;
$es-theme-on-surface-progress-s-border-radius: 2;
$es-theme-on-surface-progress-l-border-radius: 4 * 2;
$es-theme-on-surface-progress-m-border-radius: 4;
$es-theme-on-surface-progress-thumb-down-rounded-m-border-radius: 0 0 4 4;
$es-theme-on-surface-progress-thumb-down-rounded-s-border-radius: 0 0 2 2;
$es-theme-on-surface-progress-thumb-down-rounded-l-border-radius: 0 0 4 * 2 4 *
  2;
$es-theme-on-surface-progress-track-down-rounded-m-border-radius: 0 0 4 4;
$es-theme-on-surface-progress-track-down-rounded-s-border-radius: 0 0 2 2;
$es-theme-on-surface-progress-track-down-rounded-l-border-radius: 0 0 4 * 2 4 *
  2;
$es-theme-overlay-s-border-radius: 4;
$es-theme-overlay-m-border-radius: 4 * 2;
$es-theme-overlay-l-border-radius: 4 * 3;
$es-theme-overlay-mobile-header-box-shadow-color: #00000014;
$es-theme-overlay-mobile-header-box-shadow-type: dropShadow;
$es-theme-overlay-mobile-header-box-shadow-x: 0;
$es-theme-overlay-mobile-header-box-shadow-y: 4;
$es-theme-overlay-mobile-header-box-shadow-blur: 8;
$es-theme-overlay-mobile-header-box-shadow-spread: 0;
$es-theme-overlay-mobile-footer-box-shadow-color: #0000000f;
$es-theme-overlay-mobile-footer-box-shadow-type: dropShadow;
$es-theme-overlay-mobile-footer-box-shadow-x: 0;
$es-theme-overlay-mobile-footer-box-shadow-y: -4;
$es-theme-overlay-mobile-footer-box-shadow-blur: 8;
$es-theme-overlay-mobile-footer-box-shadow-spread: 0;
$es-theme-overlay-color-header-box-shadow-x: 0;
$es-theme-overlay-color-header-box-shadow-y: 10;
$es-theme-overlay-color-header-box-shadow-blur: 30;
$es-theme-overlay-color-header-box-shadow-spread: 0;
$es-theme-overlay-color-header-box-shadow-color: #81ff8d14;
$es-theme-overlay-color-header-box-shadow-type: dropShadow;
$es-theme-overlay-dropdown-menu-m-box-shadow-x: 0;
$es-theme-overlay-dropdown-menu-m-box-shadow-y: 20;
$es-theme-overlay-dropdown-menu-m-box-shadow-blur: 20;
$es-theme-overlay-dropdown-menu-m-box-shadow-spread: 0;
$es-theme-overlay-dropdown-menu-m-box-shadow-color: #00000014;
$es-theme-overlay-dropdown-menu-m-box-shadow-type: dropShadow;
$es-theme-brand-color-primary-100-color: #2e7d321a;
$es-theme-brand-color-primary-150-color: #2e7d3233;
$es-theme-brand-color-primary-200-color: #2e7d324d;
$es-theme-brand-color-primary-250-color: #2e7d3266;
$es-theme-brand-color-primary-300-color: #2e7d3299;
$es-theme-brand-color-primary-350-color: #2e7d32cc;
$es-theme-brand-color-primary-400-color: #83f105;
$es-theme-brand-color-primary-450-color: #388e3c;
$es-theme-brand-color-primary-500-color: #2e7d32;
$es-theme-brand-color-primary-550-color: #29702d;
$es-theme-brand-color-primary-600-color: #1b5e20;
$es-theme-brand-color-neutral-f-color: #fff;
$es-theme-brand-color-neutral-100-color: #f7f7f7;
$es-theme-brand-color-neutral-150-color: #eff0f0;
$es-theme-brand-color-neutral-200-color: #ebebeb;
$es-theme-brand-color-neutral-250-color: #e5e5e6;
$es-theme-brand-color-neutral-300-color: #dddedf;
$es-theme-brand-color-neutral-350-color: #d5d6d8;
$es-theme-brand-color-neutral-400-color: #c1c2c3;
$es-theme-brand-color-neutral-500-color: #bdbdbd;
$es-theme-brand-color-neutral-520-color: #9a9a9a;
$es-theme-brand-color-neutral-550-color: #828282;
$es-theme-brand-color-neutral-600-color: #666666;
$es-theme-brand-color-neutral-620-color: #464749;
$es-theme-brand-color-neutral-650-color: #3c3d3e;
$es-theme-brand-color-neutral-660-color: #373839;
$es-theme-brand-color-neutral-666-color: #313335;
$es-theme-brand-color-neutral-670-color: #2f3032;
$es-theme-brand-color-neutral-700-color: #2c2e30;
$es-theme-brand-color-neutral-750-color: #292b2d;
$es-theme-brand-color-neutral-780-color: #242628;
$es-theme-brand-color-neutral-800-color: #1f2123;
$es-theme-brand-color-neutral-850-color: #1a1c1e;
$es-theme-brand-color-neutral-900-color: #121416;
$es-theme-brand-color-neutral-990-color: #000;
$es-theme-brand-color-neutral-f-60-color: #ffffff99;
$es-theme-brand-color-neutral-f-20-color: #ffffff33;
$es-theme-brand-color-neutral-0-20-color: #00000033;
$es-theme-brand-color-neutral-0-60-color: #00000099;
$es-theme-brand-color-secondary-300-color: #e86612;
$es-theme-brand-color-secondary-400-color: #ff9137;
$es-theme-brand-color-secondary-500-color: #fb8c00;
$es-theme-brand-color-secondary-600-color: #ef6c00;
$es-theme-brand-color-secondary-700-color: #e86612;
$es-theme-brand-color-secondary-800-color: #b74b00;
$es-theme-brand-color-secondary-main-60-color: #ef6c004d;
$es-theme-brand-color-error-300-color: #ff4d4d;
$es-theme-brand-color-error-400-color: #c55442;
$es-theme-brand-color-error-500-color: #ff2706;
$es-theme-brand-color-error-50-color: #ff270680;
$es-theme-brand-color-error-600-color: #ff3e3e;
$es-theme-brand-color-error-20-color: #ff270680;
$es-theme-brand-color-attention-400-color: #f28933;
$es-theme-brand-color-attention-500-color: #ef6c00;
$es-theme-brand-color-attention-50-color: #ef6c0080;
$es-theme-brand-color-attention-600-color: #d96200;
$es-theme-brand-color-attention-30-color: #ff963f4d;
$es-theme-brand-color-success-300-color: #2a722e;
$es-theme-brand-color-success-400-color: #58975b;
$es-theme-brand-color-success-500-color: #2e7d32;
$es-theme-brand-color-success-50-color: #2e7d3280;
$es-theme-brand-color-success-600-color: #2a722e;
$es-theme-brand-color-success-20-color: #58975b33;
$es-theme-brand-color-shades-100-color: #bedaff;
$es-theme-brand-color-shades-200-color: #6fa1d9;
$es-theme-brand-color-shades-300-color: #658dbb;
$es-theme-brand-color-shades-400-color: #557aa5;
$es-theme-brand-color-shades-500-color: #4b6f98;
$es-theme-brand-color-shades-50-color: #4b6f9880;
$es-theme-brand-color-shades-700-color: #1c467d;
$es-theme-brand-color-shades-20-color: #4b6f9833;
$es-theme-inner-shadow-s-box-shadow-x: 0;
$es-theme-inner-shadow-s-box-shadow-y: 4;
$es-theme-inner-shadow-s-box-shadow-blur: 6;
$es-theme-inner-shadow-s-box-shadow-spread: 0;
$es-theme-inner-shadow-s-box-shadow-color: #00000014;
$es-theme-inner-shadow-s-box-shadow-type: innerShadow;
