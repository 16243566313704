@import './themes/light/scss/light';

.p1-300 {
  font-weight: $font-weight-300;
  font-size: $font-size-18;
}

.p1-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-18;
}

.p1-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-18;
}

.p2-300 {
  font-weight: $font-weight-300;
  font-size: $font-size-16;
}

.p2-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-16;
}

.p2-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-16;
}

.p3-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-14;
}

.p3-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-14;
}

.p4-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-12;
}

.p4-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-12;
}

.p5-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-10;
}
