@import 'assets/styles/typography';

.container {
  padding: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: inherit;
}

.image {
  margin: 0 auto;
  max-width: 500px;
  width: inherit;
}

.title {
  padding-top: 28px;
  color: $error-darker;
  font-size: $font-size-24;
}

.message {
  padding-top: 12px;
  font-size: $font-size-24;
}

.button {
  @media (min-width: 380px) {
    min-width: 350px;
  }
}
