@import '../../assets/styles/typography';
@import '../../assets/styles/global';

.mainBg {
  min-height: 70vh;
  height: 100%;
  background-image: url('../../assets/images/main_bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.link {
  position: relative;
  cursor: pointer;
}

.link:before {
  position: absolute;
  content: '';
  display: block;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: $white;
  opacity: 0.2;
}
.active:before {
  opacity: 1;
}

.mainBg {
  height: 100%;
  background-image: url('../../assets/images/main_bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.containerBody {
  width: inherit;
}
