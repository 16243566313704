@import '../../assets/styles/typography';

.divider {
  height: 1px;
  background-color: $divider-color;
}

.text {
  color: $neutral-550;
}
