@import '../../assets/styles/typography';

.sticky {
  z-index: 200 !important;
  top: 50px !important;
}

.content {
  font-size: $font-size-18;
}

.headings {
  border-left: 1px solid $neutral-300;
}

@media (max-width: 576px) {
  .row {
    flex-direction: column-reverse;
  }
}
