@import '../../assets/styles/typography';

.mainBg {
  min-height: 74vh;
  background-image: url('../../assets/images/main_bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.subTitle {
  margin-top: 64px;
  color: $neutral-400;
}

.content {
  background-color: rgba(0, 0, 0, 0.6);
}

.qrText {
  font-size: $font-size-12;
  line-height: $font-size-14;
  color: $white;
  max-width: 105px;
}
