@import '../../assets/styles/typography';

.navbar {
  padding: 3px !important;
}

.link {
  font-size: $font-size-16 !important;
}

.menu {
  display: flex !important;
}

.profileLink {
  background-color: $profile-link-bg;
  border-radius: $profile-link-border-radius;
}

.profileLink {
  p {
    font-size: $profile-link-font-size;
  }
}

.dropdownToggle {
  &:after {
    display: none !important;
  }
  i {
    font-size: 8px;
  }
}

@media screen and (max-width: 576px) {
  .menu {
    display: none !important;
  }
}
