@import '../../assets/styles/typography';

.message {
  p {
    z-index: 1;
  }
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    opacity: 0.2;
    border-radius: 8px;
  }
}

.success {
  &:after {
    background-color: $success;
  }
}

.info {
  &:after {
    background-color: $info;
  }
}

.error {
  &:after {
    background-color: $error-message;
  }
}
