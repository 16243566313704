.open-sans-light {
  font-family: $open-sans-light;
}

.open-sans-regular {
  font-family: $open-sans-regular;
}

.open-sans-medium {
  font-family: $open-sans-medium;
}

.open-sans-semibold {
  font-family: $open-sans-semibold;
}

.open-sans-bold {
  font-family: $open-sans-bold;
}

.focus-label:has(input:focus) label,
.focus-label:has(select:focus) label {
  color: $input-focus-label-color;
}

.focus-label:has(input:hover) i,
.focus-label:has(div:last-child i:hover) i {
  color: $input-icon-hover-color;
}

.focus-label:has(input:focus) i {
  color: $input-focus-label-color;
}

.focus-none {
  outline: unset;
}

.focus-label > div > i + input {
  padding: 0.688rem 1.6rem 0.688rem 1.9rem;
}

.form-control:not(:disabled):hover,
.form-select:hover,
.focus-label i:hover + input {
  border-color: $input-hover-color;
  box-shadow: $input-hover-box-shadow;
}

.form-select:required:invalid {
  color: $input-placeholder-color;
  option {
    color: $input-color;
  }
}

.form-control {
  &.empty {
    color: $input-placeholder-color;
  }
}

.card-max-width {
  max-width: 450px !important;
}

.PhoneInput {
  &.PhoneInput--disabled {
    background: $input-disabled-bg;

    input {
      border: unset;
      background: $input-disabled-bg;
    }
  }

  .PhoneInputCountryIcon {
    display: flex !important;
  }

  .PhoneInputCountrySelectArrow {
    margin-top: -2px;
  }

  .PhoneInputCountrySelect {
    opacity: 0 !important;
  }

  .PhoneInputInput {
    &,
    &:focus {
      border: unset;
      outline: unset;
    }
    padding-left: 2px;
    color: $input-color;
  }
}

.text-link-secondary {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.text-error {
  color: $error;
}

.about-wrapper,
.terms-conditions-wrapper,
.privacy-policy-wrapper,
.delete-account-wrapper {
  p {
    margin-bottom: 1rem;
  }
}

.accordion-button {
  font-size: $font-size-24 !important;
  &::after {
    width: 12px !important;
    height: 24px !important;
    background-size: 12px 24px !important;
    margin-right: 15px;
  }
}

.status-error {
  color: $icon-error-color !important;
}

.status-success {
  color: $icon-success-color !important;
}

.status-inactive {
  color: $icon-inactive-color !important;
}

.status-verified {
  color: $icon-success-color !important;
}

.status-unverified {
  color: $status-unverified-color !important;
}

div.status-verified,
div.status-unverified {
  font-weight: 600;
  font-size: $status-unverified-text-size;
}

i.status-verified,
i.status-unverified {
  margin-right: 0.2rem;
  font-size: $status-unverified-icon-size !important;
}

.capitalise {
  text-transform: capitalize !important;
}

.no-hover,
.no-hover:hover,
.dropdown-item:active {
  color: inherit !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.profile-link-dropdown {
  &.show {
    i {
      display: block !important;
      transform: rotate(180deg) !important;
    }
  }
}
.profile-link-dropdown + .dropdown-menu {
  background-color: $profile-link-dropdown-bg !important;
  box-shadow: $profile-link-box-shadow;
  border: none !important;
  p,
  a {
    color: $text-primary !important;
  }
  .dropdown-item {
    &:hover {
      background-color: $profile-link-dropdown-item-hover-bg !important;
    }
  }
}

.last-update {
  font-size: 14px;
}

.button-page {
  font-size: 12px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.bg {
  &-neutral-150 {
    background: $neutral-150 !important;
  }
}

@media (max-width: 576px) {
  .accordion-button {
    &::after {
      margin-right: 0px;
    }
  }
}
