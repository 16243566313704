@import '../../assets/styles/typography';

.imageBox {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: $neutral-400;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $neutral-620;
  }
  i {
    color: white;
  }
}

.hasImage {
  .overlayFixed {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    transition: all 0.3s ease-in-out;
  }
  .overlayFixed + i,
  .overlayFixed + i + i {
    display: block;
    top: 10%;
    padding: 5px;
    box-shadow: $uploader-icon-box-shadow;
    &:hover {
      opacity: 1 !important;
    }
  }
  .overlayFixed + i + i {
    top: 57%;
    color: $uploader-delete-icon-color !important;
  }
  .overlayFixed + i,
  .overlayFixed + i + i,
  .overlayFixed + i + i + div {
    z-index: 3;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  .overlayFixed + i + i + div {
    width: 100%;
    height: 1px;
    background-color: $uploader-delimiter-bg;
  }
  &:hover {
    .overlayFixed {
      background-color: rgba(0, 0, 0, 0.5);
    }
    .overlayFixed + i,
    .overlayFixed + i + i,
    .overlayFixed + i + i + div {
      opacity: 0.5;
    }
  }
}

.info {
  font-size: $font-size-14;
}

@media (max-width: 576px) {
  .tabContent {
    width: calc(100% - 52px);
  }
  .uploader {
    display: none !important;
  }
}
