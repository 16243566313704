@import '../../assets/styles/typography';

.categorySection {
  min-height: 800px;
}

.input {
  border-radius: 70px !important;
}

.searchBar {
  background-image: url('../../assets/images/banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 141px;
}

.category {
  font-size: $font-size-18;
  min-height: 102px;
  box-shadow: 0px 1px 2px 0px #00000040;
  &:hover {
    box-shadow: 0px 6px 15px 0px #0000001f;
  }
  &.active {
    background-color: $primary !important;
    color: $white !important;
  }
}

.acordionItem {
  box-shadow: 0px 1px 2px 0px #00000040;
}

.icon {
  color: $neutral-750;
}

.titleCategory {
  max-width: 282px;
}

@media (max-width: 576px) {
  .acordionItem button {
    font-size: 18px !important;
  }
}
