@import '../../assets/styles/typography';

.footer {
  min-height: 240px;
}

.copyright {
  font-size: 0.75rem;
}

.divider {
  color: $divider-color;
  background-color: $divider-color;
}
