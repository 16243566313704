@import '../../assets/styles/typography';

@mixin hoverIconStyle {
  &:hover {
    i {
      color: $white !important;
    }
  }
}

.table {
  thead {
    tr {
      th {
        font-size: $font-size-14;
        font-family: $open-sans-semibold;
        border-right-width: 1px;
      }
      th:last-child {
        border-right: none;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: $font-size-15;
        vertical-align: middle;
      }
    }
  }
}

.headerPagination {
  max-width: 150px;

  select {
    font-size: $font-size-12;
    font-family: $open-sans-bold;
    font-weight: 600;
  }
}

.entriesTitle {
  color: $neutral-550;
}

.refresh {
  width: 40px;
  height: 40px;
  i {
    width: 16px;
    height: 16px;
  }
  @include hoverIconStyle;
}

.download {
  @include hoverIconStyle;
}

.arrow {
  width: 28px;
  height: 28px;
  background-color: $neutral-100;
  cursor: pointer;
  &.disabled {
    background-color: transparent;
    cursor: default;
  }
}

@media (max-width: 576px) {
  .arrow {
    margin-right: 0.2rem !important;
  }
  .pagination {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .select {
    height: 40px;
  }
}
