@import '../../assets/styles/typography';

.input {
  width: 3.32rem !important;
  height: 24px !important;

  &:not(:disabled) {
    cursor: pointer;
  }
}

.label {
  font-size: $font-size-18;
  font-weight: $font-weight-500;
}

@media screen and (max-width: 500px) {
  .label {
    width: 75%;
  }
}
