@import '../../assets/styles/typography';

:global {
  .tooltip {
    color: $white;
    line-height: $line-height-170;
    white-space: pre-wrap;

    .tooltip-inner {
      max-width: 250px;
      font-size: $font-size-12;
      border-radius: $tooltip-border-radius;
      text-align: left;
      white-space: break-spaces;
    }
  }
}

.tooltipWrapper {
  display: inline-block;
  position: relative;
}
