@import '../../assets/styles/typography';

.clock,
.time {
  color: $atention;
}

.time {
  font-size: 35px;
}

.otpInput input[type='number']::-webkit-inner-spin-button,
.otpInput input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.otpInput input {
  width: 3.5em !important;
  height: 3.5em !important;
  border: 1px solid $input-secondary-border-color;
  border-radius: $otp-code-border-radius;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  &:hover {
    border-color: $input-hover-color;
    box-shadow: $input-hover-box-shadow;
  }
  &:focus,
  :focus-visible {
    outline: none !important;
    border-color: $otp-code-focus-border !important;
  }
}

.separator {
  width: 12px;
  height: 1px;
  background-color: $otp-code-separator-color;
}

.resend,
.verification {
  font-size: 14px;
  button {
    border: none;
    background-color: transparent;
    color: $atention;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .otpInput input {
    width: 2.1em !important;
    height: 2.1em !important;
  }
  .separator {
    width: 8px;
  }
}
