.modal-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0.5;
  z-index: 0;
}

.logout-dialog-container {
  z-index: 10000000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.modal-view {
  top: 50%;
  transform: translateY(-50%);
}
