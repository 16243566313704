@import '../../assets/styles/typography';

.title {
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    background-color: $primary;
    opacity: 0.1;
  }
}
