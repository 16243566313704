@import '../../assets/styles/typography';

.image {
  width: 7.82rem;
}

.scopeTitle {
  font-size: $font-size-14;
  line-height: $line-height-170;
}

.subTitle {
  font-size: $font-size-15;
  line-height: $line-height-170;
}

.divider {
  color: $es-dashed-border-color;
  background-color: $es-dashed-border-color;
}
