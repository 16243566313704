@import '../../assets/styles/typography';

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.controlInner {
  position: relative;

  i {
    display: block;
    position: absolute;
    color: $input-icon-color;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0.7rem;
  }
}

.eye {
  right: 10px;
}

.inputTooltip {
  ul {
    margin-bottom: 0;
    padding-left: 1rem;
  }
}
