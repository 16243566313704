@import './assets/styles/themes/light/css/light.css';
@import './assets/styles/themes/light/scss/light.scss';
@import './assets/styles/global';

$theme-colors: (
  primary: $es-global-success-500-color,
  main: $es-color-green-dark-box-shadow-type,
  neutral-100: $es-global-neutral-100-color,
  neutral-750: $es-global-neutral-750-color,
  secondary: $es-global-orange-270-color,
  danger: $es-theme-brand-color-error-400-color,
);

@import 'bootstrap';
@import './assets/styles/typography';
@import './assets/styles/icomoon';
@import 'react-toastify/dist/ReactToastify.css';

.navbar-light .navbar-toggler-icon {
  background-image: url('./assets/icons/burger-menu.svg');
  width: 1rem;
  height: 1rem;
}

a {
  text-decoration: none;
  color: $es-global-orange-270-color !important;
}

.top-25 {
  top: 25%;
}

.required:after {
  content: ' *';
  color: $error;
}

.error {
  label {
    color: $input-error-text-label-color;
  }

  i {
    color: $input-error-icon-color;
  }

  input,
  select,
  .PhoneInput {
    border: 1px solid $input-error-border-color;
  }
}

.sign-block {
  background-color: $card-bg-color;
  border-radius: $card-border-radius;
}

.text-danger {
  font-size: 0.87rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-light-100 {
  background-color: $neutral-100;
}

.with-shadow {
  border-bottom: 1px solid $neutral-250;
  box-shadow: 0px 10px 30px 0px $header-box-shadow-color;
  z-index: 1000000;
}

.btn-secondary {
  background-color: $neutral-620 !important;
  border-color: $neutral-620 !important;
  color: $white !important;
}

.font-weight-medium {
  font-family: $open-sans-medium;
  font-weight: 500;
}

@keyframes spinner-infinite {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 40px;
  height: 40px;
  animation: spinner-infinite 2800ms infinite linear;
}

.highlight-box {
  z-index: 2;
  position: relative;
}

.dim {
  filter: blur(3px);
}

.overflow {
  overflow: visible !important;
}

.modal-backdrop2 {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.message {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  background: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.message::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
