@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?zbphkr');
  src: url('fonts/icomoon.eot?zbphkr#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?zbphkr') format('truetype'),
    url('fonts/icomoon.woff?zbphkr') format('woff'),
    url('fonts/icomoon.svg?zbphkr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dgpass-info:before {
  content: '\e900';
}

.icon-dgpass-password:before {
  content: '\e901';
}

.icon-dgpass-user:before {
  content: '\e902';
}

.icon-dgpass-key:before {
  content: '\e903';
}

.icon-dgpass-user-profile:before {
  content: '\e904';
}

.icon-dgpass-user-access:before {
  content: '\e905';
}

.icon-dgpass-user-notification:before {
  content: '\e906';
}

.icon-dgpass-user-camera:before {
  content: '\e907';
}

.icon-dgpass-logout:before {
  content: '\e908';
}

.icon-dgpass-recovery-codes:before {
  content: '\e909';
}

.icon-dgpass-hardware-tokens:before {
  content: '\e90a';
}

.icon-dgpass-mfa:before {
  content: '\e90b';
}

.icon-dgpass-copy-clipboard:before {
  content: '\e90c';
}

.icon-dgpass-status:before {
  content: '\e90d';
}

.icon-dgpass-chevrons-left:before {
  content: '\e90e';
}

.icon-dgpass-chevron-left:before {
  content: '\e90f';
}

.icon-dgpass-chevrons-right:before {
  content: '\e910';
}

.icon-dgpass-chevron-right:before {
  content: '\e911';
}

.icon-dgpass-refresh:before {
  content: '\e912';
}

.icon-dgpass-consent:before {
  content: '\e913';
}

.icon-dgpass-sign-ins:before {
  content: '\e914';
}

.icon-dgpass-recent-activity:before {
  content: '\e915';
}

.icon-dgpass-download:before {
  content: '\e916';
}

.icon-dgpass-eye-text:before {
  content: '\e917';
}

.icon-dgpass-eye-password:before {
  content: '\e918';
}

.icon-dgpass-mobile:before {
  content: '\e919';
}

.icon-dgpass-desktop:before {
  content: '\e91a';
}

.icon-dgpass-active-status:before {
  content: '\e91b';
}

.icon-dgpass-inactive-status:before {
  content: '\e91c';
}

.icon-dgpass-contents:before {
  content: '\e91d';
}

.icon-dgpass-registration:before {
  content: '\e91e';
}

.icon-dgpass-web-portal:before {
  content: '\e91f';
}

.icon-dgpass-support:before {
  content: '\e920';
}

.icon-dgpass-protection:before {
  content: '\e921';
}

.icon-dgpass-passwords:before {
  content: '\e922';
}

.icon-dgpass-mobile-app:before {
  content: '\e923';
}

.icon-dgpass-search:before {
  content: '\e924';
}

.icon-dgpass-loading-data:before {
  content: '\e925';
}

.icon-dgpass-delete:before {
  content: '\e926';
}

.icon-dgpass-chevron-down:before {
  content: '\e927';
}

.icon-dgpass-upload:before {
  content: '\e928';
}

.icon-dgpass-user-stroke:before {
  content: '\e929';
}

.icon-dgpass-email:before {
  content: '\e92a';
}

.icon-dgpass-unverified-status:before {
  content: '\e92b';
}

.icon-dgpass-edit:before {
  content: '\e92c';
}

.icon-dgpass-save:before {
  content: '\e92d';
}

.icon-dgpass-clock:before {
  content: '\e92e';
}

.icon-dgpass-delete-account:before {
  content: '\e92f';
}

.icon-dgpass-delete-button:before {
  content: '\e930';
}
