@import 'assets/styles/typography';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  min-height: inherit;

  @media (max-width: 796px) {
    flex-direction: column;
  }
}

.image {
  width: 355px;
  height: 355px;
  max-width: 355px !important;
}

.errorContainer {
  text-align: center;
  max-width: 450px !important;
}

.status {
  font-size: $font-size-42;
  font-family: $open-sans-bold;
}

.code {
  padding-top: 16px;
  font-size: $font-size-80;
  line-height: 100%;
  font-family: $open-sans-bold;
  color: $primary;
}

.message {
  font-size: $font-size-24;
  line-height: 100%;
  padding: 24px 12px;

  &:first-letter {
    text-transform: capitalize;
  }
}

.button {
  width: 100%;
}

.separator {
  max-width: 72px !important;
  height: 1px;
}
