@import 'theme-variables';

$open-sans-light: 'Open Sans Light';
$open-sans-regular: 'Open Sans Regular';
$open-sans-medium: 'Open Sans Medium';
$open-sans-semibold: 'Open Sans SemiBold';
$open-sans-bold: 'Open Sans Bold';

@font-face {
  font-family: $open-sans-light;
  font-weight: 300;
  src: local($open-sans-light),
    url('/assets/fonts/OpenSans/OpenSans-Light.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $open-sans-regular;
  font-weight: 400;
  src: local($open-sans-regular),
    url('/assets/fonts/OpenSans/OpenSans-Regular.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $open-sans-medium;
  font-weight: 500;
  src: local($open-sans-medium),
    url('/assets/fonts/OpenSans/OpenSans-Medium.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $open-sans-semibold;
  font-weight: 600;
  src: local($open-sans-semibold),
    url('/assets/fonts/OpenSans/OpenSans-SemiBold.ttf?ppdprd')
      format('truetype');
}

@font-face {
  font-family: $open-sans-bold;
  font-weight: 700;
  src: local($open-sans-bold),
    url('/assets/fonts/OpenSans/OpenSans-Bold.ttf?ppdprd') format('truetype');
}

$font-family-sans-serif: 'Open Sans Regular', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;

$primary: $es-theme-brand-color-primary-500-color;
$white: $es-global-neutral-f-color;
$black: $es-global-neutral-900-color;
$text-primary: rgba(26, 28, 30, 1);
$icon-error-color: $es-theme-on-surface-icon-error-color;
$icon-success-color: $es-theme-on-surface-icon-success-color;
$icon-inactive-color: $es-global-neutral-550-color;
$atention: $es-global-attention-500-color;

$neutral-100: $es-theme-brand-color-neutral-100-color;
$neutral-150: $es-theme-brand-color-neutral-150-color;
$neutral-200: $es-theme-brand-color-neutral-200-color;
$neutral-250: $es-theme-brand-color-neutral-250-color;
$neutral-300: $es-theme-brand-color-neutral-300-color;
$neutral-400: $es-theme-brand-color-neutral-400-color;
$neutral-550: $es-theme-brand-color-neutral-550-color;
$neutral-620: $es-theme-brand-color-neutral-620-color;
$neutral-750: $es-theme-brand-color-neutral-750-color;

$header-box-shadow-color: $es-theme-on-surface-color-header-box-shadow-color;

$error-message: $es-theme-brand-color-error-500-color;
$success: $es-theme-brand-color-success-500-color;
$info: $es-theme-brand-color-shades-500-color;

$code-color: $es-global-shades-100-color;
$body-color: $es-global-neutral-750-color;

$card-bg-color: $es-global-neutral-100-color;
$card-border-radius: $es-theme-controls-card-m-border-radius + px;
$error: $es-global-error-300-color;
$error-darker: $es-global-error-400-color;
$tooltip-icon-color: $es-global-neutral-550-color;
$input-focus-label-color: $es-theme-controls-inputs-outlined-focused-text-label-color;
$input-hover-color: $es-theme-controls-inputs-outlined-hover-border-color;
$input-hover-box-shadow: $es-theme-controls-input-hover-box-shadow-x + px
  $es-theme-controls-input-hover-box-shadow-y + px
  $es-theme-controls-input-hover-box-shadow-blur + px
  $es-theme-controls-input-hover-box-shadow-spread + px
  $es-theme-controls-input-hover-box-shadow-color;
$input-icon-color: $es-theme-controls-inputs-outlined-inactive-default-icon-secondary-color;
$input-icon-hover-color: $es-theme-controls-inputs-outlined-hover-icon-tertiary-color;
$input-icon-focus-color: $es-theme-controls-inputs-outlined-hover-icon-primary-color;
$input-error-border-color: $es-theme-controls-inputs-outlined-error-border-color;
$input-error-text-label-color: $es-theme-controls-inputs-outlined-error-text-label-color;
$input-error-icon-color: $es-theme-controls-inputs-outlined-error-icon-error-color;
$input-secondary-border-color: $es-theme-on-surface-divider-main-lvl6-color;

$button-primary-hover-bg-color: $es-theme-controls-button-main-base-primary-hover-bg-color;
$btn-link-color: $es-theme-controls-link-default-secondary-color;
$btn-link-hover-color: $es-theme-controls-link-hover-secondary-color;

$table-border-color: $es-theme-controls-data-table-header-default-divider-tertiary-color;

$divider-color: $es-theme-controls-inputs-outlined-inactive-default-divider-color;

$font-size-10: $es-10-font-size + px;
$font-size-12: $es-12-font-size + px;
$font-size-14: $es-14-font-size + px;
$font-size-15: $es-15-font-size + px;
$font-size-16: $es-16-font-size + px;
$font-size-18: $es-18-font-size + px;
$font-size-24: $es-24-font-size + px;
$font-size-42: $es-42-font-size + px;
$font-size-80: $es-80-font-size + px;

$line-height-170: $es-170-line-height;

// Input
$input-color: $es-theme-controls-inputs-outlined-inactive-default-text-input-color;
$input-btn-padding-y: 0.438rem;
$input-btn-padding-x: 0.75rem;
$input-border-color: $es-theme-controls-inputs-outlined-inactive-default-border-color;
$input-focus-border-color: $es-theme-controls-inputs-outlined-focused-border-color !important;
$input-btn-focus-box-shadow: none !important;
$input-disabled-bg: $es-theme-controls-inputs-outlined-disabled-bg-secondary-color !important;
$input-placeholder-color: $es-theme-controls-inputs-outlined-inactive-default-text-placeholder-color;

// Form
$form-select-focus-border-color: $es-theme-controls-inputs-outlined-focused-border-color !important;
$form-select-focus-box-shadow: none !important;
$form-check-input-width: 1.25em;
$form-check-min-height: 1.25em;
$form-check-input-checked-bg-color: $es-theme-controls-check-default-bg-primary-color;
$form-check-input-checked-border-color: $es-theme-controls-check-default-border-color;

// Tooltip
$tooltip-border-radius: $es-theme-controls-tooltip-m-border-radius + px;

$accordion-padding-y: 2.5rem;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg width='13' height='24' viewBox='0 0 13 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3443 17.3123C12.0232 16.9927 11.5038 16.9939 11.1842 17.315L7.32031 21.1928V0.820312C7.32031 0.367254 6.95305 0 6.49999 0C6.04694 0 5.67968 0.367254 5.67968 0.820312V21.1928L1.81581 17.315C1.49622 16.9939 0.976835 16.9926 0.655723 17.3122C0.334571 17.6319 0.333382 18.1513 0.652935 18.4724L5.91856 23.7587L5.91934 23.7594C6.23893 24.0797 6.75999 24.0807 7.08065 23.7594L7.08143 23.7587L12.3471 18.4724C12.6666 18.1513 12.6655 17.6319 12.3443 17.3123Z' fill='black'/%3E%3C/svg%3E");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg width='13' height='24' viewBox='0 0 13 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3443 17.3123C12.0232 16.9927 11.5038 16.9939 11.1842 17.315L7.32031 21.1928V0.820312C7.32031 0.367254 6.95305 0 6.49999 0C6.04694 0 5.67968 0.367254 5.67968 0.820312V21.1928L1.81581 17.315C1.49622 16.9939 0.976835 16.9926 0.655723 17.3122C0.334571 17.6319 0.333382 18.1513 0.652935 18.4724L5.91856 23.7587L5.91934 23.7594C6.23893 24.0797 6.75999 24.0807 7.08065 23.7594L7.08143 23.7587L12.3471 18.4724C12.6666 18.1513 12.6655 17.6319 12.3443 17.3123Z' fill='black'/%3E%3C/svg%3E");

// Profile link
$profile-link-bg: rgba(46, 125, 50, 0.1);
$profile-link-border-radius: $es-x5-border-radius + px;
$profile-link-font-size: $font-size-18;
$profile-link-box-shadow: rgba(0, 0, 0, 0.35) 0px 12px 15px 0px;
$profile-link-dropdown-item-hover-bg: #ebebeb;
$profile-link-dropdown-bg: #f7f7f7;

//Uploader
$uploader-delete-icon-color: $es-theme-controls-button-main-error-secondary-default-text-color;
$uploader-delimiter-bg: $es-theme-on-surface-divider-main-lvl3-color;
$uploader-icon-box-shadow: inset 0px 0px 0px 1px white;

//Divider
$divider-bg: $es-theme-on-surface-divider-main-lvl3-color;

//Status
$status-unverified-color: $es-global-orange-270-color;
$status-unverified-color: $es-global-orange-270-color;
$status-unverified-text-size: $font-size-18;
$status-unverified-icon-size: $font-size-16;

//Otp code
$otp-code-separator-color: $es-theme-on-surface-border-gray-color;
$otp-code-focus-border: $es-theme-on-surface-border-success-color;
$otp-code-border-radius: $es-theme-controls-input-s-border-radius + px;
